import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./manualitinerarysummary.css";
import axios from "axios";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import jsPDF from "jspdf";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import "jspdf-autotable";
import logo from "./assets/logo.png";
import customMarkerIcon from "./assets/custom-marker.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import hostURL from "./config";
import {
  faPen,
  faEye,
  faSave,
  faFileExport,
  faClock,
  faDollarSign,
  faPaperclip,
  faTrash,
  faPlus,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";

function ManualItinerarySummary() {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    tripName,
    destinationCity,
    manualStartDate,
    manualEndDate,
    photo: passedPhoto,
  } = location.state || {};

  const [places, setPlaces] = useState({});

  const [newPlace, setNewPlace] = useState({});
  const [budget, setBudget] = useState(20000);
  const [newBudget, setNewBudget] = useState(0);
  const [selectedCurrency, setSelectedCurrency] = useState("PHP");
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [tripdescription, setTripdescription] = useState("");
  const [showBudgetModal, setShowBudgetModal] = useState(false);
  const [showBreakdownModal, setShowBreakdownModal] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [showTimeModal, setShowTimeModal] = useState(false);
  const [showExpenseModal, setShowExpenseModal] = useState(false);
  const [showAttachModal, setShowAttachModal] = useState(false);
  const [showEditPlaceModal, setShowEditPlaceModal] = useState(false);
  const [mapZoom, setMapZoom] = useState(10); // Default zoom level
  const [showEditTitleModal, setShowEditTitleModal] = useState(false);
  const [mapKey, setMapKey] = useState(0); // Key to force map re-render
  const mapRef = useRef(null); // Reference to the map instance

  const [timeDetails, setTimeDetails] = useState({
    startTime: "",
    endTime: "",
  });

  const [expense, setExpense] = useState("");
  const [description, setDescription] = useState("");
  const [placeDescriptions, setPlaceDescriptions] = useState({});
  const [editPlaceName, setEditPlaceName] = useState("");
  const [editTitle, setEditTitle] = useState(tripName || "");
  const [mapCenter, setMapCenter] = useState({ lat: 13.4125, lng: 103.8667 });
  const [markers, setMarkers] = useState([]);
  const [activeMarker, setActiveMarker] = useState(null);
  const [photo, setPhoto] = useState(
    passedPhoto ? URL.createObjectURL(passedPhoto) : null
  ); // Initialize with the photo passed from ManualItinerary
  const [photoFile, setPhotoFile] = useState(null); // For uploading a new photo

  const googleMapsApiKey = "AIzaSyCWQ3bmZoMMu6dkQ9TxUx8ln5lLQdNk_Do";

  const currencyOptions = [
    { code: "PHP", name: "Philippine Peso", symbol: "₱" },
    { code: "USD", name: "US Dollar", symbol: "$" },
    { code: "EUR", name: "Euro", symbol: "€" },
    { code: "JPY", name: "Japanese Yen", symbol: "¥" },
    { code: "GBP", name: "British Pound", symbol: "£" },
    { code: "AUD", name: "Australian Dollar", symbol: "A$" },
    { code: "CAD", name: "Canadian Dollar", symbol: "C$" },
    //  more currencies as needed
  ];
  // Handle photo change (for uploading a new photo)
  const handlePhotoChange = (event) => {
    const file = event.target.files[0];

    if (!file) {
      alert("No file selected. Please choose an image.");
      return;
    }

    const validImageTypes = [
      "image/jpeg",
      "image/png",
      "image/jpg",
      "image/gif",
    ];
    if (!validImageTypes.includes(file.type)) {
      alert("Invalid file type. Please upload a JPEG or PNG image.");
      return;
    }

    const maxSizeInMB = 5;
    if (file.size > maxSizeInMB * 1024 * 1024) {
      alert(
        `File size exceeds ${maxSizeInMB}MB. Please upload a smaller file.`
      );
      return;
    }

    // Update the local state with the selected file for the new image
    setPhotoFile(file);

    // Create a temporary URL for the uploaded photo to preview

    setPhoto(URL.createObjectURL(file)); // Set the preview for the photo in the UI
  };

  const getCurrencySymbol = (currencyCode) => {
    const currency = currencyOptions.find((c) => c.code === currencyCode);
    return currency ? currency.symbol : "";
  };

  const handleDescriptionChange = (day, index, description) => {
    setPlaceDescriptions((prev) => ({
      ...prev,
      [`${day}-${index}`]: description,
    }));
  };

  // Function to sort places by time while preserving all properties, including description
  const sortPlacesByTime = (dayPlaces = []) => {
    const convertTo24Hour = (time) => {
      const [timeString, modifier] = time.split(" ");
      let [hours, minutes] = timeString.split(":").map(Number);

      if (modifier === "PM" && hours !== 12) {
        hours += 12;
      } else if (modifier === "AM" && hours === 12) {
        hours = 0;
      }

      return hours * 60 + minutes; // Return total minutes from midnight
    };

    return [...dayPlaces].sort((a, b) => {
      // Assign "12:00 AM" as default time for places without a time
      const timeA = a.time ? a.time.split(" - ")[0] : "12:00 AM"; // Get start time
      const timeB = b.time ? b.time.split(" - ")[0] : "12:00 AM"; // Get start time

      const timeAMinutes = convertTo24Hour(timeA);
      const timeBMinutes = convertTo24Hour(timeB);

      return timeAMinutes - timeBMinutes;
    });
  };

  useEffect(() => {
    setPlaces((prevPlaces) => {
      const updatedPlaces = { ...prevPlaces }; // Start with the existing places
      const start = new Date(manualStartDate);
      const end = new Date(manualEndDate);

      for (let d = start; d <= end; d.setDate(d.getDate() + 1)) {
        const formattedDate = d.toLocaleDateString();
        if (prevPlaces[formattedDate]) {
          // Sort existing places and maintain all properties, including description
          updatedPlaces[formattedDate] = sortPlacesByTime(
            prevPlaces[formattedDate]
          );
        } else if (!updatedPlaces[formattedDate]) {
          updatedPlaces[formattedDate] = [];
        }
      }

      return updatedPlaces;
    });

    if (destinationCity) {
      axios
        .get("https://maps.googleapis.com/maps/api/geocode/json", {
          params: {
            address: destinationCity,
            key: googleMapsApiKey,
          },
        })
        .then((geoResponse) => {
          if (geoResponse.data.status === "OK") {
            const location = geoResponse.data.results[0].geometry.location;
            setMapCenter({ lat: location.lat, lng: location.lng });
          } else {
            console.warn(`Geocoding API error: ${geoResponse.data.status}`);
          }
        })
        .catch((geoError) => {
          console.error("Error fetching coordinates for city:", geoError);
        });
    }
  }, [manualStartDate, manualEndDate, destinationCity]);

  useEffect(() => {
    let total = 0;
    Object.keys(places).forEach((date) => {
      total += places[date].reduce(
        (acc, place) => acc + parseFloat(place.cost || 0),
        0
      );
    });
    setTotalExpenses(total);
  }, [places]);

  const handleAddPlace = async (date) => {
    const placeName = newPlace[date]?.trim();

    if (placeName && placeName !== "") {
      let newPlaceData = {
        name: placeName,
        lat: null, // Default null for unrecognized places
        lng: null, // Default null for unrecognized places
        time: "", // Default empty time
        cost: "",
        description: "",
        photo: null,
      };

      try {
        const response = await axios.get(
          "https://maps.googleapis.com/maps/api/geocode/json",
          {
            params: {
              address: placeName,
              key: googleMapsApiKey,
            },
          }
        );

        if (response.data.status === "OK") {
          const location = response.data.results[0].geometry.location;

          // Update newPlaceData with lat/lng for recognized places
          newPlaceData.lat = location.lat;
          newPlaceData.lng = location.lng;

          // Add marker for recognized places
          setMarkers([
            ...markers,
            {
              lat: location.lat,
              lng: location.lng,
              name: placeName,
              description: "",
            },
          ]);

          console.log(`Recognized place added and pinned: ${placeName}`);
        } else {
          console.warn(`Place not recognized by Google Maps API: ${placeName}`);
        }
      } catch (error) {
        console.error(`Error fetching coordinates for ${placeName}:`, error);
      }

      // Add and sort places for the specified date (recognized or not)
      const updatedPlaces = {
        ...places,
        [date]: sortPlacesByTime([
          ...(places[date] || []), // Existing places for the date
          newPlaceData,
        ]),
      };
      setPlaces(updatedPlaces);

      // Clear the input field after adding
      setNewPlace({ ...newPlace, [date]: "" });
    }
  };

  const handleSaveItinerary = async () => {
    try {
      const token = localStorage.getItem("userToken");
      if (!token) {
        alert("User not authenticated. Please log in.");
        return;
      }

      const userResponse = await axios.get(`${hostURL}/user`, {
        headers: {
          Authorization: token,
        },
      });

      const userId = userResponse.data.id;

      const formData = new FormData();
      formData.append("tripName", tripName || "");
      formData.append("destinationCity", destinationCity || "");
      formData.append("startDate", manualStartDate || "");
      formData.append("endDate", manualEndDate || "");
      formData.append("tripdescription", tripdescription || "");
      formData.append("budget", budget || 0); // Add budget
      formData.append("selectedCurrency", selectedCurrency || ""); // Add selected currency
      formData.append("userId", userId);

      // If the user uploaded a new photo, append it as a file
      if (photoFile) {
        formData.append("photo", photoFile);
      } else if (photo) {
        // If the photo is passed from ManualItinerary, treat it as a file (convert it to a Blob or File)
        const file = await fetch(photo)
          .then((res) => res.blob()) // Fetch the image as a blob
          .then(
            (blob) => new File([blob], "photo.jpg", { type: "image/jpeg" })
          ); // Convert blob to File object

        formData.append("photo", file);
      }

      console.log("Sending FormData:");
      for (let [key, value] of formData.entries()) {
        console.log(`${key}:`, value);
      }

      const tripResponse = await axios.post(`${hostURL}/addTrip`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      const itineraryId = tripResponse.data.trip_id;

      const placesData = Object.keys(places).flatMap((date) =>
        places[date].map((place) => ({
          ...place,
          day: date,
          itineraryId,
        }))
      );

      console.log("Places Data:", placesData);

      await axios.post(`${hostURL}/api/places/add`, {
        places: placesData,
      });

      alert("Itinerary saved successfully!");
      navigate("/userprofile");
    } catch (error) {
      console.error("Error saving itinerary:", error);
      if (error.response) {
        console.error("Backend Response:", error.response.data);
      }
      alert("Failed to save itinerary. Please try again.");
    }
  };

  const handleDeletePlace = (day, index) => {
    const updatedPlaces = { ...places };

    // Get the name of the place to be deleted
    const placeToDelete = updatedPlaces[day][index]?.name;

    // Remove the place from the list
    updatedPlaces[day].splice(index, 1);

    // Sort remaining places for the day
    updatedPlaces[day] = sortPlacesByTime(updatedPlaces[day]);

    setPlaces(updatedPlaces);

    // Remove the corresponding marker based on the place's name
    setMarkers((prevMarkers) =>
      prevMarkers.filter((marker) => marker.name !== placeToDelete)
    );
  };

  const calculateDate = (manualStartDate, daysToAdd) => {
    const date = new Date(manualStartDate);
    date.setDate(date.getDate() + daysToAdd);
    return date.toLocaleDateString();
  };

  const handleMarkerClick = (marker, index) => {
    setActiveMarker(index);
    setMapCenter({ lat: marker.lat, lng: marker.lng });
  };

  const handleBudgetSave = () => {
    if (newBudget !== null && newBudget >= 0 && newBudget <= 10000000) {
      setBudget(newBudget); // Save the updated budget
      setShowBudgetModal(false); // Close the modal
    } else if (newBudget > 10000000) {
      alert("Budget cannot exceed 10 million. Please enter a valid amount."); // Validation error for exceeding maximum
    } else {
      alert("Budget cannot be negative. Please enter a valid amount."); // Validation error for negative values
    }
  };

  const handleTimeSave = () => {
    if (!selectedPlace) return;

    const { date, index } = selectedPlace;

    setPlaces((prevPlaces) => {
      const updatedPlaces = { ...prevPlaces };
      // Preserve all properties while updating the time
      updatedPlaces[date][index] = {
        ...updatedPlaces[date][index],
        time: timeDetails.startTime
          ? `${timeDetails.startTime} - ${timeDetails.endTime || ""}`
          : "",
      };

      // Sort places for the updated day and maintain properties like description
      updatedPlaces[date] = sortPlacesByTime(updatedPlaces[date]);

      return updatedPlaces;
    });

    setShowTimeModal(false);
  };

  const handleExpenseSave = () => {
    const updatedPlaces = { ...places };
    if (selectedPlace !== null) {
      const { date, index } = selectedPlace;
      updatedPlaces[date][index].cost = parseFloat(expense) || 0;
      updatedPlaces[date][index].description = description || "";
      setPlaces(updatedPlaces);
    }
    setShowExpenseModal(false);
  };

  const handleAttachSave = async (event) => {
    if (!selectedPlace || event.target.files.length === 0) return;

    const file = event.target.files[0];

    // Validate file type and size
    const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];
    const maxFileSize = 25 * 1024 * 1024; // 25 MB
    if (!validImageTypes.includes(file.type)) {
      alert("Invalid file type. Please upload a JPEG or PNG image.");
      return;
    }
    if (file.size > maxFileSize) {
      alert("File size exceeds 25 MB. Please upload a smaller file.");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("photo", file);

      // Send the photo to the server
      const response = await axios.post(
        `${hostURL}/api/uploadPhoto`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Get the file path from the server's response
      const { photoPath } = response.data;

      // Update the selected place's photo with the file path
      const { date, index } = selectedPlace;
      setPlaces((prevPlaces) => {
        const updatedPlaces = { ...prevPlaces };
        updatedPlaces[date][index].photo = photoPath; // Save the file path
        return updatedPlaces;
      });

      setShowAttachModal(false);
    } catch (error) {
      console.error("Error uploading photo:", error);
      alert("Failed to upload photo. Please try again.");
    }
  };

  const handleEditPlace = () => {
    if (selectedPlace) {
      const { date, index } = selectedPlace;
      const updatedPlaces = { ...places };
      updatedPlaces[date][index].name = editPlaceName;
      setPlaces(updatedPlaces);
    }
    setShowEditPlaceModal(false);
  };

  const handleEditTitle = () => {
    setEditTitle(editTitle);
    setShowEditTitleModal(false);
  };

  const formattedDate = calculateDate(manualStartDate, 5); // Example usage
  console.log(formattedDate);

  //Export to PDF
  const exportToPDF = () => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.width;
    const margin = 10;
    let y = 20;

    // Add Logo
    const logoWidth = 40;
    const logoHeight = 25;
    const logoX = (pageWidth - logoWidth) / 2;
    doc.addImage(logo, "PNG", logoX, 10, logoWidth, logoHeight);
    y += logoHeight + 10;

    // Add Title
    doc.setFontSize(18);
    doc.setFont("helvetica", "bold");
    doc.text("Itinerary Summary", pageWidth / 2, y, { align: "center" });
    y += 10;

    // Add Itinerary Details
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text(`Title: ${tripName || "N/A"}`, margin, (y += 10));
    doc.text(`Destination: ${destinationCity || "N/A"}`, margin, (y += 10));
    doc.text(
      `Start Date: ${new Date(manualStartDate).toLocaleDateString()}`,
      margin,
      (y += 10)
    );
    doc.text(
      `End Date: ${new Date(manualEndDate).toLocaleDateString()}`,
      margin,
      (y += 10)
    );
    doc.text(`Description: ${tripdescription || "N/A"}`, margin, (y += 10));
    y += 10;

    // Iterate through Days
    Object.keys(places).forEach((date, index) => {
      // Add Day Header
      doc.setFont("helvetica", "bold");
      doc.setFontSize(14);
      doc.text(`Day ${index + 1} - ${date}:`, margin, y);
      y += 10;

      // Prepare Table Data
      const tableData = places[date].map((place) => [
        place.name || "N/A",
        place.description || "N/A",
        place.time || "N/A",
        place.cost ? `${place.cost} ${selectedCurrency}` : "N/A",
      ]);

      // Add Table
      doc.autoTable({
        head: [["Place Name", "Description", "Time", "Cost"]],
        body: tableData,
        startY: y,
        styles: { fontSize: 10, cellPadding: 5 },
        headStyles: { fillColor: [40, 116, 240], textColor: [255, 255, 255] },
        theme: "striped",
        margin: { left: margin, right: margin },
        didDrawPage: (data) => {
          y = data.cursor.y + 10; // Update y position for the next content
        },
      });
    });

    // Save the PDF
    doc.save("Itinerary.pdf");
  };

  //Export to Excel
  const exportToExcel = () => {
    // Prepare data with headers
    const data = [
      {
        Title: editTitle,
        Destination: destinationCity,
        "Start Date": manualStartDate,
        "End Date": manualEndDate,
        Description: tripdescription || "N/A",
      },
      {},
      ...Object.keys(places).flatMap((date, index) => {
        return [
          { Day: `Day ${index + 1}`, Date: formattedDate }, // Add Day Header
          ...places[date].map((place) => ({
            "Place Name": place.name || "N/A",
            Description: place.description || "No Description",
            Time: place.time || "N/A",
            Cost: place.cost ? `${place.cost} ${selectedCurrency}` : "N/A",
          })),
          {}, // Add spacing between days
        ];
      }),
    ];

    // Create worksheet
    const worksheet = XLSX.utils.json_to_sheet(data, { skipHeader: true });

    // Create workbook and save
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Itinerary");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "Itinerary.xlsx");
  };

  //Export to Word
  const exportToWord = () => {
    let content = `
      <html>
      <head>
        <style>
          body {
            font-family: Arial, sans-serif;
            margin: 20px;
            color: #333;
            line-height: 1.6;
          }
          h1 {
            text-align: center;
            font-size: 28px;
            color: #444;
            margin-bottom: 10px;
          }
          h2 {
            text-align: center;
            font-size: 20px;
            color: #666;
            margin-bottom: 20px;
          }
          p {
            font-size: 16px;
            margin: 5px 0;
          }
          table {
            width: 100%;
            border-collapse: collapse;
            margin: 20px 0;
            font-size: 14px;
          }
          th, td {
            border: 1px solid #ddd;
            padding: 10px;
            text-align: left;
          }
          th {
            background-color: #2973F0;
            color: white;
            font-weight: bold;
            text-transform: uppercase;
          }
          tr:nth-child(even) {
            background-color: #f9f9f9;
          }
          .section-header {
            font-size: 18px;
            font-weight: bold;
            color: #444;
            margin-top: 30px;
            margin-bottom: 10px;
          }
        </style>
      </head>
      <body>
        <h1>Itinerary Summary</h1>
        <h2>${editTitle}</h2>
        <p><strong>Destination:</strong> ${destinationCity}</p>
        <p><strong>Start Date:</strong> ${manualStartDate}</p>
        <p><strong>End Date:</strong> ${manualEndDate}</p>
        <p><strong>Description:</strong> ${tripdescription || "N/A"}</p>
    `;

    Object.keys(places).forEach((date, index) => {
      content += `
        <div class="section-header">Day ${index + 1} - ${date}</div>
        <table>
          <thead>
            <tr>
              <th>Place Name</th>
              <th>Description</th>
              <th>Time</th>
              <th>Cost</th>
            </tr>
          </thead>
          <tbody>
      `;

      // Add each place under the day's section
      places[date].forEach((place) => {
        content += `
        <tr>
          <td>${place.name || "N/A"}</td>
          <td>${place.description || "No Description"}</td>
          <td>${place.time || "N/A"}</td>
          <td>${place.cost ? `${place.cost} ${selectedCurrency}` : "N/A"}</td>
        </tr>
      `;
      });

      content += `
          </tbody>
        </table>
      `;
    });

    content += `
      </body>
      </html>
    `;
    // Create the Word document and save it
    const blob = new Blob([content], { type: "application/msword" });
    saveAs(blob, "Itinerary.doc");
  };

  return (
    <div className="itinerary-summary-container">
      <div className="itinerary-details">
        <div
          className="trip-header"
          style={{ display: "flex", position: "relative" }}
        >
          {/* Left Section */}
          <div className="trip-header-left" style={{ flex: "1" }}>
            <h1>{editTitle}</h1>
            <p>
              {new Date(manualStartDate).toLocaleDateString()} -{" "}
              {new Date(manualEndDate).toLocaleDateString()}
            </p>
            <textarea
              placeholder="Enter itinerary description (optional)"
              value={tripdescription}
              onChange={(e) => setTripdescription(e.target.value)}
              className="description-textarea"
            />
          </div>

          {/* Right Section */}
          <div
            className="trip-header-right"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              gap: "10px",
            }}
          >
            {/* Photo Upload Feature */}
            <div className="photo-upload" style={{ textAlign: "center" }}>
              <img
                src={
                  photo
                    ? photo // Use the `photo` state for the uploaded image
                    : "https://via.placeholder.com/150x100" // Placeholder when no photo is uploaded
                }
                alt="Upload Preview"
                className="photo-preview"
                style={{
                  width: "150px",
                  height: "100px",
                  objectFit: "cover",
                  borderRadius: "8px",
                  border: "1px solid #ddd",
                  marginBottom: "5px",
                }}
              />
              <label htmlFor="uploadPhotoInput" className="upload-photo-label">
                <input
                  type="file"
                  id="uploadPhotoInput"
                  accept="image/jpeg, image/png, image/jpg"
                  onChange={handlePhotoChange}
                  style={{ display: "none" }} // Hide the default file input
                />
                <i className="fa fa-camera"></i> Change Photo
              </label>
            </div>

            {/* Buttons Section */}
            <div
              className="header-buttons"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
                marginTop: "10px",
              }}
            >
              <button
                className="control-btn"
                onClick={() => setShowEditTitleModal(true)}
              >
                <FontAwesomeIcon icon={faPen} />
                <span>Edit Itinerary Title</span>
              </button>
              <button className="control-btn" onClick={handleSaveItinerary}>
                <FontAwesomeIcon icon={faSave} />
                <span>Save as Draft</span>
              </button>

              <div className="export-dropdown" style={{ position: "relative" }}>
                <button className="control-btn dropdown-toggle">
                  <FontAwesomeIcon icon={faFileExport} />
                  <span>Export Options</span>
                </button>
                <div className="dropdown-menu">
                  <button onClick={exportToPDF} className="dropdown-item">
                    Export to PDF
                  </button>
                  <button onClick={exportToExcel} className="dropdown-item">
                    Export to Excel
                  </button>
                  <button onClick={exportToWord} className="dropdown-item">
                    Export to Word
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="budget-header">
          <h2 className="budget-title">Budgeting</h2>
          <div className="budget-buttons">
            <button
              className="controll-btn"
              onClick={() => {
                setNewBudget(budget);
                setShowBudgetModal(true);
              }}
            >
              <FontAwesomeIcon icon={faPen} />
              <span>Edit</span>
            </button>
            <button
              className="controll-btn"
              onClick={() => setShowBreakdownModal(true)}
            >
              <FontAwesomeIcon icon={faEye} />
              <span>View Breakdown</span>
            </button>
          </div>
        </div>
        <div className="budget-box">
          <div className="budget-summary">
            <p>
              {getCurrencySymbol(selectedCurrency)}
              {totalExpenses.toLocaleString()}.00
            </p>
            <p className="budget-total">
              Budget: {getCurrencySymbol(selectedCurrency)}
              {(budget || 0).toLocaleString()}.00
            </p>
          </div>
          <div className="progress-bar">
            <div
              className="filled-bar"
              style={{
                width: `${
                  budget && budget > 0
                    ? Math.min((totalExpenses / budget) * 100, 100)
                    : 0
                }%`,
                backgroundColor:
                  budget && budget > 0 && totalExpenses > budget
                    ? "#ff4d4f"
                    : "#007bff",
              }}
            ></div>
          </div>
        </div>

        <div className="itinerary">
          <h2>Itinerary</h2>
          {Object.keys(places).map((date) => (
            <div key={date} className="day-container">
              <h3>
                {new Date(date).toLocaleDateString("en-US", {
                  weekday: "long",
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                })}
              </h3>
              {places[date].map((place, index) => (
                <div
                  key={index}
                  className="place-card-maker"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <div
                    className="place-content"
                    style={{
                      flex: "1",
                      padding: "25px",
                      border: "1px solid #ccc",
                      borderRadius: "8px",
                    }}
                  >
                    <h4
                      style={{ cursor: "pointer", color: "black" }}
                      onClick={() => {
                        if (mapRef.current) {
                          mapRef.current.panTo({
                            lat: place.lat,
                            lng: place.lng,
                          }); // Pan to place
                          mapRef.current.setZoom(14); // Zoom in
                        }
                      }}
                    >
                      {place.name}
                    </h4>

                    {/* Add Description Textarea */}
                    <textarea
                      placeholder="Add description (optional)"
                      value={place.description || ""}
                      onChange={(e) => {
                        setPlaces((prevPlaces) => {
                          const updatedPlaces = { ...prevPlaces };
                          updatedPlaces[date][index] = {
                            ...updatedPlaces[date][index],
                            description: e.target.value,
                          };
                          return updatedPlaces;
                        });
                      }}
                      className="description-input"
                      style={{
                        width: "100%",
                        marginTop: "1px",
                        padding: "8px",
                        fontSize: "14px",
                        border: "0px solid #ddd",
                        borderRadius: "5px",
                      }}
                    />

                    <div
                      className="place-controls"
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      {/* Edit Name Button */}
                      <button
                        className="control-btn"
                        onClick={() => {
                          setSelectedPlace({ date, index: index }); // Ensure `idx` is passed correctly as the index
                          setShowEditPlaceModal(true);
                          setEditPlaceName(place.name); // Set the name of the place to edit
                        }}
                      >
                        <FontAwesomeIcon icon={faPen} />
                        <span>Edit Name</span>
                      </button>

                      {/* Add/Edit Time */}
                      <button
                        className="control-btn"
                        onClick={() => {
                          setSelectedPlace({ date, index: index }); // Use idx here
                          setShowTimeModal(true);
                          setTimeDetails({ startTime: "", endTime: "" });
                        }}
                        style={{
                          color: place.time ? "#337ab7" : "inherit", // Blue for existing time
                          fontWeight: place.time ? "bold" : "normal",
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <FontAwesomeIcon icon={faClock} />
                        <span>{place.time || "Add Time"}</span>
                      </button>

                      {/* Add/Edit Cost */}
                      <button
                        className="control-btn"
                        onClick={() => {
                          setSelectedPlace({ date, index: index }); // Use idx here
                          setShowExpenseModal(true);
                          setExpense("");
                          setDescription("");
                        }}
                        style={{
                          color: place.cost ? "#337ab7" : "inherit", // Blue for existing cost
                          fontWeight: place.cost ? "bold" : "normal",
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <FontAwesomeIcon icon={faDollarSign} />
                        <span>
                          {place.cost
                            ? `${getCurrencySymbol(
                                selectedCurrency
                              )}${place.cost.toLocaleString()}`
                            : "Add Cost"}
                        </span>
                      </button>

                      {/* Attach Button */}
                      <button
                        className="control-btn"
                        onClick={() => {
                          setSelectedPlace({ date, index: index }); // Use idx here
                          setShowAttachModal(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faPaperclip} />
                        <span>Attach</span>
                      </button>

                      <button
                        className="control-btn delete-btn"
                        onClick={() => handleDeletePlace(date, index)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                        <span>Delete</span>
                      </button>
                    </div>
                  </div>
                  {place.photo && (
                    <img
                      src={`${hostURL}/${place.photo}`}
                      alt="Place"
                      style={{
                        width: "250px",
                        height: "200px",
                        marginLeft: "20px",
                        borderRadius: "8px",
                        border: "1px solid #ccc",
                      }}
                    />
                  )}
                </div>
              ))}
              <div className="add-place">
                <div className="add-place-input-container">
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    className="add-place-iconn"
                  />
                  <input
                    type="text"
                    value={newPlace[date] || ""}
                    className="add-place-input"
                    placeholder="Add a Place / Restaurant / Activity"
                    onChange={(e) =>
                      setNewPlace({ ...newPlace, [date]: e.target.value })
                    }
                  />
                </div>
                <button
                  className="add-place-btn"
                  onClick={() => handleAddPlace(date)}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="itinerary-map">
        <LoadScript googleMapsApiKey={googleMapsApiKey}>
          <GoogleMap
            mapContainerStyle={{
              width: "100%",
              height: "100%", // Ensure it fills its container
            }}
            center={mapCenter}
            zoom={mapZoom}
            onLoad={(map) => (mapRef.current = map)} // Store map instance
          >
            {markers.map((marker, index) => (
              <Marker
                key={index}
                position={marker}
                onClick={() => handleMarkerClick(marker, index)}
                icon={{
                  url: customMarkerIcon, // Path to your custom marker PNG
                  scaledSize: new window.google.maps.Size(40, 60), // Adjust size
                }}
              >
                {activeMarker === index && (
                  <InfoWindow
                    position={marker}
                    onCloseClick={() => setActiveMarker(null)}
                  >
                    <div>
                      <h4>{marker.name}</h4>
                      <p>{marker.description}</p>
                    </div>
                  </InfoWindow>
                )}
              </Marker>
            ))}
          </GoogleMap>
        </LoadScript>
      </div>

      {/* Modals */}
      {showBudgetModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Edit Budget</h2>
            <input
              type="text"
              value={
                newBudget !== null && newBudget !== undefined
                  ? new Intl.NumberFormat().format(newBudget)
                  : ""
              }
              onChange={(e) => {
                let value = e.target.value.replace(/,/g, ""); // Remove commas for processing

                // Ensure the value is a valid number within the allowed range
                if (!isNaN(value) && value !== "") {
                  const numericValue = parseInt(value, 10);
                  if (numericValue >= 0 && numericValue <= 10000000) {
                    setNewBudget(numericValue); // Update state without commas
                  } else if (numericValue > 10000000) {
                    alert("Budget cannot exceed 10 million."); // Alert for exceeding maximum value
                  } else {
                    alert("Budget cannot be negative."); // Alert for negative values
                  }
                } else if (value === "") {
                  setNewBudget(null); // Clear input
                }
              }}
              placeholder="Enter New Budget"
              style={{
                width: "100%",
                padding: "10px",
                fontSize: "16px",
                border: "1px solid #ccc",
                borderRadius: "6px",
                marginBottom: "10px",
              }}
            />
            <select
              value={selectedCurrency}
              onChange={(e) => setSelectedCurrency(e.target.value)}
              style={{
                width: "100%",
                padding: "10px",
                fontSize: "16px",
                border: "1px solid #ccc",
                borderRadius: "6px",
                marginBottom: "20px",
              }}
            >
              {currencyOptions.map((currency) => (
                <option key={currency.code} value={currency.code}>
                  {currency.name}
                </option>
              ))}
            </select>
            <div className="modal-buttons">
              <button
                onClick={() => setShowBudgetModal(false)}
                style={{
                  backgroundColor: "#e0e0e0",
                  color: "#000",
                  border: "none",
                  padding: "8px 20px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
              >
                Cancel
              </button>
              <button
                onClick={handleBudgetSave}
                style={{
                  backgroundColor: "#2979ff",
                  color: "#fff",
                  border: "none",
                  padding: "8px 20px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      {/* View Breakdown Modal */}
      {showBreakdownModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Budget Breakdown</h2>
            <p>
              <strong>Total Budget:</strong>{" "}
              {getCurrencySymbol(selectedCurrency)}
              {budget.toLocaleString()}.00
            </p>
            <p>
              <strong>Total Expenses:</strong>{" "}
              {getCurrencySymbol(selectedCurrency)}
              {totalExpenses.toLocaleString()}
              .00
            </p>
            <ul>
              {Object.keys(places).map((day) =>
                places[day].map((place, idx) =>
                  place.cost ? (
                    <li key={`${day}-${idx}`}>
                      {place.name}: {getCurrencySymbol(selectedCurrency)}
                      {place.cost.toLocaleString()}
                    </li>
                  ) : null
                )
              )}
            </ul>
            <div className="modal-buttons">
              <button onClick={() => setShowBreakdownModal(false)}>
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Add Time Modal */}
      {showTimeModal && (
        <div className="modal">
          <div className="modal-content">
            <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
              Set Time
            </h2>
            <div
              className="time-inputs"
              style={{ display: "flex", alignItems: "center", gap: "20px" }}
            >
              {/* Start Time */}
              <div style={{ flex: 1 }}>
                <label
                  htmlFor="start-time"
                  style={{
                    display: "block",
                    fontWeight: "bold",
                    fontSize: "14px",
                    marginBottom: "8px",
                  }}
                >
                  Start Time
                </label>
                <select
                  id="start-time"
                  value={timeDetails.startTime}
                  onChange={(e) =>
                    setTimeDetails({
                      ...timeDetails,
                      startTime: e.target.value,
                    })
                  }
                  style={{
                    width: "100%",
                    padding: "20px",
                    borderRadius: "6px",
                    border: "1px solid #ccc",
                    fontSize: "14px",
                    maxHeight: "150px", // Shorten dropdown
                    overflowY: "auto", // Enable scrolling for long dropdowns
                  }}
                >
                  {Array.from({ length: 24 * 2 }, (_, i) => {
                    const hour = Math.floor(i / 2);
                    const minute = i % 2 === 0 ? "00" : "30";
                    const ampm = hour < 12 ? "AM" : "PM";
                    const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
                    const timeString = `${formattedHour}:${minute} ${ampm}`;
                    return (
                      <option key={timeString} value={timeString}>
                        {timeString}
                      </option>
                    );
                  })}
                </select>
              </div>

              {/* Separator */}
              <span style={{ fontSize: "18px", fontWeight: "bold" }}>—</span>

              {/* End Time */}
              <div style={{ flex: 1 }}>
                <label
                  htmlFor="end-time"
                  style={{
                    display: "block",
                    fontWeight: "bold",
                    fontSize: "14px",
                    marginBottom: "8px",
                  }}
                >
                  End Time
                </label>
                <select
                  id="end-time"
                  value={timeDetails.endTime}
                  onChange={(e) =>
                    setTimeDetails({ ...timeDetails, endTime: e.target.value })
                  }
                  style={{
                    width: "100%",
                    padding: "20px",
                    borderRadius: "6px",
                    border: "1px solid #ccc",
                    fontSize: "14px",
                    maxHeight: "150px", // Shorten dropdown
                    overflowY: "auto", // Enable scrolling for long dropdowns
                  }}
                >
                  {Array.from({ length: 24 * 2 }, (_, i) => {
                    const hour = Math.floor(i / 2);
                    const minute = i % 2 === 0 ? "00" : "30";
                    const ampm = hour < 12 ? "AM" : "PM";
                    const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
                    const timeString = `${formattedHour}:${minute} ${ampm}`;
                    return (
                      <option key={timeString} value={timeString}>
                        {timeString}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            {/* Buttons */}
            <div
              className="modal-buttons"
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <button
                onClick={() => setShowTimeModal(false)}
                style={{
                  backgroundColor: "#e0e0e0",
                  color: "#000",
                  border: "none",
                  padding: "8px 20px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  const { startTime, endTime } = timeDetails;

                  // Convert 12-hour format time to 24-hour equivalent for comparison
                  const convertTo24Hour = (time) => {
                    const [timeString, modifier] = time.split(" ");
                    let [hours, minutes] = timeString.split(":").map(Number);

                    if (modifier === "PM" && hours !== 12) {
                      hours += 12;
                    } else if (modifier === "AM" && hours === 12) {
                      hours = 0;
                    }

                    return hours * 60 + minutes; // Return total minutes from midnight
                  };

                  if (startTime && endTime) {
                    const startTimeMinutes = convertTo24Hour(startTime);
                    const endTimeMinutes = convertTo24Hour(endTime);

                    if (endTimeMinutes < startTimeMinutes) {
                      alert("End time cannot be earlier than start time");
                    } else {
                      handleTimeSave();
                    }
                  }
                }}
                style={{
                  backgroundColor: "#2979ff",
                  color: "#fff",
                  border: "none",
                  padding: "8px 20px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Add Expense Modal */}
      {showExpenseModal && (
        <div className="modal">
          <div className="modal-content">
            <h2 style={{ textAlign: "center", marginBottom: "15px" }}>
              Add Expense
            </h2>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  padding: "8px",
                  width: "100%",
                  maxWidth: "300px",
                }}
              >
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "18px",
                    marginRight: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {getCurrencySymbol(selectedCurrency)}
                </span>
                <input
                  type="number"
                  value={expense}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value);
                    if (
                      (value >= 0 && value <= 10000000) ||
                      e.target.value === ""
                    ) {
                      setExpense(e.target.value); // Only allow values between 0 and 10,000,000 or empty input
                    } else if (value > 10000000) {
                      alert(
                        "Expense cannot exceed 10 million. Please enter a valid amount."
                      );
                    }
                  }}
                  placeholder="0.00"
                  style={{
                    flex: "1",
                    border: "none",
                    outline: "none",
                    fontSize: "18px",
                  }}
                />
              </div>
              <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
                <button
                  onClick={() => setShowExpenseModal(false)}
                  style={{
                    backgroundColor: "#e0e0e0",
                    color: "#000",
                    border: "none",
                    padding: "8px 20px",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    if (
                      expense === "" ||
                      (parseFloat(expense) >= 0 &&
                        parseFloat(expense) <= 10000000)
                    ) {
                      handleExpenseSave(); // Save the expense only if it's valid
                    } else if (parseFloat(expense) > 10000000) {
                      alert(
                        "Expense cannot exceed 10 million. Please enter a valid amount."
                      );
                    } else {
                      alert(
                        "Expense cannot be negative. Please enter a valid amount."
                      );
                    }
                  }}
                  style={{
                    backgroundColor: "#2979ff",
                    color: "#fff",
                    border: "none",
                    padding: "8px 20px",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Attach Photo Modal */}
      {showAttachModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Attach Photo</h2>
            <input type="file" onChange={handleAttachSave} />
            <div className="modal-buttons">
              <button onClick={() => setShowAttachModal(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}

      {/* Edit Place Modal */}
      {showEditPlaceModal && (
        <div className="modal">
          <div className="modal-content">
            <h2 style={{ textAlign: "center", marginBottom: "15px" }}>
              Edit Place Name
            </h2>
            <input
              type="text"
              value={editPlaceName}
              onChange={(e) => setEditPlaceName(e.target.value)}
              placeholder="Place Name"
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "6px",
                border: "1px solid #ccc", // Add a light border
                fontSize: "16px",
                marginBottom: "15px",
                outline: "none", // Ensure no default outline on focus
              }}
            />
            <div
              className="modal-buttons"
              style={{
                display: "flex",
                justifyContent: "space-between", // Align buttons evenly
              }}
            >
              <button
                onClick={() => setShowEditPlaceModal(false)}
                style={{
                  backgroundColor: "#e0e0e0",
                  color: "#000",
                  border: "none",
                  padding: "8px 20px",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                Cancel
              </button>
              <button
                onClick={handleEditPlace}
                style={{
                  backgroundColor: "#2979ff",
                  color: "#fff",
                  border: "none",
                  padding: "8px 20px",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Edit Title Modal */}
      {showEditTitleModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Edit Itinerary Title</h2>
            <input
              type="text"
              value={editTitle}
              onChange={(e) => setEditTitle(e.target.value)}
              placeholder="Itinerary Title"
            />
            <div className="modal-buttons">
              <button onClick={() => setShowEditTitleModal(false)}>
                Cancel
              </button>
              <button onClick={handleEditTitle}>Save</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ManualItinerarySummary;
