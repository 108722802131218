import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { object, string } from "yup";
import "./Settings.css";
import "./AccountsModal.css";
import validatePassword from "./PasswordValidation";
import { hostURL } from "./config";
import { Navigate, useNavigate } from "react-router-dom";

const TOAST_STYLE = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
  style: {
    marginTop: "100px", // Add 100px margin from the top
  },
};

function Settings({ setUserData }) {
  const [activeButton, setActiveButton] = useState("privacy");
  const [onLoad, setOnLoad] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  // Set password value from delete modal
  const [passwordValue, setPasswordValue] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isShowDeleteAccount, setShowDeleteAccount] = useState(false);
  const [isShowConfirmDeleteAccount, setConfirmDeleteAccount] = useState(false);

  const [passwordField, setPasswordField] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [formData, setFormData] = useState({
    // firstName: '',
    // lastName: '',
    // username: '',
    // email: '',
    privacy: "public", // Default privacy setting
  });
  //const [errors, setErrors] = useState({});
  const [errors, setErrors] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const navigate = useNavigate();

  const token = localStorage.getItem("userToken");

  // Example of setting the token upon login
  //localStorage.setItem('userToken', response.data.token); // Assuming the token comes from the server response
  const privacyValue = null;

  const fetchUserData = async () => {
    // Ensure `token` is available
    if (!token) return;

    try {
      // Send a GET request to the server
      //const response = await axios.get("http://localhost:8081/user", {
      const response = await axios.get(`${hostURL}/user`, {
        headers: {
          Authorization: token,
        },
      });

      const { data } = response;
      console.log("Fetched User Data:", data);

      // Destructure data
      const {
        privacy,
        location,
        profile_pic,
        profile_url,
        website,
        created_at,
        updated_at,
        bio,
        ...formData
      } = data;

      // Default privacy setting
      const privacyValue = privacy || "public";

      // Update form data state
      setFormData({
        ...formData,
        privacy: privacyValue, // Use the determined privacy value
      });
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 200
        //toast.error(`Error: ${error.response.data.message}`, TOAST_STYLE);
      } else if (error.request) {
        // Request made but no response received
        //toast.error("Network error. Please try again later.", TOAST_STYLE);
      } else {
        // Other errors
        console.error(error);
      }
    }
  }; // Dependencies for `useCallback`

  useEffect(() => {
    fetchUserData();
  }, []);

  const handleButtonClick = (button) => {
    setActiveButton(button);
    setPasswordField(true);
    setErrors({ newPassword: "", confirmPassword: "" });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Update form data if the value changes
    setFormData((prevFormData) => {
      if (prevFormData[name] === value) return prevFormData; // Skip redundant updates
      return { ...prevFormData, [name]: value };
    });

    // Clear any validation errors for the current input
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));

    // Dynamically toggle `isChanged`
    setIsChanged(true); // Enable when a change occurs
  };

  const handleInputPassword = (e) => {
    const { name, value } = e.target;
    setPasswordField({
      ...passwordField,
      [name]: value,
    });
  };

  // const validate = () => {
  //     const newErrors = {};

  //     if (formData.firstName.length < 2) {
  //         newErrors.firstName = 'First name must atleast 2 characters.';
  //     } else {
  //         newErrors.firstName = "";
  //     }
  //     if (formData.firstName.length > 50) {
  //         newErrors.firstName = 'First name must not exceed 20 characters.';
  //     } else {
  //         newErrors.firstName = "";
  //     }

  //     if (formData.lastName.length > 50) {
  //         newErrors.lastName = 'Last name must not exceed 20 characters.';
  //     } else {
  //         newErrors.lastName = "";
  //     }
  //     if (formData.lastName.length < 2) {
  //         newErrors.lastName = 'Last name must atleast 2 characters.';
  //     } else {
  //         newErrors.lastName = "";
  //     }

  //     if (formData.username.length > 50) {
  //         newErrors.username = 'Username must not exceed 50 characters.';
  //     } else {
  //         newErrors.username = "";
  //     }
  //     if (formData.username.length < 2) {
  //         newErrors.username = 'Username must atleast 2 characters.';
  //     } else {
  //         newErrors.username = "";
  //     }

  //     // // Email validation
  //     // const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  //     // const trimmedEmail = formData.email ? formData.email.trim() : '';
  //     // if (!trimmedEmail) {
  //     //     newErrors.email = "Email should not be empty";
  //     // } else if (trimmedEmail.length > 254) {
  //     //     newErrors.email = "Email is too long (maximum 254 characters)";
  //     // } else if (!emailPattern.test(trimmedEmail)) {
  //     //     newErrors.email = "Invalid email format";
  //     // } else {
  //     //     newErrors.email = "";
  //     // }

  //     setErrors(newErrors);
  //     return newErrors; // Return true if no errors
  // };

  const validate = () => {
    const newErrors = {};

    // First Name Validation
    if (formData.firstName.length < 2) {
      newErrors.firstName = "First name must be at least 2 characters.";
    } else if (formData.firstName.length > 50) {
      newErrors.firstName = "First name must not exceed 50 characters.";
    }

    // Last Name Validation
    // if (formData.lastName.length < 2) {
    //     newErrors.lastName = 'Last name must be at least 2 characters.';
    // } else if (formData.lastName.length > 50) {
    //     newErrors.lastName = 'Last name must not exceed 50 characters.';
    // }

    // Username Validation
    // if (formData.username.length < 2) {
    //     newErrors.username = 'Username must be at least 2 characters.';
    // } else if (formData.username.length > 50) {
    //     newErrors.username = 'Username must not exceed 50 characters.';
    // }

    // // Email Validation
    // const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    // const trimmedEmail = formData.email ? formData.email.trim() : '';
    // if (!trimmedEmail) {
    //     newErrors.email = "Email should not be empty";
    // } else if (trimmedEmail.length > 254) {
    //     newErrors.email = "Email is too long (maximum 254 characters)";
    // } else if (!emailPattern.test(trimmedEmail)) {
    //     newErrors.email = "Invalid email format";
    // }

    setErrors(newErrors);
    return newErrors; // Return the errors object
  };

  const handleSubmit = async (e) => {
    setOnLoad(true);
    e.preventDefault();
    //toast.dismiss();

    // Validate form data before making the request
    const errors = validate();

    //alert(errors);

    // Ensure the errors are empty before making the login request
    // if (errors !== false) {
    //   window.confirm(errors);
    // }
    if (errors !== false) {
      const confirmSave = window.confirm(
        "Are you sure you want to save these changes?"
      );
      const { privacy, ...data } = formData;
      if (true) {
        try {
          const response = await axios.post(
            "http://localhost:8081/setting-info-user",
            data,
            {
              //const response = await axios.post(`${hostURL}/setting-info-user`,data, {
              headers: {
                Authorization: token,
              },
            }
          );
          if (response.status === 200) {
            toast.success("Updated Successfully", TOAST_STYLE);
            setIsChanged(false);
          } else if (response.status === 201) {
            toast.warn(response.data.message, TOAST_STYLE);
          } else {
            toast.error("Error Occured", TOAST_STYLE);
          }
        } catch (error) {
          if (error.response) {
            // Server responded with a status other than 200
            toast.error(`Error: ${error.response.data.message}`, TOAST_STYLE);
          } else if (error.request) {
            // Request made but no response received
            toast.error("Network error. Please try again later.", TOAST_STYLE);
          } else {
            // Other errors
            console.error(error);
          }
        }
      } else {
        setIsChanged(true);
        //toast.error("Account changes declined", TOAST_STYLE);
        fetchUserData();
      }
    } else {
      return;
    }
  };

  const handlePrivacyChange = async (e) => {
    setFormData({ ...formData, privacy: e.target.value });

    const confirmPrivacyChange = true;

    if (confirmPrivacyChange) {
      const body = {
        privacy: e.target.value,
        id: formData.id,
      };
      try {
        //const response = await axios.post("http://localhost:8081/update-privacy",body, {
        const response = await axios.post(`${hostURL}/update-privacy`, body, {
          headers: {
            Authorization: token,
          },
        });
        if (response.status === 200) {
          //toast.success("Privacy Changed", TOAST_STYLE);
        } else {
          //toast.error("Error Occured", TOAST_STYLE);
        }
      } catch (error) {
        if (error.response) {
          // Server responded with a status other than 200
          //toast.error(`Error: ${error.response.data.message}`, TOAST_STYLE);
        } else if (error.request) {
          // Request made but no response received
          //toast.error("Network error. Please try again later.", TOAST_STYLE);
        } else {
          // Other errors
          console.error(error);
        }
      }
    } else {
      //toast.error("Privacy changes declined", TOAST_STYLE);
      setFormData({ ...formData, privacy: privacyValue || "public" });
    }
  };

  // const handlePasswordChange = async (e) => {
  //   setOnLoad(true);
  //   e.preventDefault();
  //   //toast.dismiss();
  //   try {
  //     const { privacy, ...data } = passwordField;
  //     const sendData = {
  //       ...data,
  //       id: formData.id,
  //     };

  //     // Validate passwords
  //     const validationErrors = validatePassword(passwordField);
  //     if (Object.keys(validationErrors).length > 0) {
  //       setErrors(validationErrors);
  //       //toast.warn("Please fix the errors in the form", TOAST_STYLE);
  //       return;
  //     }

  //     const confirmSave = window.confirm("Are you sure you want to change your password?");
  //     if (!confirmSave) return;

  //     //const response = await axios.post("http://localhost:8081/update-password", sendData, {
  //     const response = await axios.post(`${hostURL}/update-password`,sendData, {
  //       headers: {
  //         Authorization: token,
  //       },
  //     });

  //     if (response.status === 200) {
  //       //toast.success("Password Updated", TOAST_STYLE);
  //     } else {
  //       //toast.warn(response.data.message, TOAST_STYLE);
  //     }
  //   } catch (error) {
  //         if (error.response) {
  //             // Server responded with a status other than 200
  //             //toast.error(`Error: ${error.response.data.message}`, TOAST_STYLE);
  //         } else if (error.request) {
  //             // Request made but no response received
  //             //toast.error("Network error. Please try again later.", TOAST_STYLE);
  //         } else {
  //             // Other errors
  //             console.error(error);
  //         }
  //   } finally {
  //     setOnLoad(false);
  //   }
  // };

  // Ensure hostURL is defined correctly

  const handlePasswordChange = async (e) => {
    setOnLoad(true);
    e.preventDefault();
    console.log("Tess");
    try {
      const { privacy, ...data } = passwordField;
      const sendData = {
        ...data,
        id: formData.id, // Ensure this is defined
      };

      // Debugging logs
      console.log("hostURL:", hostURL);
      console.log("formData:", formData);
      console.log("Password Field:", passwordField);

      const token = localStorage.getItem("userToken"); // Retrieve the token
      console.log("Retrieved Token:", token); // Log the token for debugging

      // Check if token is null
      if (!token) {
        toast.error(
          "User  is not authenticated. Please log in again.",
          TOAST_STYLE
        );
        return; // Exit if token is not available
      }

      // Validate passwords
      const validationErrors = validatePassword(passwordField);
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        if (validationErrors.oldPassword) {
          toast.warn(validationErrors.oldPassword, TOAST_STYLE);
        }
        return; // Exit if there are validation errors
      }

      const confirmSave = window.confirm(
        "Are you sure you want to change your password?"
      );
      if (!confirmSave) return;

      const response = await axios.post(
        `${hostURL}/update-password`,
        sendData,
        {
          headers: {
            Authorization: `${token}`, // Ensure the token is prefixed with 'Bearer ' if required
          },
        }
      );

      if (response.status === 200) {
        toast.success("Password Updated", TOAST_STYLE);
        setPasswordField({
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
      } else {
        toast.warn(response.data.message, TOAST_STYLE);
      }
    } catch (error) {
      if (error.response) {
        toast.error(`Error: ${error.response.data.message}`, TOAST_STYLE);
      } else if (error.request) {
        toast.error("Network error. Please try again later.", TOAST_STYLE);
      } else {
        console.error(error);
      }
    } finally {
      setOnLoad(false);
    }
  };

  const handleCloseModal = () => {
    setPasswordValue("");
    setConfirmPassword("");
    setShowDeleteAccount(false);
    setConfirmDeleteAccount(false);
  };

  const handleDeleteAccount = async (e) => {
    // e.preventDefault(); // Prevent the default form submission
    toast.dismiss();
    const token = localStorage.getItem("userToken");
    try {
      if (!confirmPassword) {
        toast.error(`Enter Confirm Password`, TOAST_STYLE);
      } else if (passwordValue !== confirmPassword) {
        toast.error(`Password did not match`, TOAST_STYLE);
      } else {
        const confirmSave = window.confirm(
          "Are you sure you want to delete the account?"
        );
        if (confirmSave) {
          try {
            //await axios.delete("http://localhost:8081/user", {
            const resp = await axios.post(
              `${hostURL}/delete-account`,
              { passwordValue },
              {
                headers: {
                  Authorization: token,
                },
              }
            );
            if (resp.status === 200) {
              localStorage.removeItem("userToken");
              localStorage.removeItem("profileCard");
              localStorage.removeItem("isLoggedIn");
              localStorage.removeItem("userId");
              navigate("/register", {
                state: { toastMessage: "Account deleted" },
              });
              window.location.reload();
            } else {
              toast.warn(resp.data.message, TOAST_STYLE);
            }
            // Optionally, handle user logout or redirect here
          } catch (error) {
            console.error("Error deleting account:", error);
          } finally {
            setConfirmDeleteAccount(false);
            setShowDeleteAccount(false);
          }
        }
      }
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 200
        toast.error(`Error: ${error.response.data.message}`, TOAST_STYLE);
      } else if (error.request) {
        // Request made but no response received
        toast.error("Network error. Please try again later.", TOAST_STYLE);
      } else {
        // Other errors
        console.error(error);
      }
    }
  };

  const deleteAccountStep1 = async (e) => {
    // e.preventDefault();
    toast.dismiss();
    if (passwordValue === "") {
      toast.warn("Enter Password to delete account", TOAST_STYLE);
    } else {
      try {
        //await axios.delete("http://localhost:8081/user", {
        const resp = await axios.post(
          `${hostURL}/delete/check-password`,
          { passwordValue },
          {
            headers: {
              Authorization: token,
            },
          }
        );
        if (resp.status === 200) {
          setConfirmDeleteAccount(true);
          setShowDeleteAccount(false);
        } else {
          toast.warn(resp.data.message, TOAST_STYLE);
        }
        // Optionally, handle user logout or redirect here
      } catch (error) {
        console.error("Error deleting account:", error);
      }
    }
  };
  // Make sure to bind this function to a button or form submit event

  return (
    <>
      <ToastContainer
        style={{ zIndex: 9999 }}
        position="top-right"
        autoClose={5000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
      />

      <div className="settings-container">
        <div className="content-input">
          {activeButton === "privacy" && (
            <div>
              <h3>Set Privacy Settings</h3>
              <p></p>
              <div className="pcontainer">
                <input
                  type="radio"
                  name="privacy"
                  id="public"
                  value="public"
                  checked={formData.privacy === "public"}
                  onChange={handlePrivacyChange}
                />
                <label htmlFor="public">
                  <span>Public</span>
                  <span className="description">
                    Anyone on or off Tripmatic
                  </span>
                </label>

                <input
                  type="radio"
                  name="privacy"
                  id="private"
                  value="private"
                  checked={formData.privacy === "private"}
                  onChange={handlePrivacyChange}
                />
                <label htmlFor="private">
                  <span>Private</span>
                  <span className="description">
                    Only I can see what I post
                  </span>
                </label>
              </div>
              {/* Division */}
              <p></p> <hr />
              {/* Start Change Password */}
              <h4>Change Password</h4>
              <form onSubmit={handlePasswordChange}>
                <div className="content-input-form">
                  <div className="form">
                    <label>Enter Current Password</label>
                    <input
                      type="password"
                      name="oldPassword"
                      value={passwordField.oldPassword}
                      onChange={handleInputPassword}
                      required
                    />
                  </div>
                  <p></p>
                  <div className="form">
                    <label>Enter New Password</label>
                    <input
                      type="password"
                      name="newPassword"
                      value={passwordField.newPassword}
                      onChange={handleInputPassword}
                      required
                    />
                    {errors.newPassword && (
                      <span
                        style={{ color: "red", fontSize: "14px" }}
                        className="error"
                      >
                        {errors.newPassword}
                      </span>
                    )}
                  </div>
                  <p></p>
                  <div className="form">
                    <label>Confirm New Password</label>
                    <input
                      type="password"
                      name="confirmPassword"
                      value={passwordField.confirmPassword}
                      onChange={handleInputPassword}
                      required
                    />
                    {errors.confirmPassword && (
                      <span
                        style={{ color: "red", fontSize: "14px" }}
                        className="error"
                      >
                        {errors.confirmPassword}
                      </span>
                    )}
                  </div>
                  <p></p>
                  <div className="form">
                    <button
                      type="submit"
                      disabled={onLoad}
                      className="save-button"
                    >
                      Change Password{" "}
                    </button>
                  </div>
                </div>
              </form>
              <hr />
              {/* End Change Password */}
              {/* Delete Account */}
              <div className="form_delete">
                <button
                  type="button"
                  onClick={() => setShowDeleteAccount(true)}
                  style={{
                    textDecoration: "none",
                    color: "#FA5252",
                    fontSize: "16px",
                  }}
                >
                  <img
                    src="../icons8-trash-16.png"
                    alt="Delete Account"
                    style={{ marginRight: "8px" }}
                  />
                  Delete Account
                </button>{" "}
                <p></p>
              </div>
              {/* Delete Modal 1 */}
              {isShowDeleteAccount && (
                <div
                  className="modal"
                  ref={(el) => {
                    if (el) {
                      el.style.setProperty("z-index", "1000", "important");
                    }
                  }}
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div
                        className="modal-header"
                        style={{
                          borderBottom: "none",
                          height: "40px",
                          marginTop: "5px",
                        }}
                      >
                        <h2>Delete Account</h2>
                        <span
                          style={{ display: "block", fontSize: "16px" }}
                          className="btn-close"
                          onClick={() => handleCloseModal()}
                        ></span>
                        <hr />
                      </div>
                      <form>
                        <div className="form-header">
                          <label
                            htmlFor="pass"
                            style={{
                              display: "block",
                              textAlign: "left",
                              fontSize: "14px",
                              marginRight: "30px",
                            }}
                          >
                            Enter Password
                          </label>
                        </div>

                        <div className="form-group">
                          <input
                            type="password"
                            id="pass"
                            value={passwordValue}
                            placeholder="password"
                            autoComplete="new-password"
                            name="password-delete"
                            onChange={(e) => {
                              setPasswordValue(e.target.value);
                            }}
                            style={{
                              width: "100%",
                              maxWidth: "320px",
                              minWidth: "150px",
                            }} // Adjustable width
                          />
                        </div>
                        <div className="form-group-settings justify-content-center">
                          <button
                            className="submit-btn-settings align-center w-75"
                            type="button"
                            onClick={() => {
                              deleteAccountStep1();
                            }}
                          >
                            Submit
                          </button>
                        </div>

                        <div className="form-footer">
                          <hr />
                          <h6
                            style={{
                              display: "block",
                              textAlign: "center",
                              fontSize: "13px",
                              fontWeight: "normal",
                            }}
                          >
                            <small>
                              Deleting your account will also remove all the
                              posts and itineraries you’ve shared on the
                              Tripmatic app.
                            </small>
                          </h6>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              )}
              {/* Delete Modal 2*/}
              {isShowConfirmDeleteAccount && (
                <div
                  className="modal"
                  ref={(el) => {
                    if (el) {
                      el.style.setProperty("z-index", "1000", "important");
                    }
                  }}
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div
                        className="modal-header"
                        style={{
                          borderBottom: "none",
                          height: "40px",
                          marginTop: "5px",
                        }}
                      >
                        <h2>Confirm Delete Account</h2>
                        <span
                          style={{ display: "block", fontSize: "16px" }}
                          className="btn-close"
                          onClick={() => handleCloseModal()}
                        ></span>
                        <hr />
                      </div>
                      <div className="form-header">
                        <label
                          htmlFor="pass"
                          style={{
                            display: "block",
                            textAlign: "left",
                            fontSize: "14px",
                            marginRight: "30px",
                          }}
                        >
                          Confirm Password
                        </label>
                      </div>

                      <div className="form-group">
                        <input
                          type="password"
                          id="pass"
                          value={confirmPassword}
                          placeholder="confirm password"
                          autoComplete="new-password"
                          name="password-confirm-delete"
                          onChange={(e) => {
                            setConfirmPassword(e.target.value);
                          }}
                          style={{
                            width: "100%",
                            maxWidth: "320px",
                            minWidth: "150px",
                            marginBottom: "-5px",
                          }} // Adjustable width
                        />
                      </div>
                      <div className="form-group-settings justify-content-center">
                        <button
                          className="delete-btn-settings align-center w-75"
                          onClick={() => handleDeleteAccount()}
                        >
                          Delete Account
                        </button>
                      </div>

                      <div className="form-footer">
                        <hr />
                        <h6
                          style={{
                            display: "block",
                            textAlign: "center",
                            fontSize: "13px",
                            fontWeight: "normal",
                          }}
                        >
                          <small>
                            Deleting your account will also remove all the posts
                            and itineraries you’ve shared on the Tripmatic app.{" "}
                          </small>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Settings;
