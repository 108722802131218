import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "./aiItinerary.css";
import hostURL from "./config";

function AIItinerary() {
  const [destination, setDestination] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [travelerType, setTravelerType] = useState("");
  const [budget, setBudget] = useState("");
  const [activities, setActivities] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    return localStorage.getItem("isLoggedIn") === "true";
  });

  const [isLoading, setIsLoading] = useState(false); // Loading state

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login"); // Redirect to login if not logged in
    }
  }, [isLoggedIn, navigate]);

  const handleActivityChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setActivities([...activities, value]);
    } else {
      setActivities(activities.filter((activity) => activity !== value));
    }
  };

  const handleSubmitAI = async (event) => {
    event.preventDefault();
    setErrorMessage("");
    setIsLoading(true); // Show loading screen

    try {
      const response = await axios.post(`${hostURL}/generate-itinerary`, {
        destination,
        startDate,
        endDate,
        travelerType,
        budget,
        activities,
      });

      if (response.data.itinerary) {
        setIsLoading(false); // Hide loading screen
        navigate("/generated-itinerary", {
          state: {
            itinerary: response.data.itinerary,
            destination,
            startDate,
            endDate,
          },
        });
      } else {
        setIsLoading(false); // Hide loading screen
        setErrorMessage("Failed to generate an itinerary. Please try again.");
      }
    } catch (error) {
      setIsLoading(false); // Hide loading screen
      console.error("Error generating itinerary:", error);
      setErrorMessage(
        error.response?.data?.error ||
          "Error generating itinerary. Please try again."
      );
    }
  };

  return (
    <div className="ai-itinerary-container">
      {isLoading && ( // Loading screen
        <div className="loading-overlay">
          <div className="loading-content">
            <p>Generating your itinerary...</p>
            <div className="spinner"></div>
          </div>
        </div>
      )}

      <h2>TripMatic: AI Itinerary Generator</h2>

      <form onSubmit={handleSubmitAI}>
        <div className="form-group">
          <input
            id="destination"
            type="text"
            placeholder="Where to?"
            value={destination}
            onChange={(e) => setDestination(e.target.value)}
            required
          />
        </div>

        <div className="form-row-extended">
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="startDate">Start Date</label>
              <input
                id="startDate"
                type="date"
                value={startDate}
                min={new Date().toISOString().split("T")[0]}
                onChange={(e) => {
                  const selectedDate = e.target.value;
                  setStartDate(selectedDate);
                  if (new Date(selectedDate) > new Date(endDate)) {
                    setEndDate("");
                  }
                }}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="endDate">End Date</label>
              <input
                id="endDate"
                type="date"
                value={endDate}
                min={startDate}
                onChange={(e) => setEndDate(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="form-group">
            <label>Select the kind of activities you want to do:</label>
            <div className="activity-checkboxes">
              <label>
                <input
                  type="checkbox"
                  value="Standard"
                  onChange={handleActivityChange}
                />
                Standard
              </label>
              <label>
                <input
                  type="checkbox"
                  value="Family Friendly"
                  onChange={handleActivityChange}
                />
                Family Friendly
              </label>
              <label>
                <input
                  type="checkbox"
                  value="Dog Friendly"
                  onChange={handleActivityChange}
                />
                Dog Friendly
              </label>
              <label>
                <input
                  type="checkbox"
                  value="Relaxation"
                  onChange={handleActivityChange}
                />
                Relaxation
              </label>
              <label>
                <input
                  type="checkbox"
                  value="Cultural Immersion"
                  onChange={handleActivityChange}
                />
                Cultural Immersion
              </label>
              <label>
                <input
                  type="checkbox"
                  value="Eco Friendly"
                  onChange={handleActivityChange}
                />
                Eco Friendly
              </label>
              <label>
                <input
                  type="checkbox"
                  value="Adventure"
                  onChange={handleActivityChange}
                />
                Adventure
              </label>
              <label>
                <input
                  type="checkbox"
                  value="City Experiences"
                  onChange={handleActivityChange}
                />
                City Experiences
              </label>
              <label>
                <input
                  type="checkbox"
                  value="Rail/Train/Bus Trip"
                  onChange={handleActivityChange}
                />
                Rail/Train/Bus Trip
              </label>
              <label>
                <input
                  type="checkbox"
                  value="Nature"
                  onChange={handleActivityChange}
                />
                Nature
              </label>
              <label>
                <input
                  type="checkbox"
                  value="Foodie"
                  onChange={handleActivityChange}
                />
                Foodie
              </label>
              <label>
                <input
                  type="checkbox"
                  value="Educational Trip"
                  onChange={handleActivityChange}
                />
                Educational Trip
              </label>
            </div>
          </div>
        </div>
        <div className="form-row-close">
          <div className="form-group">
            <label>Type of Traveler/s</label>
            <select
              value={travelerType}
              onChange={(e) => setTravelerType(e.target.value)}
            >
              <option value="">Select Type</option>
              <option value="solo">Solo</option>
              <option value="couple">Couple</option>
              <option value="family">Family</option>
              <option value="group">Group</option>
            </select>
          </div>

          <div className="form-group">
            <label>Budget</label>
            <select value={budget} onChange={(e) => setBudget(e.target.value)}>
              <option value="">Select Budget</option>
              <option value="Budget">Budget</option>
              <option value="Standard">Standard</option>
              <option value="Luxury">Luxury</option>
            </select>
          </div>
        </div>

        <button type="submit">Generate</button>
      </form>

      {errorMessage && (
        <div className="error-message">
          <p>{errorMessage}</p>
        </div>
      )}
    </div>
  );
}

export default AIItinerary;
