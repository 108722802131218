import React, { useState, useEffect } from "react";
import { bgs } from "../bgimages";
import "./Home.css";
import ha1 from "../homeassets/3.png";
import ha2 from "../homeassets/4.png";
import ha3 from "../homeassets/5.png";
import ha4 from "../homeassets/6.png";
import ha5 from "../homeassets/7.png";
import ha6 from "../homeassets/8.png";
import tmbear from "../homeassets/tmbear.png";
import pic2 from "../homeassets/pic2.png";
import pic3 from "../homeassets/pic3.png";
import emailjs from "emailjs-com";

function Home({ isLoggedIn }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  const [feedback, setFeedback] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [isSending, setIsSending] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % bgs.length);
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % bgs.length);
    }, 3000); // Change image every 3 seconds

    return () => {
      clearInterval(intervalId); // Clean up the interval when the component unmounts
    };
  }, []);

  // Handle form field changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFeedback((prev) => ({ ...prev, [name]: value }));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSending(true);
    setResponseMessage("");

    emailjs
      .send(
        "Tripmatic_FeedBack", // Replace with your EmailJS Service ID
        "template_1rlj888", // Replace with your EmailJS Template ID
        feedback,
        "MpfQcOYYKfrA81lNZ" // Replace with your EmailJS Public Key
      )
      .then(
        () => {
          setResponseMessage("Thank you for your feedback!");
          setFeedback({ name: "", email: "", subject: "", message: "" });
          setIsSending(false);
        },
        (error) => {
          console.error("Error sending feedback:", error);
          setResponseMessage("Failed to send feedback. Please try again.");
          setIsSending(false);
        }
      );
  };

  return (
    <div className="movingBG">
      <div className="home">
        <div className="homesection">
          <h1>One app for all your travel planning needs</h1>
          <p className="tight">
            Create detailed itineraries, explore user-shared guides, and manage
            your bookings seamlessly — all in one place.
          </p>
          <a
            href={`${isLoggedIn ? "/manualitinerary" : "/login"}`}
            className="capsulebutton"
          >
            Start Planning
          </a>
        </div>
        <div className="fadesection">
          {bgs.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Slideshow ${index}`}
              className={`fade-image ${
                currentImageIndex === index ? "show" : ""
              }`}
              style={{
                transitionDelay: `${index === currentImageIndex ? 0 : 0.5}s`,
              }}
            />
          ))}
        </div>
        <div className="homesection3 extramargin">
          <h1>The only tool you'll ever need</h1>
          <p className="tight">
            Tripmatic is the ultimate travel companion, empowering you to
            effortlessly create personalized itineraries for unforgettable
            adventures.
          </p>
          <div className="flexrow">
            <div className="featurecard">
              <img src={ha1} alt="tripmatic" />
              <h1>Manually Create Itinerary</h1>
              <p>Craft your perfect trip, one stop at a time</p>
            </div>

            <div className="featurecard">
              <img src={ha2} alt="tripmatic" />
              <h1>AI-Generated Itinerary</h1>
              <p>Get a personalized travel plan crafted by AI magic</p>
            </div>

            <div className="featurecard">
              <img src={ha3} alt="tripmatic" />
              <h1>Community Interaction</h1>
              <p>Connect, share, and explore with fellow travelers</p>
            </div>
          </div>
          <div className="flexrow">
            <div className="featurecard">
              <img src={ha4} alt="tripmatic" />
              <h1>Track Expenses</h1>
              <p>Keep your travel budget on point, every step of the way</p>
            </div>
            <div className="featurecard">
              <img src={ha5} alt="tripmatic" />
              <h1>Add Notes</h1>
              <p>Jot down your travel thoughts and details in one place</p>
            </div>

            <div className="featurecard">
              <img src={ha6} alt="tripmatic" />
              <h1>View Map</h1>
              <p>See your journey unfold on an interactive map</p>
            </div>
          </div>
        </div>

        <div className="homesection3 extramargin">
          <h1>Discover your next destination</h1>

          <p className="tight">
            Lost on where to go next? Let us be your compass, guiding you to
            hidden gems and must-see spots around the world!
          </p>
          <img src={tmbear} alt="tripmatic" className="widehomeimage" />
          <div className="flexrow2">
            <img src={pic2} alt="tripmatic" className="boxhomeimage" />

            <img src={pic3} alt="tripmatic" className="boxhomeimage" />
          </div>
        </div>

        <div className="homesection2 extramargin">
          <h1>Ready to plan your trip in half the time?</h1>
          <div className="flexrow">
            <a
              href={`${isLoggedIn ? "/manualitinerary" : "/login"}`}
              className="capsulebutton"
            >
              Start Planning
            </a>
          </div>
        </div>
        <div className="full-form">
          <div className="homesection2 extramargin">
            <h1>We Value Your Feedback</h1>
            <p className="tight">
              Let us know how we can improve your experience!
            </p>
            <form onSubmit={handleSubmit} className="feedback-form">
              <div className="form-group">
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={feedback.name}
                  onChange={handleInputChange}
                  required
                  placeholder="Enter your name"
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={feedback.email}
                  onChange={handleInputChange}
                  required
                  placeholder="Enter your email"
                />
              </div>
              <div className="form-group">
                <label htmlFor="subject">Subject:</label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  value={feedback.subject}
                  onChange={handleInputChange}
                  required
                  placeholder="Enter the subject"
                />
              </div>
              <div className="form-group">
                <label htmlFor="message">Message:</label>
                <textarea
                  id="message"
                  name="message"
                  value={feedback.message}
                  onChange={handleInputChange}
                  required
                  placeholder="Enter your message"
                ></textarea>
              </div>
              {responseMessage && (
                <p className="response-message">{responseMessage}</p>
              )}
              <button
                type="submit"
                className="capsulebutton"
                disabled={isSending}
              >
                {isSending ? "Sending..." : "Submit"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
