import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom"; // Use useNavigate for navigation
import "./index.css";
import Notifications from "./Notifications";
import { FaHome } from "react-icons/fa";
import { FaCirclePlus, FaUser, FaLocationPin } from "react-icons/fa6";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import UserDropdown from "./UserDropdown";
import image from "./pages/assets/logo.png";
import { GiHamburgerMenu } from "react-icons/gi";

import hostURL from "./pages/config";
function Navbar({ isLoggedIn, setIsLoggedIn, profileCard, user_id }) {
  //const navigate = useNavigate(); // React Router hook to programmatically navigate

  const [searchItems, setSearchItems] = useState([]);

  const [navProfile, setNavProfile] = useState("https://placehold.co/400");

  const [userid, setUserid] = useState(undefined);

  useEffect(() => {
    setNavProfile(profileCard);
  }, [profileCard]);

  useEffect(() => {
    setUserid(user_id);
  }, [user_id]);

  const fetchSearchItems = async () => {
    try {
      const response = await fetch(`${hostURL}/searchitems`); // Replace with your API URL
      if (!response.ok) {
        throw new Error("Error fetching search items");
      }
      const data = await response.json();

      // Check if there are itineraries
      if (data && data.length > 0) {
        var res = data.map((x) => {
          return {
            id: x.id,
            name: x.name,
            isLocation: x.is_itinerary,
          };
        });
        setSearchItems(res); // Store itineraries in the state
      } else {
        setSearchItems([]); // Handle empty data if needed
      }
    } catch (error) {
      console.error("Error fetching itineraries:", error);
      //setError(error.message); // Store the error message in state
    }
  };

  useEffect(() => {
    // Fetch search when the component mounts

    fetchSearchItems();
  }, []);

  const handleOnSearch = (string, results) => {
    fetchSearchItems();
  };

  const handleOnHover = (result) => {};

  const handleOnSelect = (item) => {
    window.location.href = `/${
      item.isLocation ? "viewitinerary" : "userprofile"
    }/${item.id}`;
    // navigate(`/${item.isLocation ? "itinerary" : "userprofile"}/${item.id}`);
  };

  const handleOnFocus = () => {};

  const formatResult = (item) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
          textAlign: "left",
        }}
      >
        <p
          style={{
            display: "flex !important",
            flexDirection: "row",
            gap: "1rem !important",
            width: "100%",
            alignItems: "center !important",
            alignSelf: "center !important",
            paddingTop: "1rem ",
            fontSize: "0.8rem",
          }}
        >
          {`${item.isLocation ? "Itinerary" : "User"} | ${item.name}`}
        </p>
      </div>
    );
  };

  const [showSideNav, setShowSideNav] = useState(false);

  const sideNavRef = useRef(null);

  // Detect click outside the modal
  useEffect(() => {
    if (showSideNav) {
      const handleClickOutside = (event) => {
        if (sideNavRef.current && !sideNavRef.current.contains(event.target)) {
          setShowSideNav(false); // Optionally close the modal when clicked outside
        }
      };

      // Add the event listener
      document.addEventListener("mousedown", handleClickOutside);

      // Clean up the event listener
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [showSideNav]);

  return (
    <nav className="navbar">
      <div className="logo">
        <Link to="/">
          <img src={image} alt="logo" className="logoimage" />
        </Link>
      </div>
      <ul className="nav-links">
        {!isLoggedIn ? null : (
          <Link
            to={!isLoggedIn ? "/" : "/feed"}
            className="linkWithLogo hidenav"
          >
            <FaHome color="#303030" size={25} />
            <div className="linkText">Home</div>
          </Link>
        )}

        {!isLoggedIn ? (
          <>
            <Link to="/login" className="linkWithLogo">
              <div className="linkText2">Login</div>
            </Link>

            <Link to="/register" className="capsulebuttonsignup">
              <div className="linkText2">Sign Up</div>
            </Link>
          </>
        ) : (
          <>
            {/* Add Itinerary Dropdown */}
            <div className="addItineraryContainer notificationsContainer hidenav">
              <FaCirclePlus color="#303030" size={25} />
              <div className="dropbtn">Add Itinerary</div>
              <div className="hoverContainer">
                <div className="addItineraryDropdownActive notificationContentActive">
                  <div className="addItineraryOption notifs">
                    <Link to="/manualItinerary">Manual Itinerary Maker</Link>
                  </div>
                  <div className="addItineraryOption notifs">
                    <Link to="/aiItinerary">AI Itinerary Generator</Link>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ width: "250px" }} className="hidenav">
              <ReactSearchAutocomplete
                items={searchItems}
                onSearch={handleOnSearch}
                onHover={handleOnHover}
                onSelect={handleOnSelect}
                onFocus={handleOnFocus}
                autoFocus
                formatResult={formatResult}
                styling={{
                  searchIconMargin: "0 0 0 16px",
                }}
                className="search"
              />
            </div>

            <Notifications user_id={userid} />

            <UserDropdown
              isLoggedIn={isLoggedIn}
              setIsLoggedIn={setIsLoggedIn}
              profileCard={navProfile}
            />
          </>
        )}

        {!isLoggedIn ? null : (
          <div
            ref={sideNavRef}
            className={`hamburger ${showSideNav ? "active" : ""}`}
            onClick={() => {
              setShowSideNav((x) => !x);
            }}
          >
            <GiHamburgerMenu size={25} />
          </div>
        )}
      </ul>

      {!isLoggedIn ? null : (
        <div className={`sidenavcontainer ${showSideNav ? "active" : ""}`}>
          <div ref={sideNavRef} className="sidenav">
            <div style={{ width: "250px", marginBottom: "1rem" }}>
              <ReactSearchAutocomplete
                items={searchItems}
                onSearch={handleOnSearch}
                onHover={handleOnHover}
                onSelect={handleOnSelect}
                onFocus={handleOnFocus}
                autoFocus
                formatResult={formatResult}
                styling={{
                  searchIconMargin: "0 0 0 16px",
                }}
                className="search"
              />
            </div>
            {!isLoggedIn ? null : (
              <Link to={!isLoggedIn ? "/" : "/feed"} className="sidenavlink">
                <div>Home</div>
              </Link>
            )}
            <Link to="/manualItinerary" className="sidenavlink">
              Manual Itinerary Maker
            </Link>
            <Link to="/aiItinerary" className="sidenavlink">
              AI Itinerary Generator
            </Link>
          </div>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
