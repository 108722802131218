import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import axios from "axios";
import styled from "styled-components";
import "./userprofile.css";
import "./styles.css";
import { SlOptions } from "react-icons/sl";
import jsPDF from "jspdf";
import logo from "./pages/assets/logo.png";
import "jspdf-autotable";

import { FaEdit, FaHeart, FaComments } from "react-icons/fa";
import dayjs from "dayjs";
import "./modal.css";
import { useParams } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

import profile from "./pages/assets/profile.png";
import place from "./pages/assets/placeholderplace.png";
import hostURL from "./pages/config";
import usernotfound from "./pages/assets/usernotlocated.png";
import { RiArrowGoBackFill } from "react-icons/ri";
import viewempty from "./pages/assets/viewempty.png";
import restricted from "./pages/assets/restricted.png";
export const AvatarInput = styled.div`
  margin-bottom: 32px;
  position: relative;
  align-self: center;

  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
  }

  .circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }

  label {
    right: 23em !important;
    position: absolute;
    width: 48px;
    height: 48px;
    background: #312e38;
    border-radius: 50%;
    right: 0;
    bottom: 0;
    border: 0;
    cursor: pointer;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;

    input {
      display: none;
    }

    svg {
      width: 20px;
      height: 20px;
      color: #f4ede8;
    }

    &:hover {
      background: blue;
    }
  }
`;

function ViewUserProfile() {
  const { userid } = useParams();
  const [display, setDisplay] = useState("publish");
  const [isCopied, setIsCopied] = useState(false);
  const [userData, setUserData] = useState({});
  const [profileCard, setProfileCard] = useState("https://placehold.co/400");
  const [initialValues, setInitialValue] = useState({
    firstName: "",
    lastName: "",
    bio: "",
    location: "",
    website: "",
    id: "-1",
  });

  const [loggedInUser, setLoggedInUser] = useState({
    firstName: "",
    lastName: "",
    bio: "",
    location: "",
    website: "",
    id: "-1",
    image_base64: "",
  });

  const [markers, setMarkers] = useState([]);

  const token = localStorage.getItem("userToken");
  const googleMapsApiKey = "AIzaSyCWQ3bmZoMMu6dkQ9TxUx8ln5lLQdNk_Do"; // Replace with your actual API key

  const fetchCurrentUser = async () => {
    try {
      if (!userid) return;

      if (userid) {
        const response = await axios.get(
          `${hostURL}/userprofile/${userid}`,
          {}
        );

        const { data } = response;

        setInitialValue({
          firstName: data.firstName || "",
          lastName: data.lastName || "",
          bio: data.bio || "",
          location: data.location || "",
          website: data.website || "",
          id: data.id,
          private: data.private || 0,
          image_base64: data.image_base64,
        });
        setProfileCard(data.image_base64);
        setUserData(data);

        // Fetch user location coordinates and set as a marker
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchLoggedInUser = async () => {
    try {
      if (token) {
        const response = await axios.get(`${hostURL}/user`, {
          headers: {
            Authorization: token,
          },
        });
        const { data } = response;

        setLoggedInUser({
          firstName: data.firstName || "",
          lastName: data.lastName || "",
          bio: data.bio || "",
          location: data.location || "",
          website: data.website || "",
          id: data.id,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [followings, setFollowings] = useState([]);

  const fetchFollows = useCallback(async () => {
    try {
      if (!userid) return;

      if (userid) {
        const response = await axios.get(
          `${hostURL}/follows?user_id=${userid}`,
          {}
        );

        const { data } = response;

        setFollowings(() => data);
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    fetchFollows();
  }, [fetchFollows]);

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  useEffect(() => {
    fetchLoggedInUser();
  }, []);

  const followingThisUser = useMemo(() => {
    return followings.find(
      (x) => x.follower === loggedInUser.id && x.following === initialValues.id
    )
      ? true
      : false;
  }, [followings, initialValues.id, loggedInUser.id]);

  const pageIsForLoggedIn = useMemo(() => {
    return loggedInUser.id === initialValues.id;
  }, [initialValues.id, loggedInUser.id]);
  const handleCopy = () => {
    const url = `${window.location.origin}/userprofile/${initialValues.id}`;
    navigator.clipboard.writeText(url).then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    });
  };

  const [itineraries, setItineraries] = useState([]);

  const [loading, setLoading] = useState(false);

  const fetchItineraries = async () => {
    if (!userid) {
      console.error("No token found. User not authenticated.");
      return;
    }

    try {
      if (!userid) {
        console.error("User ID not found. Unable to fetch itineraries.");
        return;
      }

      setLoading(true);

      // Fetch itineraries for the user
      const response = await fetch(`${hostURL}/itinerariesprofile/${userid}`); // Replace with your API URL
      if (!response.ok) {
        setItineraries([]);
        setLoading(false);
        throw new Error("Error fetching itineraries");
      }

      const data = await response.json();

      // Check if there are itineraries
      if (data && data.length > 0) {
        const res = data.map((x) => {
          return {
            id: x.id,
            user_id: x.user_id,
            title: x.trip_name,
            description: x.description,
            image: `${hostURL}/${x.photo}`, // Fallback image if no photo is available
            profilePicture: x.profile_pic,
            username: x.username || "No Name",
            numberOfComments: x.comments_count || 0,
            numberOfLikes: x.likes_count || 0,
            liked: x.liked || false,
            followed: x.followed || false,
            bookmarked: x.bookmarked || false,
            status: x.status,
            startDate: x.start,
            endDate: x.end,

            pic: x.pic,
          };
        });
        setItineraries(res); // Store itineraries in the state
      } else {
        setLoading(false);
        setItineraries([]); // Handle empty data if needed
      }
    } catch (error) {
      console.error("Error fetching itineraries:", error);
    } finally {
      setLoading(false);
    }
  };

  //export as pdf
  const exportToPDF = async (itineraryId) => {
    try {
      // Fetch itinerary details

      setLoading(true);
      const response = await axios.get(`${hostURL}/itinerary/${itineraryId}`);
      const itinerary = response.data;

      if (!itinerary) {
        return;
      }

      const {
        trip_name: tripName,
        destination_city: destinationCity,
        start_date: startDate,
        end_date: endDate,
        trip_description: tripdescription,
      } = itinerary;

      // Fetch places for the itinerary
      const placesResponse = await axios.get(
        `${hostURL}/api/places/${itineraryId}`
      );
      const places = placesResponse.data;

      // Initialize jsPDF
      const doc = new jsPDF();
      const pageWidth = doc.internal.pageSize.width;
      const margin = 10;
      let y = 20;

      // Add Logo
      const logoWidth = 40;
      const logoHeight = 25;
      const logoX = (pageWidth - logoWidth) / 2;
      doc.addImage(logo, "PNG", logoX, 10, logoWidth, logoHeight);
      y += logoHeight + 10;

      // Add Title
      doc.setFontSize(18);
      doc.setFont("helvetica", "bold");
      doc.text("Itinerary Summary", pageWidth / 2, y, { align: "center" });
      y += 10;

      // Add Itinerary Details
      doc.setFontSize(12);
      doc.setFont("helvetica", "normal");
      doc.text(`Title: ${tripName || "N/A"}`, margin, (y += 10));
      doc.text(`Destination: ${destinationCity || "N/A"}`, margin, (y += 10));
      doc.text(
        `Start Date: ${new Date(startDate).toLocaleDateString()}`,
        margin,
        (y += 10)
      );
      doc.text(
        `End Date: ${new Date(endDate).toLocaleDateString()}`,
        margin,
        (y += 10)
      );
      doc.text(`Description: ${tripdescription || "N/A"}`, margin, (y += 10));
      y += 10;

      // Group places by days
      const groupedPlaces = {};
      places.forEach((place) => {
        const day = place.day || "Unassigned Day";
        if (!groupedPlaces[day]) groupedPlaces[day] = [];
        groupedPlaces[day].push(place);
      });

      // Iterate through days and add data
      Object.keys(groupedPlaces).forEach((day, index) => {
        // Add Day Header
        doc.setFont("helvetica", "bold");
        doc.setFontSize(14);
        doc.text(`Day ${index + 1} - ${day}:`, margin, y);
        y += 10;

        // Prepare Table Data
        const tableData = groupedPlaces[day].map((place) => [
          place.name || "N/A",
          place.description || "N/A",
          place.time || "N/A",
          place.cost ? `₱${place.cost.toLocaleString()}` : "N/A",
        ]);

        // Add Table
        doc.autoTable({
          head: [["Place Name", "Description", "Time", "Cost"]],
          body: tableData,
          startY: y,
          styles: { fontSize: 10, cellPadding: 5 },
          headStyles: { fillColor: [40, 116, 240], textColor: [255, 255, 255] },
          theme: "striped",
          margin: { left: margin, right: margin },
          didDrawPage: (data) => {
            y = data.cursor.y + 10; // Update y position for the next content
          },
        });
      });

      // Save the PDF
      doc.save(`${tripName || "Itinerary"}.pdf`);
    } catch (error) {
      console.error("Error exporting PDF:", error);
      alert("Failed to export PDF. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fetch itineraries when the component mounts

    fetchItineraries();
  }, []);

  const handleFollow = useCallback(async () => {
    try {
      const dto = {
        user_id: loggedInUser.id,
        following_id: initialValues.id,
      };

      const response = await axios.post(`${hostURL}/follow`, dto);

      if (response.status === 200) {
        window.location.reload();
      }
    } catch (err) {
      console.log(err);
    }
  }, [loggedInUser, initialValues]);

  const handleUnfollow = useCallback(async () => {
    try {
      const dto = {
        user_id: loggedInUser.id,
        following_id: initialValues.id,
      };

      const unlikeResponse = await axios.delete(
        `${hostURL}/unfollow?user_id=${dto.user_id}&following_id=${dto.following_id}`,
        dto
      );

      if (unlikeResponse.status === 200) {
        window.location.reload();
      }
    } catch (err) {
      console.log(err);
    }
  }, [loggedInUser, initialValues]);

  const handleFollow2 = useCallback(
    (x) => async () => {
      try {
        const dto = {
          user_id: loggedInUser.id,
          following_id:
            loggedInUser.id !== x.follower ? x.follower : x.following,
        };

        const response = await axios.post(`${hostURL}/follow`, dto);

        if (response.status === 200) {
          window.location.reload();
        }
      } catch (err) {
        console.log(err);
      }
    },
    [loggedInUser]
  );

  const handleUnfollow2 = useCallback(
    (x) => async () => {
      try {
        const dto = {
          user_id: loggedInUser.id,
          following_id:
            loggedInUser.id !== x.follower ? x.follower : x.following,
        };

        const unlikeResponse = await axios.delete(
          `${hostURL}/unfollow?user_id=${dto.user_id}&following_id=${dto.following_id}`,
          dto
        );

        if (unlikeResponse.status === 200) {
          window.location.reload();
        }
      } catch (err) {
        console.log(err);
      }
    },
    [loggedInUser]
  );

  const handleImageFallback = (e) => {
    e.target.onerror = null; // Prevent an infinite loop if the fallback image fails
    e.target.src = place; // Set the fallback image source
  };

  const handleProfileFallback = (e) => {
    e.target.onerror = null; // Prevent an infinite loop if the fallback image fails
    e.target.src = profile; // Set the fallback image source
  };

  const returnItineraryCard = (x) => {
    return (
      <div className="itinerarycard" key={x.id}>
        <div className="itinerarycard-content">
          <h1
            onClick={() => {
              window.location.href = `/viewitinerary/${x.id}`;
            }}
            style={{
              color: "darkblue !important",
              cursor: "pointer",
            }}
          >
            {x?.title?.length > 23 ? x.title.slice(0, 23) + "..." : x.title}
          </h1>

          <span>{`${dayjs(x.startDate).format("MMM DD, YYYY")} - ${dayjs(
            x.endDate
          ).format("MMM DD, YYYY")}`}</span>
          <p className="toEllipsis" style={{ flex: "2" }}>
            {x?.description?.length > 90
              ? x.description.slice(0, 90) + "..."
              : x.description}
          </p>

          <div className="itinerarycard-content-footer">
            <div className="genericrow">
              {pageIsForLoggedIn ? (
                <div className="genericrow2 genericbutton">
                  <FaEdit size={18} color="black" />
                  <span className="tohide">Edit Trip</span>
                </div>
              ) : null}

              <div className="genericrow2 genericbutton optionsactivator">
                <div className="  itinerarycard-options">
                  <div className="itinerarycard-optionscontent">
                    <button
                      type="button"
                      className="itinerarycard-option"
                      onClick={() => exportToPDF(x.id)} // Pass only the itinerary ID
                    >
                      Export PDF
                    </button>
                  </div>
                </div>
                <SlOptions size={18} color="black" />
                <span className="tohide">More Options</span>
              </div>
            </div>
            <div className="genericrow">
              <div className="genericrow2 genericbutton">
                <FaHeart
                  size={18}
                  color={x.numberOfLikes > 0 ? "red" : "gray"}
                />
                <span>{x.numberOfLikes}</span>
              </div>
              <div className="genericrow2 genericbutton">
                <FaComments size={18} color="gray" />
                <span>{x.numberOfComments}</span>
              </div>
            </div>
          </div>
        </div>

        <img
          onClick={() => {
            window.location.href = `/viewitinerary/${x.id}`;
          }}
          style={{
            cursor: "pointer",
          }}
          src={x.image}
          alt="itinerary"
          className="itinerarycard-image"
          onerror={handleImageFallback}
        />
      </div>
    );
  };

  const [followsModal, setFollowsModal] = useState(false);
  const [followSelected, setFollowSelected] = useState(0);

  const followModalRef = useRef(null);

  // Detect click outside the modal
  useEffect(() => {
    if (followsModal) {
      const handleClickOutside = (event) => {
        if (
          followModalRef.current &&
          !followModalRef.current.contains(event.target)
        ) {
          setFollowsModal(false); // Optionally close the modal when clicked outside
        }
      };

      // Add the event listener
      document.addEventListener("mousedown", handleClickOutside);

      // Clean up the event listener
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [followsModal]);

  const UserProfileContent = () => (
    <div className="generic-container" key={userid}>
      {loading ? (
        <div className="loading-overlay">
          <div className="loading-content">
            <p>Loading...</p>
            <div className="spinner"></div>
          </div>
        </div>
      ) : null}
      <div className={`modalcontainer ${followsModal ? "active" : ""}`}>
        <div ref={followModalRef} className="profilebox">
          <div className="profilemodalheader">
            <h1
              className={`${followSelected === 0 ? "active" : ""}`}
              onClick={() => {
                setFollowSelected(0);
                setFollowsModal(true);
              }}
            >
              FOLLOWERS
            </h1>
            <h1
              className={`${followSelected === 1 ? "active" : ""}`}
              onClick={() => {
                setFollowSelected(1);
                setFollowsModal(true);
              }}
            >
              FOLLOWING
            </h1>
          </div>

          <div className="profilemodalcontent">
            {followings.filter((x) =>
              followSelected === 0
                ? x.following === initialValues.id
                : x.follower === initialValues.id
            ).length > 0 ? (
              followings
                .filter((x) =>
                  followSelected === 0
                    ? x.following === initialValues.id
                    : x.follower === initialValues.id
                )
                .map((y, i) => {
                  return (
                    <div key={i}>
                      <div className="profilerow">
                        <LazyLoadImage
                          className="profileimage"
                          src={
                            followSelected === 0
                              ? y.follower_pic !== ""
                                ? y.follower_pic
                                : profile
                              : y.following_pic !== ""
                              ? y.following_pic
                              : profile
                          }
                          alt="profile"
                          onError={handleProfileFallback}
                          onClick={() => {
                            window.location.href = `/userprofile/${
                              followSelected === 0 ? y.follower : y.following
                            }`;
                          }}
                          style={{
                            cursor: "pointer",
                          }}
                        />
                        <div
                          className="columnstartmodal"
                          onClick={() => {
                            window.location.href = `/userprofile/${
                              followSelected === 0 ? y.follower : y.following
                            }`;
                          }}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <div>
                            {followSelected === 0
                              ? y.follower_name
                              : y.following_name}
                          </div>
                          <div>
                            @
                            {followSelected === 0
                              ? y.follower_username
                              : y.following_username}
                          </div>
                        </div>
                      </div>
                      {pageIsForLoggedIn ? (
                        <input
                          type="button"
                          value={`${
                            followSelected === 0
                              ? followings.find(
                                  (x) =>
                                    x.follower === loggedInUser.id &&
                                    x.following === y.follower
                                )
                                ? "UNFOLLOW"
                                : "FOLLOW"
                              : followings.find(
                                  (x) =>
                                    x.follower === loggedInUser.id &&
                                    x.following === y.following
                                )
                              ? "UNFOLLOW"
                              : "FOLLOW"
                          }`}
                          onClick={
                            followSelected === 0
                              ? followings.find(
                                  (x) =>
                                    x.follower === loggedInUser.id &&
                                    x.following === y.follower
                                )
                                ? handleUnfollow2(y)
                                : handleFollow2(y)
                              : followings.find(
                                  (x) =>
                                    x.follower === loggedInUser.id &&
                                    x.following === y.following
                                )
                              ? handleUnfollow2(y)
                              : handleFollow2(y)
                          }
                          className="profilemodalbutton"
                        />
                      ) : null}
                    </div>
                  );
                })
            ) : (
              <div>There is nothing here</div>
            )}
          </div>

          <div className="modalbottom">
            <input
              type="button"
              value="CLOSE"
              onClick={() => {
                setFollowsModal(false);
              }}
              className="modalButton"
            />
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="profile-card">
            <LazyLoadImage
              alt="Profile"
              src={profileCard}
              className="circleimage"
              onError={handleProfileFallback}
            />
            <h2>
              {userData.firstName} {userData.lastName}
            </h2>
            <p className="profusername">@{userData.username}</p>
            <p className="card-text bio">{userData.bio}</p>
            {userData.website && (
              <button
                className="btn  mb-3 mt-1 websitecont"
                onClick={() => {
                  window.open(
                    userData.website.startsWith("http://")
                      ? userData.website
                      : "http://" + userData.website,
                    "_blank"
                  );
                }}
              >
                <i className="bi bi-facebook"></i> {userData.website}
              </button>
            )}
            <div className="stats">
              <div
                onClick={() => {
                  setFollowSelected(0);
                  setFollowsModal(true);
                }}
                style={{ cursor: "pointer" }}
              >
                <p>
                  {
                    followings.filter((x) => x.following === initialValues.id)
                      .length
                  }
                </p>
                <span>FOLLOWERS</span>
              </div>
              <div
                onClick={() => {
                  setFollowSelected(1);
                  setFollowsModal(true);
                }}
                style={{ cursor: "pointer" }}
              >
                <p>
                  {
                    followings.filter((x) => x.follower === initialValues.id)
                      .length
                  }
                </p>
                <span>FOLLOWING</span>
              </div>
            </div>
            <div className="actions">
              {pageIsForLoggedIn ||
              (initialValues.id !== loggedInUser.id &&
                initialValues.private === 1) ? null : followingThisUser ? (
                <button className="follow" onClick={handleUnfollow}>
                  Unfollow
                </button>
              ) : (
                <button className="follow" onClick={handleFollow}>
                  Follow
                </button>
              )}

              <button className="copy-link" onClick={handleCopy}>
                <i className="fas fa-link"></i>
                {isCopied ? "Link Copied!" : "Copy Link"}
              </button>
            </div>
          </div>

          <div className="widecolumn">
            {/* <div className="map-container col-7">
              <MyMap userLocation={userData.location} markers={markers} />
            </div> */}

            <div className="button-container">
              <div className="menu">
                <nav className="nav">
                  <button
                    className={`nav-link menu-item ${
                      display === "publish" ? "saved" : ""
                    }`}
                    onClick={() => setDisplay("publish")}
                  >
                    <i className="fas fa-print"></i> <b>Published</b>
                  </button>
                </nav>
              </div>
            </div>

            {display === "publish" && (
              <div className="itinerarycard-column">
                {initialValues.id !== loggedInUser.id &&
                initialValues.private === 1 ? (
                  <div className="mt-3">
                    <LazyLoadImage
                      src={restricted}
                      className="usererrorimage"
                    />
                  </div>
                ) : itineraries.filter(
                    (x) =>
                      x.status === "published" && x.user_id === initialValues.id
                  ).length > 0 ? (
                  itineraries
                    .filter(
                      (x) =>
                        x.status === "published" &&
                        x.user_id === initialValues.id
                    )
                    .map((x) => {
                      return returnItineraryCard(x);
                    })
                ) : (
                  <div className="mt-3">
                    <LazyLoadImage src={viewempty} className="usererrorimage" />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  if (!initialValues || initialValues.id == -1 || initialValues == "") {
    return (
      <div className="viewItineraryContainer">
        <div
          className="controw"
          onClick={() => {
            window.history.back();
          }}
          style={{ cursor: "pointer" }}
        >
          <RiArrowGoBackFill color="gray" size={35} />
          <div>Go Back</div>
        </div>
        <div className="viewitinerarybodyerror">
          <LazyLoadImage
            src={usernotfound}
            alt="place"
            className="viewerrorimage"
          />
        </div>
      </div>
    );
  }

  const Placeholder = () => (
    <div className="container-fluid">
      <div className="row">
        <div className="profile-card col-3">
          <LazyLoadImage
            alt="Profile picture placeholder"
            src="./addprofilepic.png"
          />
          <h2>Jette Smith</h2>
          <p className="card-text text-muted">@jettesmith_123</p>
          <p className="card-text">To Travel is to Live. ✈️</p>
          <a
            href="https://facebook.com/"
            className="btn btn-outline-primary mb-3"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="bi bi-facebook"></i> facebook.com/jettyysmith
          </a>
          <div className="stats">
            <div>
              <p>
                {
                  followings.filter((x) => x.following === initialValues.id)
                    .length
                }
              </p>
              <span>FOLLOWERS</span>
            </div>
            <div>
              <p>
                {
                  followings.filter((x) => x.follower === initialValues.id)
                    .length
                }
              </p>
              <span>FOLLOWING</span>
            </div>
          </div>
          <div className="actions">
            <button className="edit">
              <img src="./edit.png" alt="Edit icon" />
              Edit
            </button>
            <button className="copy-link" onClick={handleCopy}>
              <i className="fas fa-link"></i>
              {isCopied ? "Link Copied!" : "Copy Link"}
            </button>
          </div>
        </div>
        <div className="map-container col-7">
          <div className="button-container">
            <div className="menu">
              <nav className="nav">
                <button
                  type="button"
                  className={`nav-link menu-item ${
                    display === "publish" ? "saved" : ""
                  }`}
                  onClick={() => setDisplay("publish")}
                  href="#"
                >
                  <i className="fas fa-print"></i> <b>Published</b>
                </button>
              </nav>
            </div>
          </div>
          {display === "publish" && (
            <div className="mt-3">
              <center>
                There is nothing here. <br /> Create itineraries to publish!
              </center>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <>{userData || loggedInUser ? <UserProfileContent /> : <Placeholder />}</>
  );
}

export default ViewUserProfile;
