import "./App.css";
import Navbar from "./Navbar.js";
import Home from "./pages/Home.js";
import Login from "./pages/Login.js";
import Register from "./pages/Register.js";
import Feed from "./pages/Feed.js";
import UserProfile from "./UserProfile";
import UserDropdown from "./UserDropdown";
import EditItinerary from "./pages/EditItinerary";
import Settings from "./Settings";
import AddItinerary from "./pages/AddItinerary.js"; // Import AddItinerary component
import ManualItinerary from "./pages/ManualItinerary.js"; // Import Manual Itinerary component
import AIItinerary from "./pages/AIItinerary.js"; // Import AI Itinerary component
import GeneratedItinerary from "./pages/GeneratedItinerary.js"; // Import Generated Itinerary component
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import ChangePassword from "./pages/reset-password/change-password.js";
import "leaflet/dist/leaflet.css";
import ManualItinerarySummary from "./pages/ManualItinerarySummary";

import image from "./pages/assets/logo.png";
import ViewUserProfile from "./ViewUserProfile.js";
import axios from "axios";
import ViewItinerary from "./pages/ViewItinerary.js";

import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import NotFoundImage from "./pages/assets/404.png";
import { RiArrowGoBackFill } from "react-icons/ri";

function App() {
  //For Main Fix3

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [profileCard, setProfileCard] = useState(
    localStorage.getItem("profileCard")
  );
  const userId = localStorage.getItem("userId");
  useEffect(() => {
    const isAuth = localStorage.getItem("isLoggedIn") === "true";
    setIsLoggedIn(isAuth);
  }, []);
  const NotFound = () => {
    return (
      <div className="viewItineraryContainer">
        <div
          className="controw"
          onClick={() => {
            window.history.back();
          }}
          style={{ cursor: "pointer" }}
        >
          <RiArrowGoBackFill color="gray" size={35} />
          <div>Go Back</div>
        </div>
        <div className="viewitinerarybodyerror">
          <LazyLoadImage
            src={NotFoundImage}
            alt="place"
            className="viewerrorimage"
          />
        </div>
      </div>
    );
  };

  return (
    <BrowserRouter className="App">
      <Navbar
        key={profileCard}
        isLoggedIn={isLoggedIn}
        setIsLoggedIn={setIsLoggedIn}
        profileCard={profileCard}
        user_id={userId}
      />
      <Routes>
        <Route path="/" element={<Home isLoggedIn={isLoggedIn} />} />
        <Route path="/home" element={<Home isLoggedIn={isLoggedIn} />} />
        <Route
          path="/login"
          element={<Login setIsLoggedIn={setIsLoggedIn} />}
        />
        <Route path="/register" element={<Register />} />
        <Route
          path="/feed"
          element={
            isLoggedIn ? <Feed /> : <Login setIsLoggedIn={setIsLoggedIn} />
          }
        />
        <Route
          path="/addItinerary"
          element={
            !isLoggedIn ? (
              <Login setIsLoggedIn={setIsLoggedIn} />
            ) : (
              <AddItinerary />
            )
          }
        />
        <Route
          path="/userprofile"
          element={
            !isLoggedIn ? (
              <Login setIsLoggedIn={setIsLoggedIn} />
            ) : (
              <UserProfile
                setIsLoggedIn={setIsLoggedIn}
                setProfileCardForApp={setProfileCard}
              />
            )
          }
        />

        <Route
          path="/settings"
          element={
            !isLoggedIn ? <Login setIsLoggedIn={setIsLoggedIn} /> : <Settings />
          }
        />
        <Route
          path="/login/:token?"
          element={<Login setIsLoggedIn={setIsLoggedIn} />}
        />
        <Route
          path="/reset-password/:hash/:token"
          element={<ChangePassword />}
        />
        <Route
          path="/manualitinerary"
          element={
            !isLoggedIn ? (
              <Login setIsLoggedIn={setIsLoggedIn} />
            ) : (
              <ManualItinerary />
            )
          }
        />
        <Route
          path="/aiItinerary"
          element={
            !isLoggedIn ? (
              <Login setIsLoggedIn={setIsLoggedIn} />
            ) : (
              <AIItinerary />
            )
          }
        />
        <Route
          path="/generated-itinerary"
          element={
            !isLoggedIn ? (
              <Login setIsLoggedIn={setIsLoggedIn} />
            ) : (
              <GeneratedItinerary />
            )
          }
        />

        <Route
          path="/userprofile/:userid"
          element={
            !isLoggedIn ? (
              <Login setIsLoggedIn={setIsLoggedIn} />
            ) : (
              <ViewUserProfile />
            )
          }
        />
        <Route
          path="/manualitinerarysummary"
          element={
            !isLoggedIn ? (
              <Login setIsLoggedIn={setIsLoggedIn} />
            ) : (
              <ManualItinerarySummary />
            )
          }
        />
        <Route
          path="/viewitinerary/:itinerary_id"
          element={
            !isLoggedIn ? (
              <Login setIsLoggedIn={setIsLoggedIn} />
            ) : (
              <ViewItinerary profileCard={profileCard} />
            )
          }
        />
        <Route
          path="/edit-itinerary/:itinerary_id"
          element={
            !isLoggedIn ? (
              <Login setIsLoggedIn={setIsLoggedIn} />
            ) : (
              <EditItinerary />
            )
          }
        />

        {/* Catch-all route for non-existent paths */}
        <Route path="*" element={<NotFound />} />
      </Routes>

      <div className="foot">
        <div className="foottop">
          <div className="footcolwide">
            <img src={image} alt="bear" className="footimg" />
            <p>
              Turn your next trip into a hassle-free experience with Tripmatic.
            </p>
          </div>
          <div className="footrow">
            <div className="footcolnarrow">
              <Link to="/home">Home</Link>
              <Link to="/manualItinerary">Manual Itinerary Maker</Link>
              <Link to="/aiItinerary">AI Itinerary Generator</Link>
            </div>
          </div>
        </div>
        <p>© 2024 Tripmatic. All rights reserved</p>
      </div>
    </BrowserRouter>
  );
}

export default App;
