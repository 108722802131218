import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./manualItinerary.css";

function ManualItinerary() {
  const [tripName, setTripName] = useState("");
  const [destinationCity, setDestinationCity] = useState("");
  const [manualStartDate, setManualStartDate] = useState("");
  const [manualEndDate, setManualEndDate] = useState("");
  const [photo, setPhoto] = useState(null);

  const navigate = useNavigate();

  const handlePhotoChange = (event) => {
    const file = event.target.files[0];

    // Validate file type
    const validImageTypes = [
      "image/jpeg",
      "image/png",
      "image/jpg",
      "image/gif",
    ];
    if (!validImageTypes.includes(file.type)) {
      alert(
        "Invalid file type. Please upload an image file (JPEG, PNG, JPG, or GIF)."
      );
      return;
    }

    // Validation passed, update the state
    setPhoto(file);
  };

  const handleCancel = () => {
    setTripName("");
    setDestinationCity("");
    setManualStartDate("");
    setManualEndDate("");
    setPhoto(null);
  };

  const handleSubmitManual = async (event) => {
    event.preventDefault();

    // Validate form fields
    if (!tripName || !destinationCity || !manualStartDate || !manualEndDate) {
      alert("Please fill in all required fields.");
      return;
    }

    // Navigate to the summary page with the trip details
    navigate("/manualitinerarysummary", {
      state: {
        tripName,
        destinationCity,
        manualStartDate,
        manualEndDate,
        photo,
      },
    });
  };

  return (
    <div className="manual-itinerary-container">
      <form onSubmit={handleSubmitManual}>
        <div className="form-section">
          <h2>Add Trip</h2>
          <p>Add a trip manually below.</p>

          <div className="form-group-with-photo">
            <div style={{ width: "100%" }}>
              <div className="form-group">
                <label htmlFor="tripName">Trip Name *</label>
                <input
                  type="text"
                  id="tripName"
                  value={tripName}
                  onChange={(e) => setTripName(e.target.value)}
                  required
                  placeholder="Enter trip name"
                />
              </div>

              <div className="form-group">
                <label htmlFor="destinationCity">Destination City *</label>
                <input
                  type="text"
                  id="destinationCity"
                  value={destinationCity}
                  onChange={(e) => setDestinationCity(e.target.value)}
                  required
                  placeholder="Enter destination"
                />
              </div>
            </div>

            <div className="photo-upload">
              <img
                src={
                  photo
                    ? URL.createObjectURL(photo)
                    : "https://via.placeholder.com/250x150"
                }
                alt="Trip"
                className="trip-photo-large"
              />
              <label htmlFor="photoUpload" className="change-photo-label">
                <input
                  type="file"
                  id="photoUpload"
                  accept="image/*"
                  onChange={handlePhotoChange}
                  style={{ display: "none" }}
                />
                <i className="fa fa-camera"></i> Change Photo
              </label>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="manualStartDate">Start Date *</label>
              <input
                type="date"
                id="manualStartDate"
                value={manualStartDate}
                onChange={(e) => setManualStartDate(e.target.value)}
                min={new Date().toISOString().split("T")[0]} // Prevent selecting dates before today
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="manualEndDate">End Date *</label>
              <input
                type="date"
                id="manualEndDate"
                value={manualEndDate}
                onChange={(e) => setManualEndDate(e.target.value)}
                min={manualStartDate || new Date().toISOString().split("T")[0]} // Prevent selecting dates before start date
                required
              />
            </div>
          </div>

          <div className="button-group">
            <button type="button" className="cancel-btn" onClick={handleCancel}>
              Cancel
            </button>
            <button type="submit" className="save-btn">
              Next
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ManualItinerary;
