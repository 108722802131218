import React, { useCallback, useEffect, useRef, useState } from "react";
import "../viewitinerary.css";
import { FaBookmark, FaHeart, FaTrash } from "react-icons/fa";
import { FaComments } from "react-icons/fa";
import { RiArrowGoBackLine } from "react-icons/ri";
import axios from "axios";
import { FaLocationPin } from "react-icons/fa6";

import { LazyLoadImage } from "react-lazy-load-image-component";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import InfiniteScroll from "react-infinite-scroll-component";
import notpublic from "./assets/notpublic.png";
import wrong from "./assets/wrong.png";
import placeholderplace from "./assets/placeholderplace.png";
import profile from "./assets/profile.png";
import customMarkerIcon from "./assets/custom-marker.png";

import hostURL from "./config";
function ViewItinerary({ profileCard }) {
  const { itinerary_id } = useParams();

  console.log("tripid", itinerary_id);

  const sectionRef = useRef(null);
  const userId = localStorage.getItem("userId");
  const [markers, setMarkers] = useState([]);
  const mapRef = useRef(null);
  const googleMapsApiKey = "AIzaSyCWQ3bmZoMMu6dkQ9TxUx8ln5lLQdNk_Do"; // Replace with your Google Maps API key
  const [isLoading, setIsLoading] = useState(false);

  const [itinerary, setItinerary] = useState({
    id: itinerary_id,
    user_id: -1,
    user_pic: "https://placehold.co/400",
    username: "",
    user_name: "",
    trip_name: "",
    destination_city: "",
    start_date: "",
    end_date: "",
    photo: "https://placehold.co/400",
    created_at: dayjs(),
    status: "",
    tripdescription: "",
    perDay: [],
    comments: [],
    isPrivate: false,
    liked: false,
    followed: false,
    bookmarked: false,
    likes_count: 0,
    comments_count: 0,
  });

  const [comments, setComments] = useState([]);

  const fetchItinerary = async () => {
    if (!itinerary_id) return;

    setIsLoading(true);
    try {
      if (!userId) {
        console.error("User ID not found. Unable to fetch itinerary.");
        return;
      }

      if (itinerary_id) {
        const response = await axios.get(
          `${hostURL}/view-itinerary/${itinerary_id}?user_id=${userId}`,
          {}
        );

        const { data } = response;

        console.log(data);
        let transformed = {
          ...data,
          places: JSON.parse(data.places),
          //     comments: JSON.parse(data.comments),
        };

        let byDay2 = transformed.places.reduce((acc, place) => {
          const day = place.day || "Day 1";

          var dayObject = {
            date: "",
            places: [],
            id: 0,
          };

          if (acc) {
            if (!acc.find((x, i) => x.date === day)) {
              dayObject.date = day;
              dayObject.id = acc.length;
              dayObject.places.push(place);
              acc.push(dayObject);
            } else {
              dayObject = acc.find((x) => x.date === day);

              dayObject.places.push(place);
              acc[dayObject.id] = dayObject;
            }
          }

          return acc;
        }, []);

        console.log(byDay2);
        const res = { ...transformed, byDay: byDay2 };

        console.log(res);
        setItinerary(res);
      }
    } catch (err) {
      console.log("Error viewing itinerary", err);
    } finally {
      setIsLoading(false);
    }
  };

  const geocodePlace = async (placeName) => {
    try {
      const response = await axios.get(
        "https://maps.googleapis.com/maps/api/geocode/json",
        {
          params: {
            address: placeName,
            key: googleMapsApiKey,
          },
        }
      );
      if (response.data.status === "OK") {
        return response.data.results[0].geometry.location; // { lat, lng }
      } else {
        console.warn(`Geocoding failed for place: ${placeName}`);
        return null;
      }
    } catch (error) {
      console.error(`Error geocoding place: ${placeName}`, error);
      return null;
    }
  };

  const prepareMarkers = useCallback(async () => {
    const newMarkers = [];
    for (const day of itinerary.byDay || []) {
      for (const place of day.places || []) {
        if (place.lat && place.lng) {
          newMarkers.push({
            name: place.name,
            position: {
              lat: parseFloat(place.lat),
              lng: parseFloat(place.lng),
            },
          });
        } else {
          const location = await geocodePlace(place.name); // Fetch lat/lng dynamically
          if (location) {
            newMarkers.push({
              name: place.name,
              position: location,
            });
          }
        }
      }
    }
    setMarkers(newMarkers);
  }, [itinerary.byDay]);

  useEffect(() => {
    if (itinerary.byDay) {
      prepareMarkers();
    }
  }, [itinerary, prepareMarkers]);

  const fetchComments = async () => {
    if (!itinerary_id) return;

    setIsLoading(true);
    try {
      if (!userId) {
        console.error("User ID not found. Unable to fetch itinerary comments.");
        return;
      }

      if (itinerary_id) {
        const response = await axios.get(
          `${hostURL}/comments/${itinerary_id}?user_id=${userId}`,
          {}
        );

        const { data } = response;

        console.log("hey comments", data);

        setComments(data);
        setItems(data);
      }
    } catch (err) {
      console.log("Error fetching comments", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!itinerary_id) {
      return;
    }

    fetchItinerary();
    fetchComments();
  }, [itinerary_id]);

  //like itinerary

  const handleLike = useCallback(
    (x) => async () => {
      try {
        const dto = {
          user_id: userId,
          itinerary_id: x.id,
          itinerary_owner: x.user_id,
        };

        const likeResponse = await axios.post(`${hostURL}/like`, dto);

        if (likeResponse.status === 200) {
          fetchItinerary();
          setRerender((x) => !x);
        }
      } catch (err) {
        console.log(err);
      }
    },
    []
  );

  const handleUnlike = useCallback(
    (x) => async () => {
      try {
        const dto = {
          user_id: userId,
          itinerary_id: x.id,
          itinerary_owner: x.user_id,
        };

        const unlikeResponse = await axios.delete(
          `${hostURL}/unlike?user_id=${dto.user_id}&itinerary_id=${dto.itinerary_id}`,
          dto
        );

        if (unlikeResponse.status === 200) {
          fetchItinerary();
          setRerender((x) => !x);
        }
      } catch (err) {
        console.log(err);
      }
    },
    []
  );

  const handleClickPlaceName = (placeName) => {
    const marker = markers.find((marker) => marker.name === placeName);
    if (marker && mapRef.current) {
      mapRef.current.panTo(marker.position);
      mapRef.current.setZoom(14);
    }
  };

  // Route for liking a comment
  const handleLikeComment = useCallback(
    (x) => async () => {
      try {
        const dto = {
          user_id: userId,
          comment_id: x.id,
          comment_owner: x.comment_from,
          itinerary_id: itinerary.id,
        };

        const likeResponse = await axios.post(`${hostURL}/likecomment`, dto);

        if (likeResponse.status === 200) {
          fetchItinerary();
          fetchComments();
          setRerender((x) => !x);
        }
      } catch (err) {
        console.log(err);
      }
    },
    []
  );

  const handleUnlikeComment = useCallback(
    (x) => async () => {
      try {
        const unlikeResponse = await axios.delete(
          `${hostURL}/unlikecomment?user_id=${userId}&comment_id=${x.id}`,
          {}
        );

        if (unlikeResponse.status === 200) {
          fetchItinerary();
          fetchComments();
          setRerender((x) => !x);
        }
      } catch (err) {
        console.log(err);
      }
    },
    []
  );

  //bookmark itinerary

  const handleBookmark = useCallback(
    (x) => async () => {
      try {
        const dto = {
          user_id: userId,
          itinerary_id: x.id,
          itinerary_owner: x.user_id,
        };

        const likeResponse = await axios.post(`${hostURL}/bookmark`, dto);

        if (likeResponse.status === 200) {
          fetchItinerary();
          setRerender((x) => !x);
        }
      } catch (err) {
        console.log(err);
      }
    },
    []
  );

  const handleUnbookmark = useCallback(
    (x) => async () => {
      try {
        const dto = {
          user_id: userId,
          itinerary_id: x.id,
          itinerary_owner: x.user_id,
        };

        const unlikeResponse = await axios.delete(
          `${hostURL}/unbookmark?user_id=${dto.user_id}&itinerary_id=${dto.itinerary_id}`,
          dto
        );

        if (unlikeResponse.status === 200) {
          fetchItinerary();
          setRerender((x) => !x);
        }
      } catch (err) {
        console.log(err);
      }
    },
    []
  );

  const handleImageFallback = (e) => {
    e.target.onerror = null; // Prevent an infinite loop if the fallback image fails
    e.target.src = placeholderplace; // Set the fallback image source
  };

  const handleProfileFallback = (e) => {
    e.target.onerror = null; // Prevent an infinite loop if the fallback image fails
    e.target.src = profile; // Set the fallback image source
  };

  const scrollToSection = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const handleFollow2 = useCallback(
    (x) => async () => {
      try {
        const dto = {
          user_id: userId,
          following_id: itinerary.user_id,
        };

        const response = await axios.post(`${hostURL}/follow`, dto);

        if (response.status === 200) {
          window.location.reload();
        }
      } catch (err) {
        console.log(err);
      }
    },
    [itinerary.user_id, userId]
  );

  const handleUnfollow2 = useCallback(
    (x) => async () => {
      try {
        const dto = {
          user_id: userId,
          following_id: itinerary.user_id,
        };

        const unlikeResponse = await axios.delete(
          `${hostURL}/unfollow?user_id=${dto.user_id}&following_id=${dto.following_id}`,
          dto
        );

        if (unlikeResponse.status === 200) {
          window.location.reload();
        }
      } catch (err) {
        console.log(err);
      }
    },
    [itinerary.user_id, userId]
  );

  const [rerender, setRerender] = useState(false);

  const [selectedComment, setSelectedComment] = useState(null);

  const [comment, setComment] = useState("");

  const handleFormChange = (e) => {
    setComment(e.target.value); // Directly set the comment value from input
  };

  const handleComment = useCallback(
    (x) => async () => {
      try {
        const dto = {
          user_id: userId,
          itinerary_id: itinerary.id,
          itinerary_owner: itinerary.user_id,
          replied_id: !x?.comment_from ? null : x.comment_from,
          message: comment,
          commentId: x?.id || "",
        };

        console.log(dto);
        setIsLoading(true);
        const commentresponse = await axios.post(`${hostURL}/comment`, dto);

        if (commentresponse.status === 200) {
          fetchItinerary();
          fetchComments();
          setRerender((x) => !x);
          setComment("");
          setSelectedComment(null);
          if (comments.length === 0) window.location.reload();
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    },
    [comment, itinerary.id, itinerary.user_id, userId]
  );

  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    // Initially load the first batch of itineraries
    loadMoreItems();
  }, []);

  const loadMoreItems = () => {
    // Simulate an API request to load the next batch of itineraries
    setTimeout(() => {
      setItems((prevItems) => [
        ...prevItems,
        ...comments.slice(items.length, items.length + 5),
      ]);
      if (items.length >= comments.length) {
        setHasMore(false); // No more items to load
      }
    }, 1000);
  };

  const handleReply = (x) => () => {
    console.log("reply clicked");
    setSelectedComment(x);
    setComment(`@${x.comment_from_username} `);
  };

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const deleteModalRef = useRef(null);

  // Detect click outside the modal
  useEffect(() => {
    if (deleteModalRef) {
      const handleClickOutside = (event) => {
        if (
          deleteModalRef.current &&
          !deleteModalRef.current.contains(event.target)
        ) {
          setShowDeleteConfirmation(false); // Optionally close the modal when clicked outside
        }
      };

      // Add the event listener
      document.addEventListener("mousedown", handleClickOutside);

      // Clean up the event listener
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [showDeleteConfirmation]);

  // Delete Itinerary
  const handleDelete = useCallback(
    (x) => async () => {
      try {
        // Making a DELETE request to delete a comment
        setIsLoading(true);
        const response = await axios.delete(
          `${hostURL}/deletecomment?comment_id=${x.id}`,
          {}
        );

        if (response.status === 200) {
          fetchItinerary();
          fetchComments();
          setRerender((x) => !x); // Refresh the itineraries
          setShowDeleteConfirmation(false);
        } else {
          setShowDeleteConfirmation(false);
          console.log("unsuccessful");
        }
      } catch (error) {
        setShowDeleteConfirmation(false);
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  if (
    !itinerary.id ||
    itinerary.id == null ||
    !itinerary.user_id ||
    itinerary.user_id === -1
  ) {
    return (
      <div className="viewItineraryContainer">
        <div
          className="controw"
          onClick={() => {
            window.history.back();
          }}
          style={{ cursor: "pointer" }}
        >
          <RiArrowGoBackLine color="gray" size={35} />
          <div>Go Back</div>
        </div>
        <div className="viewitinerarybodyerror">
          <LazyLoadImage src={wrong} alt="place" className="viewerrorimage" />
        </div>
      </div>
    );
  }

  // if (itinerary.user_id === -1 || !itinerary.user_id) {
  //   return (
  //     <div className="viewItineraryContainer">
  //       <div
  //         className="controw"
  //         onClick={() => {
  //           window.history.back();
  //         }}
  //         style={{ cursor: "pointer" }}
  //       >
  //         <RiArrowGoBackLine color="gray" size={35} />
  //         <div>Go Back</div>
  //       </div>
  //       <div className="viewitinerarybody">Failed to Load</div>
  //     </div>
  //   );
  // }

  if (itinerary.isPrivate && userId != itinerary.user_id)
    return (
      <div className="viewItineraryContainer">
        <div
          className="controw"
          onClick={() => {
            window.history.back();
          }}
          style={{ cursor: "pointer" }}
        >
          <RiArrowGoBackLine color="gray" size={35} />
          <div>Go Back</div>
        </div>
        <div className="viewitinerarybodyerror">
          <LazyLoadImage
            src={notpublic}
            alt="place"
            className="viewerrorimage"
          />
        </div>
      </div>
    );

  return (
    <div className="viewItineraryContainer">
      {isLoading ? (
        <div className="loading-overlay">
          <div className="loading-content">
            <p>Loading...</p>
            <div className="spinner"></div>
          </div>
        </div>
      ) : null}
      {showDeleteConfirmation ? (
        <div className="loading-overlay">
          <div
            key={selectedComment.id}
            className={`modalcontainer ${showDeleteConfirmation ? "active" : ""
              }`}
          >
            <div ref={deleteModalRef} className="modalbox">
              <div className="modalheader">
                <h1>Delete</h1>
              </div>

              <div className="modalcontent">
                <p>Are you sure you want to delete this comment?</p>
              </div>

              <div className="modalbottom">
                <input
                  type="button"
                  value="NO"
                  onClick={() => {
                    setShowDeleteConfirmation(false);
                  }}
                  className="modalButtonGray"
                />
                <input
                  key={selectedComment.id}
                  type="button"
                  value="YES"
                  onClick={handleDelete(selectedComment)}
                  className="modalButtonRed"
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <div
        className="controw"
        onClick={() => {
          window.history.back();
        }}
        style={{ cursor: "pointer" }}
      >
        <RiArrowGoBackLine color="gray" size={35} />
        <div>Go Back</div>
      </div>
      <div className="viewitinerarybody">
        <div className="viewitineraryLeft">
          <LazyLoadImage
            src={`${hostURL}/${itinerary.photo}`}
            alt="place"
            className="viewheaderimage"
            onError={handleImageFallback}
          />
          <div className="controwspacebetween">
            <div className="contrownarrow">
              <LazyLoadImage
                src={itinerary.user_pic}
                alt="place"
                className="viewpic"
                onClick={() => {
                  window.location.href = `/userprofile/${itinerary.user_id}`;
                }}
                style={{
                  cursor: "pointer",
                }}
                onError={handleProfileFallback}
              />
              <div className="contcol">
                <div
                  onClick={() => {
                    window.location.href = `/userprofile/${itinerary.user_id}`;
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {`${itinerary.user_name}`}
                  <span className="username"> @{itinerary.username}</span>
                </div>
                <div>{`${dayjs(itinerary.created_at).format(
                  "MMM DD,YYYY"
                )}`}</div>
              </div>
            </div>
            <div className="viewbuttoncontainer">
              {userId == itinerary.user_id ? null : (
                <input
                  type="button"
                  className="viewfollow"
                  value={itinerary.followed ? "UNFOLLOW" : "FOLLOW"}
                  onClick={
                    itinerary.followed
                      ? handleUnfollow2(itinerary)
                      : handleFollow2(itinerary)
                  }
                />
              )}

              <FaBookmark
                key={rerender}
                size={25}
                color="lightgray"
                className={`feedBookmarked ${itinerary.bookmarked ? "bookmarked" : "notbookmarked"
                  }`}
                onClick={
                  itinerary.bookmarked
                    ? handleUnbookmark(itinerary)
                    : handleBookmark(itinerary)
                }
              />

              <div className="contrownarrow">
                <FaHeart
                  key={rerender}
                  size={25}
                  className={`feedHeart ${itinerary.liked ? "hearted" : "nothearted"
                    }`}
                  onClick={
                    itinerary.liked
                      ? handleUnlike(itinerary)
                      : handleLike(itinerary)
                  }
                />
                <div>{itinerary.likes_count || 0}</div>
              </div>

              <div className="contrownarrow">
                <FaComments
                  size={25}
                  className="feedComment"
                  onClick={scrollToSection}
                />
                <div>{itinerary.comments_count || 0}</div>
              </div>
            </div>
          </div>

          <h1 className="viewheading bigtitle">{itinerary.trip_name}</h1>
          <div className="viewcardscontainer">
            <div className="contcol">
              <div className="viewnormaltext">{`${dayjs(
                itinerary.start_date
              ).format("MMM DD,YYYY")} - ${dayjs(itinerary.end_date).format(
                "MMM DD,YYYY"
              )}`}</div>

              <div className="viewgraytext">{itinerary.tripdescription}</div>
            </div>

            {itinerary?.byDay?.map((x, i) => {
              return (
                <>
                  <h1 className="viewheading">
                    {`${x?.date} - ${dayjs(itinerary?.start_date)
                      .add(i, "day")
                      .format("dddd, MMM  DD") || ""
                      }`}
                  </h1>
                  {x?.places?.map((y, i) => {
                    return (
                      <div key={i} className="viewitinerarycard">
                        <div className="decorativepin">
                          <FaLocationPin className="pin" color="darkblue" />
                          <div className="pinnumber">{i + 1}</div>
                        </div>
                        <div className="viewitinerarycardcontent">
                          <div className="contcol2">
                            <div
                              className="viewnormaltext viewtitle hoverableviewtext"
                              onClick={() => handleClickPlaceName(y.name)}
                              style={{ cursor: "pointer" }}
                            >
                              {y.name}
                            </div>
                            <div className="viewgraytext">
                              {y.description === "" || !y.description
                                ? "No Description"
                                : y.description}
                            </div>
                            <div className="viewbuttoncontainer2">
                              <div className="viewsmalltext">
                                {y.time === "" || !y.time ? "" : y.time}
                              </div>

                              <div className="viewsmalltext">
                                {y.cost === "" || !y.cost ? "" : y.cost}
                              </div>
                            </div>
                          </div>
                        </div>
                        <LazyLoadImage
                          src={`${hostURL}/${y.photo}`} // Fallback image if no photo is available}
                          alt="place"
                          className="viewcardimage"
                          onError={handleImageFallback}
                        />
                      </div>
                    );
                  })}
                </>
              );
            })}
          </div>
          <div
            className="commentscontainer"
            id="COMMENTS"
            ref={sectionRef}
            key={itinerary.comments_count}
          >
            <div className="viewheading">
              Comments: {itinerary.comments_count}
            </div>
            <div className="commentrow">
              <LazyLoadImage
                src={profileCard}
                alt="place"
                className="viewpic"
                onError={handleProfileFallback}
              />
              <div className="commentform">
                <textarea
                  type="textarea"
                  placeholder="Ask anything!"
                  className="commentinput"
                  name="comment"
                  maxLength={300}
                  value={comment}
                  onChange={handleFormChange}
                />

                <input
                  type="button"
                  placeholder="Ask anything!"
                  className="commentpostbutton"
                  value="POST"
                  onClick={handleComment(selectedComment)}
                />
              </div>
            </div>

            <InfiniteScroll
              key={rerender + itinerary.comments_count}
              dataLength={items.length} // Length of the items array
              next={loadMoreItems} // Function to load more items
              hasMore={hasMore} // Whether there are more items to load
              loader={<h4>Loading...</h4>}
              endMessage={
                <p style={{ marginTop: "1.5rem" }}>No more comments to show</p>
              }
            >
              {comments?.map((x, i) => {
                return (
                  <div className="commentrow" key={i} id={`comment-${x.id}`}>
                    <LazyLoadImage
                      src={x.comment_from_pic}
                      alt="place"
                      className="viewpic"
                      onError={handleProfileFallback}
                      onClick={() => {
                        window.location.href = `/userprofile/${x.user_id}`;
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                    />
                    <div className="contcol">
                      <div className="contrownarrow">
                        <div className="contcolcommm">
                          <div className="contrownarrow">
                            <div
                              className="viewnormaltext"
                              onClick={() => {
                                window.location.href = `/userprofile/${x.user_id}`;
                              }}
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              {`${x.comment_from_name} `}{" "}
                              <span className="username">{`@${x.comment_from_username}`}</span>
                            </div>
                          </div>

                          <div className="viewgraytext">{x.comment}</div>
                        </div>
                      </div>

                      <div className="controwspacebetween">
                        <div className="viewbuttoncontainer2">
                          <div className="contrownarrower">
                            <FaHeart
                              key={rerender}
                              size={15}
                              className={`feedHeart ${x.liked ? "hearted" : "nothearted"
                                }`}
                              onClick={
                                x.liked
                                  ? handleUnlikeComment(x)
                                  : handleLikeComment(x)
                              }
                            />
                            <div>{x.likes_count || 0}</div>
                          </div>
                          <div
                            onClick={handleReply(x)}
                            style={{ cursor: "pointer" }}
                          >
                            REPLY
                          </div>

                          <div className="username">{x.time_ago}</div>
                        </div>
                        {x.comment_from == userId ||
                          itinerary.user_id == userId ? (
                          <FaTrash
                            size={15}
                            className={`feedHeart ${itinerary.liked ? "hearted" : "nothearted"
                              }`}
                            onClick={() => {
                              setSelectedComment(x);
                              setShowDeleteConfirmation(true);
                            }}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                );
              })}
            </InfiniteScroll>
          </div>
        </div>
        <div className="stickymapcontainer">
          <div className="viewitinerarymap">
            <LoadScript googleMapsApiKey={googleMapsApiKey}>
              <GoogleMap
                mapContainerStyle={{
                  width: "100%",
                  height: "100%",
                }}
                center={
                  markers?.length ? markers[0]?.position : { lat: 0, lng: 0 }
                }
                zoom={markers?.length ? 10 : 2}
                onLoad={(map) => {
                  mapRef.current = map;
                  if (
                    window.google &&
                    window.google.maps &&
                    markers.length > 0
                  ) {
                    const bounds = new window.google.maps.LatLngBounds();
                    markers.forEach((marker) => bounds.extend(marker.position));
                    map.fitBounds(bounds);
                  }
                }}
              >
                {markers?.map((marker, idx) => (
                  <Marker
                    key={idx}
                    position={marker.position}
                    title={marker.name}
                    icon={{
                      url: customMarkerIcon, // Path to your custom marker icon
                      scaledSize: new window.google.maps.Size(40, 60), // Adjust size as needed
                    }}
                  />
                ))}
              </GoogleMap>
            </LoadScript>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewItinerary;
