// function Validation(values) {
//     let error = {};
//     const email_pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     const password_pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;

//     if (values.firstname === "") {
//         error.firstname = "First name should not be empty";
//     } else {
//         error.firstname = "";
//     }

//     if (values.lastname === "") {
//         error.lastname = "Last name should not be empty";
//     } else {
//         error.lastname = "";
//     }

//     if (values.username === "") {
//         error.username = "Username should not be empty";
//     } else {
//         error.username = "";
//     }

//     if (values.email === "") {
//         error.email = "Email should not be empty";
//     } else if (!email_pattern.test(values.email)) {
//         error.email = "Email is invalid";
//     } else {
//         error.email = "";
//     }

//     if (values.password === "") {
//         error.password = "Password should not be empty";
//     } else if (!password_pattern.test(values.password)) {
//         error.password = "Password must contain at least one digit, lowercase, uppercase letter, and min. of 8 characters";
//     } else {
//         error.password = "";
//     }

//     if (values.cpassword === "") {
//         error.cpassword = "Confirm password should not be empty";
//     } else if (values.cpassword !== values.password) {
//         error.cpassword = "Passwords do not match";
//         //error.confirmpassword = values.confirmpassword + values.password ;
//     } else {
//         error.cpassword = "";
//     }

//     return error;
// }

// export default Validation;

function Validation(values) {
  let error = {};
  const email_pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Email should not have whitespace (already handled)
  const password_pattern =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])(?!.*\s)[A-Za-z\d!@#$%^&*]{8,20}$/; // Password must be 8-20 characters long and cannot contain whitespace
  const name_pattern = /^[A-Za-z]{2,20}$/; // Regex for name validation (no whitespace)
  const username_pattern = /^(?!.*\s)[A-Za-z0-9]{7,20}$/; // Regex for username validation (no whitespace, only letters and numbers)

  // First Name Validation
  if (!values.firstName.trim()) {
    error.firstName = "First name should not be empty";
  } else if (!name_pattern.test(values.firstName)) {
    error.firstName =
      "First name must contain upto 20 characters, minimum of two letters and not contain spaces.";
  } else {
    error.firstName = "";
  }

  // Last Name Validation
  if (!values.lastName.trim()) {
    error.lastName = "Last name should not be empty";
  } else if (!name_pattern.test(values.lastName)) {
    error.lastName =
      "Last name must contain upto 20 characters, minimum of two letters and not contain spaces. (i.e. So, SanJose, etc.)";
  } else {
    error.lastName = "";
  }

  // Username Validation
  if (!values.username.trim()) {
    error.username = "Username should not be empty";
  } else if (!username_pattern.test(values.username)) {
    error.username =
      "Username must be 7-20 characters long, contain only letters or numbers, and cannot have white spaces";
  } else {
    error.username = "";
  }

  // Email Validation
  if (!values.email.trim()) {
    error.email = "Email should not be empty";
  } else if (!email_pattern.test(values.email)) {
    error.email = "Email is invalid and cannot contain white spaces";
  } else {
    error.email = "";
  }

  // Password Validation
  if (!values.password) {
    error.password = "Password should not be empty";
  } else if (!password_pattern.test(values.password)) {
    error.password =
      "Password must be 8-20 characters long, contain at least one digit, one uppercase letter, one lowercase letter, one special character, and cannot contain white spaces.";
  } else {
    error.password = "";
  }

  // Confirm Password Validation
  if (!values.cpassword) {
    error.cpassword = "Confirm password should not be empty";
  } else if (values.cpassword !== values.password) {
    error.cpassword = "Passwords do not match";
  } else {
    error.cpassword = "";
  }

  return error;
}

export default Validation;
