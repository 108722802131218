import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Validation from "./LoginValidation";
import cabin from "../homeassets/loginbg.png";
import mascot from "../homeassets/loginbgbear.png";
import mascotweb from "../homeassets/bearweb.png";
import { toast, ToastContainer } from "react-toastify";
import { useParams } from "react-router-dom";
import emailjs from "emailjs-com";
import hostURL from "./config";
import profile from "./assets/profile.png";
const UserForm = ({ setIsLoggedIn }) => {
  const { token } = useParams();
  // Accept setIsLoggedIn as a prop

  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [isShowForgotPassword, setShowForgotPassword] = useState(false);
  const [emailValue, setEmailValue] = useState("");
  const navigate = useNavigate(); // Hook for navigation

  const handleInput = (event) => {
    setValues((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };
  const TOAST_STYLE = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  };
  const handleForgotPassword = async () => {
    try {
      if (!emailValue) {
        toast.error("Please Provide an Email", TOAST_STYLE);
      } else {
        // Generate the reset link (this assumes the backend or frontend generates it)
        axios
          .post(`${hostURL}/forgot-password`, { email: emailValue })
          .then(async (res) => {
            console.log(res);
            if (res.status === 200) {
              console.log("hey", res);
              if (res.data.data) {
                const resetLink = `${window.location.origin}/reset-password/${res.data.data.params}`;

                // EmailJS configuration for the forgot password email
                const emailData = {
                  to_email: emailValue, // The recipient's email
                  link: resetLink, // The reset link
                  name: res.data.data.name || "Tripmatic User", // Optional: Replace with the user's name if available
                };

                // Replace with your EmailJS details (Service ID, Template ID, Public Key)
                const SERVICE_ID = "service_4yyms3q"; // Replace with your EmailJS Service ID
                const TEMPLATE_ID = "template_bkkcvol"; // Replace with your EmailJS Forgot Password Template ID
                const PUBLIC_KEY = "MpfQcOYYKfrA81lNZ"; // Replace with your EmailJS Public Key

                await emailjs
                  .send(SERVICE_ID, TEMPLATE_ID, emailData, PUBLIC_KEY)
                  .then(() => {
                    toast.success(
                      "Password reset email sent! Please check your inbox.",
                      TOAST_STYLE
                    );
                  })
                  .catch(() => {
                    toast.error("Could not send the email.", TOAST_STYLE);
                  });
              } else {
                toast.error("Could not process the request.", TOAST_STYLE);
              }
            } else {
              toast.error("Invalid Email.", TOAST_STYLE);
            }
          });
      }
    } catch (error) {
      console.error("Error sending forgot password email:", error);
      toast.error(
        "Failed to send password reset email. Please try again later.",
        TOAST_STYLE
      );
    } finally {
      setShowForgotPassword(false);
    }
  };

  const handleCloseModal = () => {
    setEmailValue("");
    setShowForgotPassword(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    toast.dismiss();
    // setErrors(Validation(values));

    console.log(hostURL);
    try {
      // Ensure the errors are empty before making the login request
      if (!errors.email && !errors.password) {
        axios
          .post(`${hostURL}/login`, values)
          .then((res) => {
            console.log(res);
            if (res.status === 200) {
              if (res.data.token) {
                // Save the token to sessionStorage to maintain login state

                console.log(res.data);
                localStorage.setItem("userId", res.data.user_id);
                localStorage.setItem("userToken", res.data.token); // Replace 'your-auth-token' with actual token
                localStorage.setItem("isLoggedIn", true);
                localStorage.setItem(
                  "profileCard",
                  res.data.image_base64 || profile
                );
                // Update the login state and set it in sessionStorage
                setIsLoggedIn(true);

                // Redirect to a different page
                window.location.href = "/feed";
              } else {
                toast.error(res.data.message, TOAST_STYLE);
                // setErrors({
                //   password: res.data.message,
                // });
              }
            } else if (res.status === 201) {
              toast.error(res.data.message, TOAST_STYLE);
            } else {
              toast.error("Invalid email or password", TOAST_STYLE);
              setErrors({
                email: "",
                password: "",
              });
            }
          })
          .catch((err) => console.log(err))
          .finally(() => {
            setTimeout(() => {
              setErrors({
                email: "",
                password: "",
              });
            }, 3000);
          });
      }
    } catch (error) {
      toast.error("Something went wrong", TOAST_STYLE);
      //alert(error);
    }
  };

  useEffect(() => {
    if (token) {
      const sendData = { token: token };
      axios
        .post(`${hostURL}/verify-email`, sendData)
        .then((res) => {
          if (res.status === 200) {
            toast.success("Account Verified, please login.", TOAST_STYLE);
            //alert("Account Verified");
          }
        })
        .catch((err) => {
          toast.error(
            "Something went wrong with the verification",
            TOAST_STYLE
          );
          console.log(err);
        });
    }
  }, [token]);

  return (
    <div className="loginSuccess">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
      />

      <div className="login">
        <img src={cabin} alt="logo" className="formbglogreg" />

        <img src={mascotweb} alt="logo" className="formbglogreg" />

        <div className="loginLogo">
          <img src={mascot} alt="logo" className="hiddenmascot" />
        </div>
        <form action="" className="loginForm" onSubmit={handleSubmit}>
          <div>
            <p className="welcomeTxt">Hi! Welcome,</p>
            <p className="createTxt">create your plan today.</p>
          </div>
          <div>
            <input
              type="email"
              placeholder="Email Address"
              name="email"
              onChange={handleInput}
              className="inputForms"
            />
          </div>
          <div>
            {errors.email && (
              <span className="errorInput"> {errors.email}</span>
            )}
          </div>
          <div>
            <input
              type="password"
              placeholder="Password"
              name="password"
              onChange={handleInput}
              className="inputForms"
            />
          </div>
          <div>
            {errors.password && (
              <span className="errorInput"> {errors.password}</span>
            )}
          </div>
          <div>
            <a
              href="#"
              className="forgotPasstxt"
              onClick={() => setShowForgotPassword(true)}
            >
              Forgot Password?
            </a>
          </div>
          <div>
            <button type="submit" className="buttonLogin">
              Login
            </button>
          </div>
          <div
            style={{
              marginTop: "5px",
              display: "flex",
              gap: "1rem",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
            className="bottomQuestion"
          >
            <p>Don't have an account?</p>
            <Link to="/Register" className="registerAccnt">
              Register an account
            </Link>
          </div>
        </form>
      </div>

      {/*Reset Password Modal v2*/}
      {isShowForgotPassword && (
        <div className="modal-login">
          <div className="modal-dialog-login">
            <div className="modal-content-login">
              <div className="modal-header-login">
                <h2 className="modal-header-h2-login">Reset Password</h2>
                <span
                  className="btn-close-login"
                  onClick={() => handleCloseModal()}
                >
                  &times;
                </span>
              </div>
              <form>
                <div className="form-header-login">
                  <label
                    htmlFor="pass"
                    style={{
                      display: "block",
                      textAlign: "left",
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#36454F",
                      marginTop: "20px",
                    }}
                  >
                    Email
                  </label>
                </div>

                <div className="form-group-login">
                  <input
                    className="form-group-input-login"
                    type="text"
                    id="email"
                    value={emailValue}
                    placeholder="Email"
                    onChange={(e) => {
                      setEmailValue(e.target.value);
                    }}
                  />
                  <p></p>
                  <button
                    style={{ textAlign: "center" }}
                    className="submit-btn-login"
                    onClick={() => handleForgotPassword()}
                  >
                    Reset Password
                  </button>
                </div>
                <div
                  style={{
                    borderBottom: "1px solid #ccc",
                    margin: "33px",
                    width: "85%",
                  }}
                ></div>
                <div className="modal-footer-login">
                  <h6 className="reset-instruction-login">
                    <small>
                      Forgot your password? No problem! Enter your registered
                      email, and we’ll send you instructions to securely reset
                      your password.
                    </small>
                  </h6>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserForm;
