import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Validation from "./RegisterValidation";
import axios from "axios";
import bcrypt from "bcryptjs";
import cabin from "../homeassets/loginbg.png";
import mascot from "../homeassets/loginbgbear.png";
import mascotweb from "../homeassets/bearweb.png";
import { toast, ToastContainer } from "react-toastify";
import emailjs from "emailjs-com";
import emailjsConfig from "../emailjs.config";

import "../modal.css";
import hostURL from "./config";
function Register() {
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    password: "",
    cpassword: "",
  });
  const navigate = useNavigate();
  const [onLoad, setOnLoad] = useState(false);
  const [errors, setErrors] = useState({});
  const TOAST_STYLE = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  };
  const handleInput = (event) => {
    setValues((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };
  const location = useLocation();
  useEffect(() => {
    if (location.state?.toastMessage) {
      toast.success(location.state.toastMessage, TOAST_STYLE);
    }
  }, [location]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setOnLoad(true);
    const validationErrors = Validation(values);
    let isSuccess = false;

    setLoading(true);
    try {
      if (
        Object.values(validationErrors).every((error) => error === "") &&
        values.password === values.cpassword
      ) {
        console.log("Encrypting password...");

        // Hash the password
        const hashedPassword = await bcrypt.hash(values.password, 10);

        // Generate verification token and link
        const token = Math.random().toString(36).substr(2); // Simple token generation (can be improved)
        const verificationLink = `${window.location.origin}/login/${token}`;

        // Prepare email data for EmailJS
        const emailData = {
          name: `${values.firstName} ${values.lastName}`,
          toEmail: values.email,
          link: verificationLink,
        };

        // Prepare the data to send to the backend
        const payload = {
          ...values,
          password: hashedPassword,
          verify_token: token, // Add token to payload for backend storage
          cpassword: undefined, // Optional: Exclude cpassword
        };

        console.log("Sending request to server:", payload);

        // Send verification email using EmailJS
        await emailjs.send(
          emailjsConfig.serviceId,
          emailjsConfig.templateId,
          emailData,
          emailjsConfig.publicKey
        );

        // Send user registration data to the backend
        axios
          .post(`${hostURL}/register`, payload)
          .then(async (res) => {
            await emailjs.send(
              emailjsConfig.serviceId,
              emailjsConfig.templateId,
              emailData,
              emailjsConfig.publicKey
            );
            if (res.status === 200) {
              isSuccess = true;
              toast.success(
                `Email verification sent. Please wait 5-10 minutes, thank you!`,
                TOAST_STYLE
              );
              setLoading(false);

              setTimeout(() => {
                navigate("/login");
              }, 5000);
            } else {
              toast.error(res.data.message, TOAST_STYLE);
            }
          })
          .catch((err) => console.error("Registration error:", err));
      } else {
        setErrors(validationErrors);
      }
    } catch (error) {
      setLoading(false);
      setErrors({});
      console.log(error);
    } finally {
      setLoading(false);
      setOnLoad(false);
      setTimeout(() => {
        if (isSuccess) {
          toast.success(
            "Registered Successfully. Check your email to verify your account.",
            TOAST_STYLE
          );
        }
      }, 10000);
    }
  };

  const [showUserAgreement, setShowUserAgreement] = useState(false);

  const [showPrivacy, setShowPrivacyAgreement] = useState(false);

  const [loading, setLoading] = useState(false);

  return (
    <div className="loginSuccess">
      {loading ? (
        <div className="loading-overlay">
          <div className="loading-content">
            <p>Loading...</p>
            <div className="spinner"></div>
          </div>
        </div>
      ) : null}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="login">
        <div className={`modalcontainer ${showUserAgreement ? "active" : ""}`}>
          <div className="modalbox">
            <div className="modalheader">
              <h1>User Agreement</h1>
            </div>

            <div className="modalcontent">
              <p>
                Welcome to Tripmatic, an online web and mobile-responsive
                application designed to help users create customized travel
                itineraries, generate AI-powered itineraries, and engage with a
                vibrant community to share travel plans and ideas. By accessing
                or using Tripmatic, you agree to adhere to the terms and
                conditions outlined in this User Agreement. These terms are
                designed to ensure a safe, lawful, and enjoyable experience for
                all users. If you do not agree with any part of this agreement,
                you are not authorized to access or use the platform. Tripmatic
                reserves the right to modify this agreement at any time, and it
                is your responsibility to review the terms periodically for
                updates. Continued use of the platform constitutes acceptance of
                any changes.
              </p>
              <p>
                As a Tripmatic user, you must ensure that your use of the
                platform complies with all applicable laws and regulations. You
                are required to create a secure account and safeguard your login
                credentials. Any activity conducted under your account is your
                responsibility, and you agree to notify us immediately in case
                of unauthorized access or suspicious activities. The platform
                prohibits actions such as posting illegal, harmful, or
                defamatory content, attempting to disrupt the platform’s
                functionality, or engaging in fraudulent behavior. Violations of
                these rules may result in the suspension or termination of your
                account, as well as possible legal action.
              </p>
              <p>
                Tripmatic is committed to maintaining the integrity of its
                platform and providing a collaborative environment for
                travelers. By sharing itineraries, posting comments, or
                interacting with other users, you grant Tripmatic a
                non-exclusive, royalty-free, and worldwide license to display
                and distribute the content you share on the community page. You
                retain ownership of your content, but you are responsible for
                ensuring that you have the necessary rights to share it.
                Tripmatic is not liable for any damages resulting from your
                interactions with other users or third-party services linked to
                the platform. Your use of Tripmatic signifies your acceptance of
                this User Agreement and your commitment to using the platform
                responsibly.
              </p>
            </div>

            <div className="modalbottom">
              <input
                type="button"
                value="DONE"
                onClick={() => {
                  setShowUserAgreement(false);
                }}
                className="modalButton"
              />
            </div>
          </div>
        </div>

        <div className={`modalcontainer ${showPrivacy ? "active" : ""}`}>
          <div className="modalbox">
            <div className="modalheader">
              <h1>Privacy Statement</h1>
            </div>

            <div className="modalcontent">
              <p>
                At Tripmatic, we take your privacy seriously and are dedicated
                to protecting the personal information you share with us. This
                Privacy Statement explains how we collect, use, and protect your
                data while using our platform. When you create an account or use
                our services, we may collect personal information such as your
                name, email address, and location. Additionally, when using
                specific features like itinerary creation, we may store trip
                details and preferences to enhance your experience. Automated
                tools, such as cookies, may also collect technical data to
                improve our app's functionality and usability. By using
                Tripmatic, you consent to the collection and use of this data as
                outlined in this policy.
              </p>
              <p>
                Your information is primarily used to provide the services you
                request, including generating personalized itineraries and
                facilitating community interactions. We may also use your data
                to improve our features, conduct analytics, and communicate with
                you about updates, promotions, or relevant travel content. Rest
                assured, Tripmatic does not sell or share your personal
                information with third parties without your explicit consent,
                except when required by law or to comply with legal processes.
                We may share non-personal, aggregated data with trusted partners
                to enhance the overall user experience without compromising
                individual privacy.
              </p>
              <p>
                To protect your data, Tripmatic employs industry-standard
                security measures such as encryption, secure servers, and
                regular system updates. While we strive to keep your information
                safe, no system can guarantee complete security. We encourage
                users to take additional precautions, such as using strong
                passwords and monitoring account activity. You have the right to
                access, modify, or delete your personal information at any time
                by contacting us at tripmatic.app@gmail.com. By using Tripmatic,
                you agree to the practices outlined in this Privacy Statement
                and acknowledge our commitment to safeguarding your data. For
                further details, please reach out to us with any questions or
                concerns.
              </p>
            </div>

            <div className="modalbottom">
              <input
                type="button"
                value="DONE"
                onClick={() => {
                  setShowPrivacyAgreement(false);
                }}
                className="modalButton"
              />
            </div>
          </div>
        </div>
        <img src={cabin} alt="logo" className="formbglogreg" />

        <img src={mascotweb} alt="logo" className="formbglogreg" />

        <div className="registerLogo">
          <img src={mascot} alt="logo" className="hiddenmascot" />
        </div>
        <form className="registerForm" onSubmit={handleSubmit}>
          <div>
            <p className="welcomeTxt">Hi! Welcome,</p>
          </div>
          <div>
            <input
              type="text"
              placeholder="First Name"
              name="firstName"
              onChange={handleInput}
              className="inputForms"
              required
            />
            {errors.firstName && (
              <span className="errorInput"> {errors.firstName}</span>
            )}
          </div>
          <div>
            <input
              type="text"
              placeholder="Last Name"
              name="lastName"
              onChange={handleInput}
              className="inputForms"
              required
            />
            {errors.lastName && (
              <span className="errorInput"> {errors.lastName}</span>
            )}
          </div>
          <div>
            <input
              type="text"
              placeholder="Username"
              name="username"
              onChange={handleInput}
              className="inputForms"
              required
            />
            {errors.username && (
              <span className="errorInput"> {errors.username}</span>
            )}
          </div>
          <div>
            <input
              type="email"
              placeholder="Email Address"
              name="email"
              onChange={handleInput}
              className="inputForms"
              required
            />
            {errors.email && (
              <span className="errorInput"> {errors.email}</span>
            )}
          </div>
          <div>
            <input
              type="password"
              placeholder="Password"
              name="password"
              onChange={handleInput}
              className="inputForms"
              required
            />
            {errors.password && (
              <span className="errorInput"> {errors.password}</span>
            )}
          </div>
          <div>
            <input
              type="password"
              placeholder="Confirm Password"
              name="cpassword"
              onChange={handleInput}
              className="inputForms"
              required
            />
            {errors.cpassword && (
              <span className="errorInput"> {errors.cpassword}</span>
            )}
          </div>

          <div className="checkBox">
            <input type="checkbox" id="usr" required />
            <label htmlFor="usr" className="checkBoxLabel">
              I accept the Tripmatic{" "}
              <span
                onClick={() => {
                  setShowUserAgreement(true);
                  setShowPrivacyAgreement(false);
                }}
                className="button-link"
              >
                User Agreement{" "}
              </span>{" "}
              and have read the{" "}
              <span
                onClick={() => {
                  setShowUserAgreement(false);
                  setShowPrivacyAgreement(true);
                }}
                className="button-link"
              >
                Privacy Statement
              </span>
              .
            </label>
          </div>
          <div>
            <button
              type="submit"
              className="buttonRegister"
              value="Register Account"
            >
              Register Account
            </button>
          </div>
          <div
            style={{
              marginTop: "5px",
              display: "flex",
              gap: "1rem",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
            className="bottomQuestion"
          >
            <p>Already have an account?</p>
            <Link to="/login" className="loginAccnt">
              Login
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Register;
