import React, { useCallback, useEffect, useState } from "react";
import "../feed.css";
import { FaHeart } from "react-icons/fa";
import { FaComments } from "react-icons/fa";

import axios from "axios";

import { LazyLoadImage } from "react-lazy-load-image-component";

import InfiniteScroll from "react-infinite-scroll-component";
import hostURL from "./config";
import emptyGeneral from "./assets/emptyfeed.png";

import emptyFollowing from "./assets/emptyfollowing.png";
import placeholderplace from "./assets/placeholderplace.png";
import profile from "./assets/profile.png";

function Feed() {
  const navLabels = ["Famous Itineraries", "People you follow"];
  const [selectedNav, setSelectedNav] = useState("Famous Itineraries");

  const [itineraries, setItineraries] = useState([]);

  const userId = localStorage.getItem("userId");

  const [loading, setLoading] = useState(false);

  const fetchItineraries = async () => {
    console.log("Fetching itineraries...");

    if (!userId) {
      console.error("No Id found. User not authenticated.");
      return;
    }

    try {
      setLoading(true);
      if (!userId) {
        setItineraries([]);
        console.error("User ID not found. Unable to fetch itineraries.");
        return;
      }

      // Fetch itineraries for the user
      const response = await fetch(`${hostURL}/itineraries/${userId}`); // Replace with your API URL
      if (!response.ok) {
        setItineraries([]);
        throw new Error("Error fetching itineraries");
      }

      const data = await response.json();

      // Check if there are itineraries
      if (data && data.length > 0) {
        setItineraries(data); // Store itineraries in the state
      } else {
        setLoading(false);
        console.log("No itineraries found.");
        setItineraries([]); // Handle empty data if needed
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching itineraries:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fetch itineraries when the component mounts

    fetchItineraries();
  }, []);

  console.log(itineraries);

  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    // Initially load the first batch of itineraries
    loadMoreItems();
  }, []);

  const loadMoreItems = () => {
    // Simulate an API request to load the next batch of itineraries
    setTimeout(() => {
      setItems((prevItems) => [
        ...prevItems,
        ...itineraries.slice(items.length, items.length + 5),
      ]);
      if (items.length >= itineraries.length) {
        setHasMore(false); // No more items to load
      }
    }, 2000);
  };

  const handleLike = useCallback(
    (x) => async () => {
      try {
        const token = localStorage.getItem("userToken");
        if (!token) {
          alert("User not authenticated. Please log in.");
          return;
        }

        setLoading(true);

        const userResponse = await axios.get(`${hostURL}/user`, {
          headers: {
            Authorization: token,
          },
        });

        const userId = userResponse.data.id;

        const dto = {
          user_id: userId,
          itinerary_id: x.id,
          itinerary_owner: x.user_id,
        };

        console.log(dto);

        const likeResponse = await axios.post(`${hostURL}/like`, dto);

        console.log("likeit");
        console.log(likeResponse);

        if (likeResponse.status === 200) {
          setItineraries((prevList) => {
            let temp = [...prevList];

            // Find the itinerary by id and update its `like` property
            const changedRowIndex = temp.findIndex((y) => y.id === x.id);
            if (changedRowIndex !== -1) {
              temp[changedRowIndex] = {
                ...temp[changedRowIndex],
                liked: true,
                numberOfLikes: temp[changedRowIndex].numberOfLikes + 1,
              };
            }

            return temp;
          });
          setRerender((x) => !x);
          fetchItineraries();
        }
      } catch (err) {
        setLoading(false);
        console.log(err);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const handleUnlike = useCallback(
    (x) => async () => {
      try {
        const token = localStorage.getItem("userToken");
        if (!token) {
          console.log("User not authenticated. Please log in.");
          return;
        }

        setLoading(true);

        const userResponse = await axios.get(`${hostURL}/user`, {
          headers: {
            Authorization: token,
          },
        });

        const userId = userResponse.data.id;

        const dto = {
          user_id: userId,
          itinerary_id: x.id,
        };

        console.log(dto);

        const unlikeResponse = await axios.delete(
          `${hostURL}/unlike?user_id=${dto.user_id}&itinerary_id=${dto.itinerary_id}`,
          dto
        );

        console.log("unlikeit");
        console.log(unlikeResponse);

        if (unlikeResponse.status === 200) {
          setItineraries((prevList) => {
            let temp = [...prevList];

            // Find the itinerary by id and update its `like` property
            const changedRowIndex = temp.findIndex((y) => y.id === x.id);
            if (changedRowIndex !== -1) {
              temp[changedRowIndex] = {
                ...temp[changedRowIndex],
                liked: false,
                numberOfLikes: temp[changedRowIndex].numberOfLikes - 1,
              };
            }

            return temp;
          });
          setRerender((x) => !x);
          fetchItineraries();
        }
      } catch (err) {
        console.log(err);
      }
    },
    []
  );

  const [rerender, setRerender] = useState(false);

  console.log(itineraries);

  const handleImageFallBack = (e) => {
    e.target.onerror = null; // Prevent an infinite loop if the fallback image fails
    e.target.src = placeholderplace; // Set the fallback image source
  };

  const handleProfileFallback = (e) => {
    e.target.onerror = null; // Prevent an infinite loop if the fallback image fails
    e.target.src = profile; // Set the fallback image source
  };

  return (
    <div className="feedContainer">
      {loading ? (
        <div className="loading-overlay">
          <div className="loading-content">
            <p>Loading...</p>
            <div className="spinner"></div>
          </div>
        </div>
      ) : null}

      <div className="feedBody">
        <div className="feedNav">
          {navLabels.map((x, i) => {
            return (
              <div
                onClick={() => setSelectedNav(x)}
                className={`${
                  x === selectedNav ? "feedLinkActive" : "feedLink"
                }`}
              >
                {x}
              </div>
            );
          })}
        </div>
        {selectedNav === "Famous Itineraries" ? (
          <InfiniteScroll
            dataLength={items.length} // Length of the items array
            next={loadMoreItems} // Function to load more items
            hasMore={hasMore} // Whether there are more items to load
            loader={<h4>Loading...</h4>}
            endMessage={
              <p style={{ marginTop: "1.5rem" }}>No more itineraries to show</p>
            }
          >
            <div
              key={`${selectedNav === "Famous Itineraries" ? "sel" : "not"}`}
              className={`${
                selectedNav === "Famous Itineraries" ? "feedContent" : ""
              }`}
            >
              {itineraries.length > 0 ? (
                itineraries?.map((x, i) => {
                  return (
                    <div className="feedCard" key={i}>
                      <LazyLoadImage
                        src={`${hostURL}/${x.photo}`}
                        alt="no img"
                        className="feedImg"
                        onClick={() => {
                          window.location.href = `/viewitinerary/${x.id}`;
                        }}
                        style={{ cursor: "pointer" }}
                        onError={handleImageFallBack}
                      />

                      <div className="feedInformation">
                        <h1
                          className="titleview"
                          onClick={() => {
                            window.location.href = `/viewitinerary/${x.id}`;
                          }}
                          style={{
                            cursor: "pointer",
                            color: "darkblue !important",
                          }}
                        >
                          {x?.title?.length > 15
                            ? x.title.slice(0, 15) + "..."
                            : x.title}
                        </h1>
                        <p>
                          {x?.description?.length > 60
                            ? x.description.slice(0, 60) + "..."
                            : x.description}
                        </p>
                        <div className="feedCardUserDetails">
                          <div
                            className="feedCardUser"
                            onClick={() => {
                              window.location.href = `/userprofile/${x.user_id}`;
                            }}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            <LazyLoadImage
                              src={x.profilePicture}
                              alt="no img"
                              className="feedUserImg"
                              onError={handleProfileFallback}
                            />
                            <div>{x.username}</div>
                          </div>
                          <div className="feedCardDetails">
                            <FaHeart
                              className={`feedHeart ${
                                x.liked ? "hearted" : "nothearted"
                              }`}
                              size={25}
                              onClick={
                                x.liked ? handleUnlike(x) : handleLike(x)
                              }
                              key={rerender}
                            />
                            <div>{x.numberOfLikes || 0}</div>
                            <FaComments
                              className="feedComment"
                              size={25}
                              onClick={() => {
                                window.location.href = `/viewitinerary/${x.id}`;
                              }}
                            />
                            <div>{x.numberOfComments || 0}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="centercontainer">
                  {!loading ? (
                    <LazyLoadImage
                      src={emptyGeneral}
                      className="usererrorimage2"
                    />
                  ) : null}
                </div>
              )}
            </div>
          </InfiniteScroll>
        ) : (
          <div
            key={`${selectedNav !== "Famous Itineraries" ? "sel" : "not"}`}
            className={`${
              selectedNav !== "Famous Itineraries" ? "feedContent" : ""
            }`}
          >
            {itineraries.filter((f) => f.followed).length > 0 ? (
              itineraries
                .filter((f) => f.followed)
                .map((x, i) => {
                  return (
                    <div className="feedCard" key={i}>
                      <LazyLoadImage
                        src={`${hostURL}/${x.photo}`}
                        alt="no img"
                        className="feedImg"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          window.location.href = `/viewitinerary/${x.id}`;
                        }}
                        onError={handleImageFallBack}
                      />

                      <div className="feedInformation">
                        <h1
                          className="titleview"
                          onClick={() => {
                            window.location.href = `/viewitinerary/${x.id}`;
                          }}
                          style={{
                            cursor: "pointer",
                            color: "darkblue !important",
                          }}
                        >
                          {x?.title?.length > 15
                            ? x.title.slice(0, 15) + "..."
                            : x.title}
                        </h1>
                        <p>
                          {x?.description?.length > 60
                            ? x.description.slice(0, 60) + "..."
                            : x.description}
                        </p>
                        <div className="feedCardUserDetails">
                          <div
                            className="feedCardUser"
                            onClick={() => {
                              window.location.href = `/userprofile/${x.user_id}`;
                            }}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            <LazyLoadImage
                              src={x.profilePicture}
                              alt="no img"
                              className="feedUserImg"
                              onError={handleProfileFallback}
                            />
                            <h1>{x.username}</h1>
                          </div>
                          <div className="feedCardDetails">
                            <FaHeart
                              className={`feedHeart ${
                                x.liked ? "hearted" : "nothearted"
                              }`}
                              size={25}
                              onClick={
                                x.liked ? handleUnlike(x) : handleLike(x)
                              }
                            />
                            <div>{x.numberOfLikes || 0}</div>
                            <FaComments
                              className="feedComment"
                              size={25}
                              onClick={() => {
                                window.location.href = `/viewitinerary/${x.id}`;
                              }}
                            />
                            <div>{x.numberOfComments || 0}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
            ) : (
              <div className="centercontainer">
                <LazyLoadImage
                  src={emptyFollowing}
                  className="usererrorimage2"
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Feed;
