import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import image from "../assets/logo.png";
import validatePasswordV2 from "../../ValidationHelper";
import { toast, ToastContainer } from "react-toastify";
import hostURL from "../config";

const ChangePassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { hash, token } = useParams();
  const navigate = useNavigate(); // Hook for navigation
  const TOAST_STYLE = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    style: {
      marginTop: "100px", // Add 100px margin from the top
    },
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    toast.dismiss();
    const passwordValidate = validatePasswordV2(password);
    if (passwordValidate.oldPassword) {
      toast.warn(passwordValidate.oldPassword, TOAST_STYLE);
    }
    if (!confirmPassword) {
      toast.warn("Confirm Password is Required", TOAST_STYLE);
    } else if (
      password === confirmPassword &&
      password.length > 0 &&
      confirmPassword.length > 0
    ) {
      const inputData = {
        hash: hash,
        token: token,
        password: password,
      };
      axios
        .post(`${hostURL}/change-password`, inputData)
        .then((res) => {
          if (res.status === 200) {
            toast.success("Password changed successfully!", TOAST_STYLE);
            navigate("/login");
          } else {
            toast.warn("No record exists!", TOAST_STYLE);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.warn("Invalid request", TOAST_STYLE);
        });
    } else {
      toast.warn("Password not Match", TOAST_STYLE);
    }
  };

  return (
    <div className="loginSuccess">
      <ToastContainer
        style={{ zIndex: 9999 }}
        position="top-right"
        autoClose={5000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="login">
        <div className="loginLogo">
          <img src={image} alt="logo" />
        </div>
        <form action="" className="loginForm" onSubmit={handleSubmit}>
          <div>
            <p className="welcomeTxt">Reset Password</p>
          </div>
          <div>
            <input
              type="password"
              placeholder="Password"
              id="password"
              onChange={(e) => setPassword(e.target.value)}
              className="inputForms"
            />
          </div>
          <div>
            <input
              type="password"
              placeholder="Confirm Password"
              id="confirm_password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="inputForms"
            />
          </div>
          <div>
            <button type="submit" className="buttonLogin mt-4">
              Change Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
