//PasswordValidation.js
/*
function validatePassword(values) {
  const errors = {};
  const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  // Validate old password
  if (!values.oldPassword) {
    errors.oldPassword = "Old password is required";
  } else if (!passwordPattern.test(values.oldPassword)) {
    errors.oldPassword = "Password must be at least 8 characters long and include uppercase, lowercase, number, and special character";
  }

  // Validate new password
  if (!values.newPassword) {
    errors.newPassword = "New password is required";
  } else if (!passwordPattern.test(values.newPassword)) {
    errors.newPassword = "Password must be at least 8 characters long and include uppercase, lowercase, number, and special character";
  } else if (values.newPassword === values.oldPassword) {
    errors.newPassword = "New password cannot be the same as the old password";
  }

  // Validate confirm password
  if (!values.confirmPassword) {
    errors.confirmPassword = "Please confirm your password";
  } else if (values.newPassword !== values.confirmPassword) {
    errors.confirmPassword = "Passwords do not match";
  }

  return errors;
}

export default validatePassword;
*/

function validatePassword(values) {
  const newErrors = {};

  // Password validation
  const passwordPattern =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  if (!values.oldPassword) {
    newErrors.oldPassword = "Old password is required";
  }

  if (!values.newPassword) {
    newErrors.newPassword = "New password is required";
  } else if (values.newPassword.length > 128) {
    newErrors.newPassword = "New password is too long (maximum 128 characters)";
  } else if (!passwordPattern.test(values.newPassword)) {
    newErrors.newPassword =
      "New password must be at least 8 characters long and include uppercase, lowercase, number, and special character";
  } else if (values.newPassword === values.oldPassword) {
    newErrors.newPassword = "You have reused your previous password";
  }

  if (!values.confirmPassword) {
    newErrors.confirmPassword = "Please confirm your new password";
  } else if (values.newPassword !== values.confirmPassword) {
    newErrors.confirmPassword = "Passwords do not match";
  }

  return newErrors;
}

export default validatePassword;
