import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Formik, Form, Field } from "formik";
import ReactFileReader from "react-file-reader";
import MyMap from "./MyMap";
import styled from "styled-components";
import "./userprofile.css";
import "./styles.css";
import { SlOptions } from "react-icons/sl";
import jsPDF from "jspdf";
import logo from "./pages/assets/logo.png";
import customMarkerIcon from "./pages/assets/custom-marker.png";
import "./modal.css";
import "jspdf-autotable";

import { FaEdit, FaHeart, FaComments } from "react-icons/fa";
import dayjs from "dayjs";
import "./modal.css";
import { toast, ToastContainer } from "react-toastify";

import { LazyLoadImage } from "react-lazy-load-image-component";
import hostURL from "./pages/config";
import profile from "./pages/assets/profile.png";
import place from "./pages/assets/placeholderplace.png";

import published from "./pages/assets/published.png";

import drafts from "./pages/assets/drafts.png";

import bookmark from "./pages/assets/bookmark.png";
export const AvatarInput = styled.div`
  margin-bottom: 32px;
  position: relative;
  align-self: center;

  img {
    width: 100px;
    height: 100px;
    object-fit: contain;
    border-radius: 50%;
  }

  .circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }

  label {
    right: 23em !important;
    position: absolute;
    width: 48px;
    height: 48px;
    background: #312e38;
    border-radius: 50%;
    right: 0;
    bottom: 0;
    border: 0;
    cursor: pointer;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;

    input {
      display: none;
    }

    svg {
      width: 20px;
      height: 20px;
      color: #f4ede8;
    }

    &:hover {
      background: blue;
    }
  }
`;

function UserProfile({ isLoggedIn, setProfileCardForApp }) {
  const [showModal, setShowModal] = useState(false);
  const [display, setDisplay] = useState("drafts");
  const navigate = useNavigate();
  const [isCopied, setIsCopied] = useState(false);
  const [userData, setUserData] = useState({});
  const [url, setUrl] = useState("https://placehold.co/400");
  const [profileCard, setProfileCard] = useState("https://placehold.co/400");
  const [profileFile, setProfileFile] = useState();
  const [initialValues, setInitialValue] = useState({
    firstName: "",
    lastName: "",
    bio: "",
    username: "",
    website: "",
    id: "-1",
    image_base64: "",
  });

  const canvasRef = useRef(null);
  const token = localStorage.getItem("userToken");

  const userId = localStorage.getItem("userId");

  //const googleMapsApiKey = "AIzaSyCWQ3bmZoMMu6dkQ9TxUx8ln5lLQdNk_Do"; // Replace with your actual API key

  const handleEditClick = () => {
    setShowModal(true);
  };

  const handleProfileFallback = (e) => {
    e.target.onerror = null; // Prevent an infinite loop if the fallback image fails
    e.target.src = profile; // Set the fallback image source
  };

  const TOAST_STYLE = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  };
  const fetchCurrentUser = async () => {
    try {
      if (token) {
        const response = await axios.get(`${hostURL}/user`, {
          headers: {
            Authorization: token,
          },
        });
        const { data } = response;
        setInitialValue({
          firstName: data.firstName || "",
          lastName: data.lastName || "",
          bio: data.bio || "",
          username: data.username || "",
          website: data.website || "",
          id: data.id,
          privacy: data.private || "",
          image_base64: data.image_base64,
        });
        setProfileCard(data.image_base64);
        localStorage.setItem("profileCard", data.image_base64);
        setUserData(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // useEffect(() => {
  //   // Fetch user location coordinates and set as a marker
  //   const fetchLocation = async () => {
  //     if (initialValues.location) {
  //       try {
  //         const geocodeResponse = await axios.get(
  //           "https://maps.googleapis.com/maps/api/geocode/json",
  //           {
  //             params: {
  //               address: initialValues.location,
  //               key: googleMapsApiKey,
  //             },
  //           }
  //         );

  //         if (geocodeResponse.data.status === "OK") {
  //           const location = geocodeResponse.data.results[0].geometry.location;
  //           setMarkers([
  //             {
  //               name: `${initialValues.firstName} ${initialValues.lastName}'s Location`,
  //               position: location,
  //               icon: customMarkerIcon, // Use the imported custom icon
  //             },
  //           ]);
  //         } else {
  //           console.error("Geocoding error:", geocodeResponse.data.status);
  //         }
  //       } catch (error) {
  //         console.error("Error fetching geocode data:", error);
  //       }
  //     }
  //   };

  //   fetchLocation();
  // }, [initialValues]);

  useEffect(() => {
    if (!userId) return;
    fetchCurrentUser();
  }, []);

  const [followings, setFollowings] = useState([]);

  const fetchFollows = useCallback(async () => {
    try {
      if (!userId) return;

      const response = await axios.get(
        `${hostURL}/follows?user_id=${userId}`,
        {}
      );

      const { data } = response;

      setFollowings(() => data);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    fetchFollows();
  }, [fetchFollows]);

  const handleSaveClick = () => {
    setShowModal(false);
  };

  const handleCopy = () => {
    const url = `${window.location.origin}/userprofile/${initialValues.id}`;
    navigator.clipboard.writeText(url).then(() => {
      setIsCopied(true);

      setTimeout(() => {
        //toast.success("Link Copied!", TOAST_STYLE);
      }, 1000);
    });
  };

  const onSubmit = async (values) => {
    console.log(values);
    try {
      if (token) {
        const response = await axios.put(`${hostURL}/user`, values, {
          headers: {
            Authorization: token,
          },
        });
        if (!url.includes("http") && profileFile) {
          const formData = new FormData();
          formData.append("file", profileFile.fileList[0]);
          // await axios.post(`${hostURL}/upload-profile/${userId}`, formData, {
          //   headers: {
          //     Authorization: token,
          //   },
          // });
        }
        if (response.status === 200) {
          setProfileCardForApp(values.image_base64);
          fetchCurrentUser();
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setShowModal(false);
    }
  };

  const handleFiles = (files) => {
    setProfileFile(files);
    setUrl(files.base64);

    setInitialValue((prev) => {
      return { ...prev, image_base64: files.base64 };
    });

    localStorage.setItem("profileCard", files.base64);
  };

  const [itineraries, setItineraries] = useState([]);

  const [loading, setLoading] = useState(false);

  const fetchItineraries = async () => {
    try {
      if (!userId) {
        console.error("User ID not found. Unable to fetch itineraries.");
        return;
      }

      setLoading(true);

      // Fetch itineraries for the user
      const response = await fetch(`${hostURL}/itinerariesprofile/${userId}`); // Replace with your API URL
      if (!response.ok) {
        setItineraries([]);
        setLoading(false);
        throw new Error("Error fetching itineraries");
      }

      const data = await response.json();

      console.log("itineraries", data);
      // Check if there are itineraries
      if (data && data.length > 0) {
        const res = data.map((x) => {
          return {
            id: x.id,
            user_id: x.user_id,
            title: x.trip_name,
            description: x.description,
            image: `${hostURL}/${x.photo}`,
            profilePicture: x.profile_pic,
            username: x.username || "No Name",
            numberOfComments: x.comments_count || 0,
            numberOfLikes: x.likes_count || 0,
            liked: x.liked || false,
            followed: x.followed || false,
            bookmarked: x.bookmarked || false,
            status: x.status,
            startDate: x.start,
            endDate: x.end,
            pic: x.pic,
          };
        });

        setItineraries(res); // Store itineraries in the state
      } else {
        setLoading(false);
        setItineraries([]); // Handle empty data if needed
      }
    } catch (error) {
      setItineraries([]);

      setLoading(false);
      console.error("Error fetching itineraries:", error);
    } finally {
      setLoading(false);
    }
  };

  //Change Status to Published
  const handlePublish = async (itineraryId) => {
    try {
      const token = localStorage.getItem("userToken");
      if (!token) {
        return;
      }

      setLoading(true);

      const response = await axios.put(
        `${hostURL}/itineraries/${itineraryId}/publish`, // Replace :itinerary_id with the actual value
        { status: "published" },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      if (response.status === 200) {
        // window.location.href = `viewitinerary/${itineraryId}`;
        fetchItineraries(); // Refresh the itineraries
      } else {
        throw new Error("Failed to publish itinerary.");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error publishing itinerary:", error);
    } finally {
      setLoading(false);
    }
  };

  //export as pdf
  const exportToPDF = async (itineraryId) => {
    try {
      // Fetch itinerary details
      const response = await axios.get(`${hostURL}/itinerary/${itineraryId}`);
      const itinerary = response.data;

      if (!itinerary) {
        return;
      }

      const {
        trip_name: tripName,
        destination_city: destinationCity,
        start_date: startDate,
        end_date: endDate,
        tripdescription: tripdescription,
      } = itinerary;

      setLoading(true);

      // Fetch places for the itinerary
      const placesResponse = await axios.get(
        `${hostURL}/api/places/${itineraryId}`
      );
      const places = placesResponse.data;

      // Initialize jsPDF
      const doc = new jsPDF();
      const pageWidth = doc.internal.pageSize.width;
      const margin = 10;
      let y = 20;

      // Add Logo
      const logoWidth = 40;
      const logoHeight = 25;
      const logoX = (pageWidth - logoWidth) / 2;
      doc.addImage(logo, "PNG", logoX, 10, logoWidth, logoHeight);
      y += logoHeight + 10;

      // Add Title
      doc.setFontSize(18);
      doc.setFont("helvetica", "bold");
      doc.text("Itinerary Summary", pageWidth / 2, y, { align: "center" });
      y += 10;

      // Add Itinerary Details
      doc.setFontSize(12);
      doc.setFont("helvetica", "normal");
      doc.text(`Title: ${tripName || "N/A"}`, margin, (y += 10));
      doc.text(`Destination: ${destinationCity || "N/A"}`, margin, (y += 10));
      doc.text(
        `Start Date: ${new Date(startDate).toLocaleDateString()}`,
        margin,
        (y += 10)
      );
      doc.text(
        `End Date: ${new Date(endDate).toLocaleDateString()}`,
        margin,
        (y += 10)
      );
      doc.text(`Description: ${tripdescription || "N/A"}`, margin, (y += 10));
      y += 10;

      // Group places by days
      const groupedPlaces = {};
      places.forEach((place) => {
        const day = place.day || "Unassigned Day";
        if (!groupedPlaces[day]) groupedPlaces[day] = [];
        groupedPlaces[day].push(place);
      });

      // Iterate through days and add data
      Object.keys(groupedPlaces).forEach((day, index) => {
        // Add Day Header
        doc.setFont("helvetica", "bold");
        doc.setFontSize(14);
        doc.text(`Day ${index + 1} - ${day}:`, margin, y);
        y += 10;

        // Prepare Table Data
        const tableData = groupedPlaces[day].map((place) => [
          place.name || "N/A",
          place.description || "N/A",
          place.time || "N/A",
          place.cost ? `₱${place.cost.toLocaleString()}` : "N/A",
        ]);

        // Add Table
        doc.autoTable({
          head: [["Place Name", "Description", "Time", "Cost"]],
          body: tableData,
          startY: y,
          styles: { fontSize: 10, cellPadding: 5 },
          headStyles: { fillColor: [40, 116, 240], textColor: [255, 255, 255] },
          theme: "striped",
          margin: { left: margin, right: margin },
          didDrawPage: (data) => {
            y = data.cursor.y + 10; // Update y position for the next content
          },
        });
      });

      // Save the PDF
      doc.save(`${tripName || "Itinerary"}.pdf`);
    } catch (error) {
      console.error("Error exporting PDF:", error);
      alert("Failed to export PDF. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Delete Itinerary
  const handleDelete = async (itineraryId) => {
    try {
      const token = localStorage.getItem("userToken");
      if (!token) {
        alert("You are not logged in.");
        return;
      }

      // Making a DELETE request to delete an itinerary
      const response = await axios.delete(
        `${hostURL}/itineraries/${itineraryId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Prefixed with Bearer
          },
        }
      );

      if (response.status === 200) {
        alert("Itinerary deleted successfully.");
        fetchItineraries(); // Refresh the itineraries
      }
    } catch (error) {
      if (error.response) {
        // If an error response is received from the server
        if (error.response.status === 404) {
          alert("Itinerary not found. It may have already been deleted.");
        } else if (error.response.status === 401) {
          alert("You are not authorized to delete this itinerary.");
        } else {
          alert(
            `Error: ${
              error.response.data.error ||
              "An error occurred while deleting the itinerary."
            }`
          );
        }
      } else {
        // For any other network or unexpected errors
        console.error("Error deleting itinerary:", error);
        alert("An unexpected error occurred while deleting the itinerary.");
      }
    }
  };

  useEffect(() => {
    // Fetch itineraries when the component mounts

    fetchItineraries();
  }, []);

  const [selectedToDelete, setSelectedToDelete] = useState(-1);

  const returnItineraryCard = (x) => {
    return (
      <div className="itinerarycard" key={x.id}>
        <div className="itinerarycard-content">
          <h1
            onClick={() => {
              window.location.href = `/viewitinerary/${x.id}`;
            }}
            style={{
              color: "darkblue !important",
              cursor: "pointer",
            }}
          >
            {x?.title?.length > 23 ? x.title.slice(0, 23) + "..." : x.title}
          </h1>
          <span>{`${dayjs(x.startDate).format("MMM DD, YYYY")} - ${dayjs(
            x.endDate
          ).format("MMM DD, YYYY")}`}</span>
          <p className="toEllipsis" style={{ flex: "2" }}>
            {x?.description?.length > 90
              ? x.description.slice(0, 90) + "..."
              : x.description}
          </p>

          <div className="itinerarycard-content-footer">
            <div className="genericrow">
              {display === "saved" && initialValues.id !== x.user_id ? null : (
                <div
                  className="genericrow2 genericbutton"
                  onClick={() => {
                    window.location.href = `/edit-itinerary/${x.id}`;
                  }} // Use x.id to access the itinerary's ID
                  style={{ cursor: "pointer" }}
                  saved
                >
                  <FaEdit size={18} color="black" />
                  <span className="tohide">Edit Trip</span>
                </div>
              )}

              <div className="genericrow2 genericbutton optionsactivator">
                <div className="  itinerarycard-options">
                  <div className="itinerarycard-optionscontent">
                    {x.status !== "published" ? (
                      <button
                        type="button"
                        className="itinerarycard-option"
                        onClick={() => handlePublish(x.id)}
                      >
                        Publish
                      </button>
                    ) : null}

                    <button
                      type="button"
                      className="itinerarycard-option"
                      onClick={() => exportToPDF(x.id)} // Pass only the itinerary ID
                    >
                      Export PDF
                    </button>

                    {initialValues.id === x.user_id ? (
                      <button
                        type="button"
                        className="itinerarycard-option"
                        onClick={() => {
                          setSelectedToDelete(x.id);
                          setShowDeleteConfirmation(true);
                        }}
                      >
                        Delete
                      </button>
                    ) : null}
                  </div>
                </div>
                <SlOptions size={18} color="black" />
                <span className="tohide">More Options</span>
              </div>
            </div>
            <div className="genericrow">
              <div className="genericrow2 genericbutton">
                <FaHeart
                  size={18}
                  color={x.numberOfLikes > 0 ? "red" : "gray"}
                />
                <span>{x.numberOfLikes}</span>
              </div>
              <div className="genericrow2 genericbutton">
                <FaComments size={18} color="gray" />
                <span>{x.numberOfComments}</span>
              </div>
            </div>
          </div>
        </div>

        <LazyLoadImage
          src={x.image}
          alt="itinerary"
          className="itinerarycard-image"
          onerror={handleImageFallback}
          onClick={() => {
            window.location.href = `/viewitinerary/${x.id}`;
          }}
          style={{
            cursor: "pointer",
          }}
        />
      </div>
    );
  };

  const handleFollow2 = useCallback(
    (x) => async () => {
      try {
        const dto = {
          user_id: initialValues.id,
          following_id:
            initialValues.id !== x.follower ? x.follower : x.following,
        };

        const response = await axios.post(`${hostURL}/follow`, dto);

        if (response.status === 200) {
          window.location.reload();
        }
      } catch (err) {
        console.log(err);
      }
    },
    [initialValues]
  );

  const handleUnfollow2 = useCallback(
    (x) => async () => {
      try {
        const dto = {
          user_id: initialValues.id,
          following_id:
            initialValues.id !== x.follower ? x.follower : x.following,
        };

        const unlikeResponse = await axios.delete(
          `${hostURL}/unfollow?user_id=${dto.user_id}&following_id=${dto.following_id}`,
          dto
        );

        if (unlikeResponse.status === 200) {
          window.location.reload();
        }
      } catch (err) {
        console.log(err);
      }
    },
    [initialValues]
  );

  const [followsModal, setFollowsModal] = useState(false);
  const [followSelected, setFollowSelected] = useState(0);

  const followModalRef = useRef(null);

  console.log(itineraries);
  // Detect click outside the modal
  useEffect(() => {
    if (followsModal) {
      const handleClickOutside = (event) => {
        if (
          followModalRef.current &&
          !followModalRef.current.contains(event.target)
        ) {
          setFollowsModal(false); // Optionally close the modal when clicked outside
        }
      };

      // Add the event listener
      document.addEventListener("mousedown", handleClickOutside);

      // Clean up the event listener
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [followsModal]);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const deleteModalRef = useRef(null);

  // Detect click outside the modal
  useEffect(() => {
    if (deleteModalRef) {
      const handleClickOutside = (event) => {
        if (
          deleteModalRef.current &&
          !deleteModalRef.current.contains(event.target)
        ) {
          setShowDeleteConfirmation(false); // Optionally close the modal when clicked outside
        }
      };

      // Add the event listener
      document.addEventListener("mousedown", handleClickOutside);

      // Clean up the event listener
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [showDeleteConfirmation]);

  console.log(itineraries);

  const handleImageFallback = (e) => {
    e.target.onerror = null; // Prevent an infinite loop if the fallback image fails
    e.target.src = place; // Set the fallback image source
  };

  const UserProfileContent = () => (
    <>
      {loading ? (
        <div className="loading-overlay">
          <div className="loading-content">
            <p>Loading...</p>
            <div className="spinner"></div>
          </div>
        </div>
      ) : null}
      <div
        className={`modalcontainer ${showDeleteConfirmation ? "active" : ""}`}
      >
        <div ref={deleteModalRef} className="modalbox">
          <div className="modalheader">
            <h1>Delete</h1>
          </div>

          <div className="modalcontent">
            <p>Are you sure you want to delete this trip?</p>
          </div>

          <div className="modalbottom">
            <input
              type="button"
              value="NO"
              onClick={() => {
                setShowDeleteConfirmation(false);
              }}
              className="modalButtonGray"
            />
            <input
              type="button"
              value="YES"
              onClick={() => {
                setShowDeleteConfirmation(false);
                handleDelete(selectedToDelete);
              }}
              className="modalButtonRed"
            />
          </div>
        </div>
      </div>
      <div className="generic-container">
        <div className={`modalcontainer ${followsModal ? "active" : ""}`}>
          <div ref={followModalRef} className="profilebox">
            <div className="profilemodalheader">
              <h1
                className={`${followSelected === 0 ? "active" : ""}`}
                onClick={() => {
                  setFollowSelected(() => 0);
                  setFollowsModal(true);
                }}
              >
                FOLLOWERS
              </h1>
              <h1
                className={`${followSelected === 1 ? "active" : ""}`}
                onClick={() => {
                  setFollowSelected(() => 1);
                  setFollowsModal(true);
                }}
              >
                FOLLOWING
              </h1>
            </div>

            <div className="profilemodalcontent">
              {followings.filter((x) =>
                followSelected === 0
                  ? x.following === initialValues.id
                  : x.follower === initialValues.id
              ).length > 0 ? (
                followings
                  .filter((x) =>
                    followSelected === 0
                      ? x.following === initialValues.id
                      : x.follower === initialValues.id
                  )
                  .map((y, i) => {
                    return (
                      <div key={i}>
                        <div className="profilerow">
                          <LazyLoadImage
                            className="profileimage"
                            src={
                              followSelected === 0
                                ? y.follower_pic !== ""
                                  ? y.follower_pic
                                  : profile
                                : y.following_pic !== ""
                                ? y.following_pic
                                : profile
                            }
                            alt="profile"
                            onError={handleProfileFallback}
                            onClick={() => {
                              window.location.href = `/userprofile/${
                                followSelected === 0 ? y.follower : y.following
                              }`;
                            }}
                            style={{
                              cursor: "pointer",
                            }}
                          />
                          <div
                            className="columnstartmodal"
                            onClick={() => {
                              window.location.href = `/userprofile/${
                                followSelected === 0 ? y.follower : y.following
                              }`;
                            }}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            <div>
                              {followSelected === 0
                                ? y.follower_name
                                : y.following_name}
                            </div>
                            <div>
                              @
                              {followSelected === 0
                                ? y.follower_username
                                : y.following_username}
                            </div>
                          </div>
                        </div>

                        {followSelected === 0 ? (
                          y.follower_private ? (
                            <input
                              type="button"
                              value={"PRIVATE"}
                              className="profilemodalbuttongray"
                            />
                          ) : (
                            <input
                              type="button"
                              value={`${
                                followSelected === 0
                                  ? followings.find(
                                      (x) =>
                                        x.follower === initialValues.id &&
                                        x.following === y.follower
                                    )
                                    ? "UNFOLLOW"
                                    : "FOLLOW"
                                  : followings.find(
                                      (x) =>
                                        x.follower === initialValues.id &&
                                        x.following === y.following
                                    )
                                  ? "UNFOLLOW"
                                  : "FOLLOW"
                              }`}
                              onClick={
                                followSelected === 0
                                  ? followings.find(
                                      (x) =>
                                        x.follower === initialValues.id &&
                                        x.following === y.follower
                                    )
                                    ? handleUnfollow2(y)
                                    : handleFollow2(y)
                                  : followings.find(
                                      (x) =>
                                        x.follower === initialValues.id &&
                                        x.following === y.following
                                    )
                                  ? handleUnfollow2(y)
                                  : handleFollow2(y)
                              }
                              className="profilemodalbutton"
                            />
                          )
                        ) : y.following_private ? (
                          <input
                            type="button"
                            value={"PRIVATE"}
                            className="profilemodalbuttongray"
                          />
                        ) : (
                          <input
                            type="button"
                            value={`${
                              followSelected === 0
                                ? followings.find(
                                    (x) =>
                                      x.follower === initialValues.id &&
                                      x.following === y.follower
                                  )
                                  ? "UNFOLLOW"
                                  : "FOLLOW"
                                : followings.find(
                                    (x) =>
                                      x.follower === initialValues.id &&
                                      x.following === y.following
                                  )
                                ? "UNFOLLOW"
                                : "FOLLOW"
                            }`}
                            onClick={
                              followSelected === 0
                                ? followings.find(
                                    (x) =>
                                      x.follower === initialValues.id &&
                                      x.following === y.follower
                                  )
                                  ? handleUnfollow2(y)
                                  : handleFollow2(y)
                                : followings.find(
                                    (x) =>
                                      x.follower === initialValues.id &&
                                      x.following === y.following
                                  )
                                ? handleUnfollow2(y)
                                : handleFollow2(y)
                            }
                            className="profilemodalbutton"
                          />
                        )}
                      </div>
                    );
                  })
              ) : (
                <div>Nothing to show here</div>
              )}
            </div>

            <div className="modalbottom">
              <input
                type="button"
                value="CLOSE"
                onClick={() => {
                  setFollowsModal(false);
                }}
                className="modalButton"
              />
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <ToastContainer
            position="top-right"
            autoClose={5000}
            limit={1}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover
            theme="light"
          />

          <div className="row">
            <div className="profile-card">
              <LazyLoadImage
                alt="Profile"
                src={profileCard}
                className="circleimage"
                onError={handleProfileFallback}
              />
              <h2>
                {userData.firstName} {userData.lastName}
              </h2>
              <p className="profusername">@{userData.username}</p>
              <p className="card-text bio">{userData.bio}</p>
              {userData.website && (
                <button
                  className="btn  mb-3 mt-1 websitecont"
                  onClick={() => {
                    window.open(
                      userData.website.startsWith("http://")
                        ? userData.website
                        : "http://" + userData.website,
                      "_blank"
                    );
                  }}
                >
                  <i className="bi bi-facebook"></i> {userData.website}
                </button>
              )}
              <div className="stats">
                <div
                  onClick={() => {
                    setFollowSelected(0);
                    setFollowsModal(true);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <p>
                    {
                      followings.filter((x) => x.following === initialValues.id)
                        .length
                    }
                  </p>
                  <span>FOLLOWERS</span>
                </div>
                <div
                  onClick={() => {
                    setFollowSelected(1);
                    setFollowsModal(true);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <p>
                    {
                      followings.filter((x) => x.follower === initialValues.id)
                        .length
                    }
                  </p>
                  <span>FOLLOWING</span>
                </div>
              </div>
              <div className="actions">
                <button className="edit" onClick={handleEditClick}>
                  <LazyLoadImage src="./edit.png" alt="Edit icon" />
                  Edit
                </button>
                <button className="copy-link" onClick={handleCopy}>
                  <i className="fas fa-link"></i>
                  {isCopied ? "Link Copied!" : "Copy Link"}
                </button>
              </div>
            </div>

            <div className="widecolumn">
              {/* <div className="map-container col-7">
              <MyMap
                key={userData.location}
                userLocation={userData.location}
                markers={markers}
              />
            </div> */}

              <div className="button-container">
                <div className="menu">
                  <nav className="nav">
                    <button
                      className={`nav-link menu-item ${
                        display === "publish" ? "saved" : ""
                      }`}
                      onClick={() => setDisplay("publish")}
                    >
                      <i className="fas fa-print"></i> <b>Published</b>
                    </button>
                    <button
                      className={`nav-link menu-item ${
                        display === "drafts" ? "saved" : ""
                      }`}
                      onClick={() => setDisplay("drafts")}
                    >
                      <i className="fas fa-envelope"></i> <b>Drafts</b>
                    </button>
                    <button
                      className={`nav-link menu-item ${
                        display === "saved" ? "saved" : ""
                      }`}
                      onClick={() => setDisplay("saved")}
                    >
                      <i className="fas fa-bookmark"></i> <b>Saved</b>
                    </button>
                  </nav>
                </div>
              </div>
              {/* <div className="itinerarycard">
                  <div className="itinerarycard-content">
                    <h1>Boracay</h1>
                    <p>Caticlan, Philippines</p>
                    <p>July 5-7, 2024 (2 days)</p>
                    <div className="itinerarycard-content-footer">
                      <div className="genericrow">
                        <div className="genericrow2 genericbutton">
                          <FaEdit size={18} color="black" />
                          <span>Edit Trip</span>
                        </div>
                        <div className="genericrow2 genericbutton">
                          <SlOptions size={18} color="black" />
                          <span>More Options</span>
                        </div>
                      </div>
                      <div className="genericrow">
                        <div className="genericrow2 genericbutton">
                          <FaHeart size={18} color="red" />
                          <span>0</span>
                        </div>
                        <div className="genericrow2 genericbutton">
                          <FaComments size={18} color="gray" />
                          <span>0</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <img
                    src="https://wallpaperheart.com/wp-content/uploads/2018/04/beautiful-nature-high-definition-wallpaper_beautiful-backgrounds.jpg"
                    alt="itinerary"
                    className="itinerarycard-image"
                  />
                </div> */}

              {display === "publish" && (
                // <div className="itinerarycard">
                //   <center>
                //     There is nothing here. <br /> Create itineraries to publish!
                //   </center>
                // </div>

                <div className="itinerarycard-column">
                  {itineraries?.filter(
                    (x) =>
                      x.status === "published" && x.user_id === initialValues.id
                  )?.length > 0 ? (
                    itineraries
                      .filter(
                        (x) =>
                          x.status === "published" &&
                          x.user_id === initialValues.id
                      )
                      .map((x) => {
                        return returnItineraryCard(x);
                      })
                  ) : (
                    <div className="mt-3">
                      <LazyLoadImage
                        src={published}
                        className="usererrorimage"
                      />
                    </div>
                  )}
                </div>
              )}
              {display === "drafts" && (
                <div className="itinerarycard-column">
                  {itineraries?.filter(
                    (x) =>
                      x.status === "draft" &&
                      !x.bookmarked &&
                      x.user_id === initialValues.id
                  )?.length > 0 ? (
                    itineraries
                      .filter(
                        (x) =>
                          x.status === "draft" &&
                          !x.bookmarked &&
                          x.user_id === initialValues.id
                      )
                      .map((x) => {
                        return returnItineraryCard(x);
                      })
                  ) : (
                    <div className="mt-3">
                      <LazyLoadImage src={drafts} className="usererrorimage" />
                    </div>
                  )}
                </div>
              )}
              {display === "saved" && (
                <div className="itinerarycard-column">
                  {itineraries.filter((x) => x.bookmarked).length > 0 ? (
                    itineraries
                      .filter((x) => x.bookmarked)
                      .map((x) => {
                        return returnItineraryCard(x);
                      })
                  ) : (
                    <div className="mt-3">
                      <LazyLoadImage
                        src={bookmark}
                        className="usererrorimage"
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          {showModal && (
            <div
              className="modal"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center", // Align to top for better mobile view
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                overflowY: "auto", // Allow scrolling on mobile
              }}
            >
              <div
                className="modal-content"
                style={{
                  backgroundColor: "white",
                  padding: "1rem",
                  borderRadius: "10px",
                  width: "90%", // Adjust width for mobile
                  maxWidth: "400px", // Prevent modal from being too wide
                  height: "70vh", // Adjust height for content
                }}
              >
                <div className="modal-header" style={{ marginBottom: "1rem" }}>
                  <h2
                    style={{
                      fontSize: "1.2rem",
                      margin: 0,
                      textAlign: "center",
                    }}
                  >
                    Edit Profile
                  </h2>
                  <span
                    className="close-icon"
                    onClick={() => setShowModal(false)}
                    style={{ fontSize: "1.5rem", cursor: "pointer" }}
                  >
                    &times;
                  </span>
                </div>
                <div className="profile-icon" style={{ marginBottom: "1rem" }}>
                  <LazyLoadImage
                    src={initialValues.image_base64}
                    alt="Avatar Placeholder"
                    style={{
                      width: "80px", // Smaller profile image
                      height: "80px",
                      borderRadius: "50%",
                      marginBottom: "10px",
                    }}
                  />
                  <ReactFileReader
                    fileTypes={[".png", ".jpg"]}
                    base64={true}
                    handleFiles={handleFiles}
                  >
                    <i
                      className="fas fa-pencil-alt edit-icon"
                      style={{
                        fontSize: "1rem",
                        marginTop: "-20px", // Adjust for smaller image
                      }}
                    ></i>
                  </ReactFileReader>
                </div>
                <Formik
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                  enableReinitialize
                >
                  {({ values, isSubmitting, errors }) => (
                    <Form>
                      {/* First Name */}
                      <div
                        className="form-group"
                        style={{ marginBottom: "10px" }}
                      >
                        <label
                          htmlFor="first-name"
                          style={{ fontSize: "0.9rem" }}
                        >
                          First Name
                        </label>
                        <Field
                          type="text"
                          name="firstName"
                          style={{
                            fontSize: "1rem",
                            padding: "0.5rem",
                            borderRadius: "5px",
                            width: "100%", // Full width for mobile
                          }}
                        />
                      </div>
                      {/* Last Name */}
                      <div
                        className="form-group"
                        style={{ marginBottom: "5px" }}
                      >
                        <label
                          htmlFor="last-name"
                          style={{ fontSize: "0.9rem" }}
                        >
                          Last Name
                        </label>
                        <Field
                          type="text"
                          name="lastName"
                          style={{
                            fontSize: "1rem",
                            padding: "0.5rem",
                            borderRadius: "5px",
                            width: "100%", // Full width for mobile
                          }}
                        />
                      </div>
                      {/* Bio */}
                      <div
                        className="form-group"
                        style={{ marginBottom: "5px" }}
                      >
                        <label htmlFor="bio" style={{ fontSize: "0.9rem" }}>
                          Bio
                        </label>
                        <Field
                          type="text"
                          name="bio"
                          maxLength={50}
                          style={{
                            fontSize: "1rem",
                            padding: "0.5rem",
                            borderRadius: "5px",
                            width: "100%", // Full width for mobile
                          }}
                        />
                      </div>
                      {/* Username */}
                      <div
                        className="form-group"
                        style={{ marginBottom: "5px" }}
                      >
                        <label
                          htmlFor="username"
                          style={{ fontSize: "0.9rem" }}
                        >
                          Username
                        </label>
                        <Field
                          type="text"
                          name="username"
                          style={{
                            fontSize: "1rem",
                            padding: "0.5rem",
                            borderRadius: "5px",
                            width: "100%", // Full width for mobile
                          }}
                        />
                      </div>
                      {/* Website */}
                      <div
                        className="form-group"
                        style={{ marginBottom: "5px" }}
                      >
                        <label htmlFor="website" style={{ fontSize: "0.9rem" }}>
                          Website
                        </label>
                        <Field
                          type="text"
                          name="website"
                          style={{
                            fontSize: "1rem",
                            padding: "0.5rem",
                            borderRadius: "5px",
                            width: "100%", // Full width for mobile
                          }}
                        />
                      </div>
                      {/* Save Button */}
                      <div style={{ textAlign: "center", marginTop: "5px" }}>
                        <button
                          className="save-btn"
                          type="submit"
                          disabled={isSubmitting}
                          style={{
                            backgroundColor: "#18355c",
                            color: "white",
                            width: "90%",
                            padding: "0.2rem",
                            fontSize: "1rem",
                            borderRadius: "10px",
                          }}
                        >
                          Save
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          )}
          <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
        </div>
      </div>
    </>
  );

  const Placeholder = () => (
    <div className="container-fluid">
      <div className="row">
        <div className="profile-card col-3">
          <LazyLoadImage
            alt="Profile picture placeholder"
            src="./addprofilepic.png"
          />
          <h2>Jette Smith</h2>
          <p className="card-text text-muted">@jettesmith_123</p>
          <p className="card-text">To Travel is to Live. ✈️</p>
          <a
            href="https://facebook.com/"
            className="btn btn-outline-primary mb-3"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="bi bi-facebook"></i> facebook.com/jettyysmith
          </a>
          <div className="stats">
            <div>
              <p>
                {
                  followings.filter((x) => x.following === initialValues.id)
                    .length
                }
              </p>
              <span>FOLLOWERS</span>
            </div>
            <div>
              <p>
                {
                  followings.filter((x) => x.follower === initialValues.id)
                    .length
                }
              </p>
              <span>FOLLOWING</span>
            </div>
          </div>
          <div className="actions">
            <button className="edit" onClick={handleEditClick}>
              <img src="./edit.png" alt="Edit icon" />
              Edit
            </button>
            <button className="copy-link" onClick={handleCopy}>
              <i className="fas fa-link"></i>
              {isCopied ? "Link Copied!" : "Copy Link"}
            </button>
          </div>
        </div>
        <div className="map-container col-7">
          <div className="button-container">
            <div className="menu">
              <nav className="nav">
                <button
                  type="button"
                  className={`nav-link menu-item ${
                    display === "publish" ? "saved" : ""
                  }`}
                  onClick={() => setDisplay("publish")}
                >
                  <i className="fas fa-print"></i> <b>Published</b>
                </button>
                <button
                  type="button"
                  className={`nav-link menu-item ${
                    display === "drafts" ? "saved" : ""
                  }`}
                  onClick={() => setDisplay("drafts")}
                >
                  <i className="fas fa-envelope"></i> <b>Drafts</b>
                </button>
                <button
                  type="button"
                  className={`nav-link menu-item ${
                    display === "saved" ? "saved" : ""
                  }`}
                  onClick={() => setDisplay("saved")}
                >
                  <i className="fas fa-bookmark"></i> <b>Saved</b>
                </button>
              </nav>
            </div>
          </div>
          {display === "publish" && (
            <div className="mt-3" style={{ width: "100%" }}>
              <center>
                There is nothing here. <br /> Create itineraries to publish!
              </center>
            </div>
          )}
          {display === "drafts" && (
            <div className="mt-3" style={{ width: "100%" }}>
              <center>
                There is nothing here. <br /> Start creating itineraries!
              </center>
            </div>
          )}
          {display === "saved" && (
            <div className="mt-3" style={{ width: "100%" }}>
              <center>
                There is nothing here. <br /> View other itineraries to save!
              </center>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return <>{userData ? <UserProfileContent /> : <Placeholder />}</>;
}

export default UserProfile;
