import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom"; // Use useNavigate for navigation
import "./notification.css";
import { IoNotificationsOutline, IoNotifications } from "react-icons/io5";
import hostURL from "./pages/config";
function Notifications({ user_id }) {
  const [notifs, setNotifs] = useState([]);

  const [id, setId] = useState(null);

  useEffect(() => {
    setId(user_id);
  }, [user_id]);

  const fetchNotifs = async () => {
    console.log("Fetching Notifications...");

    try {
      // Fetch itineraries for the user
      const response = await fetch(`${hostURL}/notifs/${id}`); // Replace with your API URL
      if (!response.ok) {
        throw new Error("Error fetching itineraries");
      }

      const data = await response.json();

      console.log(data);

      // Check if there are itineraries
      if (data && data.length > 0) {
        setNotifs(data); // Store itineraries in the state
      } else {
        console.log("No itineraries found.");
        setNotifs([]); // Handle empty data if needed
      }
    } catch (error) {
      console.error("Error fetching itineraries:", error);
    }
  };

  useEffect(() => {
    // Fetch notifs when the component mounts

    if (id === -1 || !id) return;

    fetchNotifs();
  }, [id]);
  return (
    <div className="notificationsContainer" key={id}>
      {notifs.find((x) => x.isRecent) ? (
        <IoNotifications color="orange" size={25} />
      ) : (
        <IoNotificationsOutline color="#303030" size={25} />
      )}
      <div className="hoverContainer hoverContainer2">
        <div className="notificationContentActive">
          <h1>Notifications</h1>
          <div className="content">
            {notifs.length > 0 ? (
              notifs
                .filter((y) => y.message && y.message !== "")
                .map((x, i) => {
                  return (
                    <div
                      className="notifs"
                      key={i}
                      onClick={() => {
                        if (x.type === "follow") {
                          window.location.href = `/userprofile/${x.reference_id}`;
                        } else {
                          window.location.href = `/viewitinerary/${x.reference_id}`;
                        }
                      }}
                    >
                      <div key={x.id}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "0.1rem",
                          }}
                        >
                          <div>{x.message}</div>
                          <div
                            style={{
                              color: "gray",
                              fontWeight: "200 !important",
                            }}
                          >
                            {x.time_ago}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
            ) : (
              <div className="notifs">
                <div>No new notifications</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Notifications;
