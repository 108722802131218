import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import "./generatedItinerary.css";
import jsPDF from "jspdf";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import image from "./assets/logo.png";
import customMarkerIcon from "./assets/custom-marker.png";
import "jspdf-autotable";
import wrong from "./assets/wrong.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import hostURL from "./config";
import {
  faPen,
  faEye,
  faClock,
  faSave,
  faFileExport,
  faDollarSign,
  faPaperclip,
  faTrash,
  faPlus,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";

function EditItinerary() {
  const location = useLocation();
  const navigate = useNavigate();

  const [itinerary, setItinerary] = useState(null);
  const [places, setPlaces] = useState({});
  const [destination, setDestination] = useState("");

  const { itinerary_id } = useParams();
  const [newPlace, setNewPlace] = useState({});
  const [loading, setLoading] = useState(true);
  const [budget, setBudget] = useState(20000);
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [showBudgetModal, setShowBudgetModal] = useState(false);
  const [showBreakdownModal, setShowBreakdownModal] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [showTimeModal, setShowTimeModal] = useState(false);
  const [tripdescription, setTripdescription] = useState("");
  const [showExpenseModal, setShowExpenseModal] = useState(false);
  const [showAttachModal, setShowAttachModal] = useState(false);
  const [showEditPlaceModal, setShowEditPlaceModal] = useState(false);
  const [showEditTitleModal, setShowEditTitleModal] = useState(false);
  const [placeDescriptions, setPlaceDescriptions] = useState({});
  const [photo, setPhoto] = useState(null);
  const [timeDetails, setTimeDetails] = useState({
    startTime: "",
    endTime: "",
  });
  const [expense, setExpense] = useState("");
  const [description, setDescription] = useState("");
  const [editPlaceName, setEditPlaceName] = useState("");
  const [editTitle, setEditTitle] = useState(destination);

  const [newBudget, setNewBudget] = useState(budget);
  const [selectedCurrency, setSelectedCurrency] = useState("PHP");
  const [markers, setMarkers] = useState([]);
  const mapRef = useRef(null); // Reference to the map
  const googleMapsApiKey = "AIzaSyCWQ3bmZoMMu6dkQ9TxUx8ln5lLQdNk_Do";

  const token = localStorage.getItem("userToken");

  const userId = localStorage.getItem("userId");

  useEffect(() => {
    if (!itinerary || itinerary == null || !userId) return;

    if (userId != itinerary.user_id) {
      navigate("/feed");
    }
  }, [itinerary, userId]);

  const currencyOptions = [
    { code: "PHP", name: "Philippine Peso", symbol: "₱" },
    { code: "USD", name: "US Dollar", symbol: "$" },
    { code: "EUR", name: "Euro", symbol: "€" },
    { code: "JPY", name: "Japanese Yen", symbol: "¥" },
    { code: "GBP", name: "British Pound", symbol: "£" },
    { code: "AUD", name: "Australian Dollar", symbol: "A$" },
    { code: "CAD", name: "Canadian Dollar", symbol: "C$" },
  ];
  const handlePhotoChange = (event) => {
    const file = event.target.files[0];

    // Validation: Check if a file is selected
    if (!file) {
      alert("No file selected. Please choose an image.");
      return;
    }

    // Validation: Check file type
    const validImageTypes = [
      "image/jpeg",
      "image/png",
      "image/jpg",
      "image/gif",
    ];
    if (!validImageTypes.includes(file.type)) {
      alert("Invalid file type. Please upload a JPEG or PNG image.");
      return;
    }

    // Validation: Check file size (e.g., max 5MB)
    const maxSizeInMB = 5;
    if (file.size > maxSizeInMB * 1024 * 1024) {
      alert(
        `File size exceeds ${maxSizeInMB}MB. Please upload a smaller file.`
      );
      return;
    }

    // Set photo state if validation passes
    setPhoto(file);
  };

  const handleDescriptionChange = (day, index, description) => {
    setPlaces((prevPlaces) => {
      const updatedPlaces = { ...prevPlaces };
      updatedPlaces[day][index] = {
        ...updatedPlaces[day][index],
        description,
      };
      return updatedPlaces;
    });
  };

  const getCurrencySymbol = (currencyCode) => {
    const currency = currencyOptions.find((c) => c.code === currencyCode);
    return currency ? currency.symbol : "";
  };

  // Function to sort places by time
  const sortPlacesByTime = (dayPlaces = []) => {
    const convertTo24Hour = (time) => {
      const [timeString, modifier] = time.split(" ");
      let [hours, minutes] = timeString.split(":").map(Number);

      if (modifier === "PM" && hours !== 12) {
        hours += 12;
      } else if (modifier === "AM" && hours === 12) {
        hours = 0;
      }

      return hours * 60 + minutes; // Return total minutes from midnight
    };

    return [...dayPlaces].sort((a, b) => {
      // Assign "12:00 AM" as default time for places without a time
      const timeA = a.time ? a.time.split(" - ")[0] : "12:00 AM"; // Get start time
      const timeB = b.time ? b.time.split(" - ")[0] : "12:00 AM"; // Get start time

      const timeAMinutes = convertTo24Hour(timeA);
      const timeBMinutes = convertTo24Hour(timeB);

      return timeAMinutes - timeBMinutes;
    });
  };

  const calculateDays = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const days = [];

    while (startDate <= endDate) {
      days.push(
        new Date(startDate).toLocaleDateString("en-US", {
          weekday: "long",
          month: "long",
          day: "numeric",
        })
      );
      startDate.setDate(startDate.getDate() + 1);
    }

    return days;
  };

  useEffect(() => {
    let total = 0;
    Object.keys(places).forEach((day) => {
      total += places[day].reduce(
        (acc, place) => acc + parseFloat(place.cost || 0),
        0
      );
    });
    setTotalExpenses(total);
  }, [places]);

  useEffect(() => {
    if (startDate && endDate) {
      const days = calculateDays(startDate, endDate);
      setPlaces((prevPlaces) => {
        const updatedPlaces = {};

        days.forEach((day, index) => {
          updatedPlaces[`Day ${index + 1}`] =
            prevPlaces[`Day ${index + 1}`] || [];
        });

        return updatedPlaces;
      });
    }
  }, [startDate, endDate]);

  useEffect(() => {
    const geocodePlaces = async () => {
      const newMarkers = [];
      for (const day in places) {
        for (const place of places[day]) {
          try {
            const response = await axios.get(
              "https://maps.googleapis.com/maps/api/geocode/json",
              {
                params: {
                  address: place.name, // Use the place name for geocoding
                  key: googleMapsApiKey,
                },
              }
            );

            if (response.data.status === "OK") {
              const location = response.data.results[0].geometry.location;
              newMarkers.push({
                name: place.name,
                position: {
                  lat: location.lat,
                  lng: location.lng,
                },
              });
            } else {
              console.warn(`Could not geocode place: ${place.name}`);
            }
          } catch (error) {
            console.error(`Error geocoding place ${place.name}:`, error);
          }
        }
      }
      setMarkers(newMarkers);
      console.log("Markers updated:", newMarkers);
    };

    if (places && Object.keys(places).length > 0) {
      geocodePlaces();
    }
  }, [places, googleMapsApiKey]);

  useEffect(() => {
    let isMounted = true;

    const calculateDays = (start, end) => {
      const startDate = new Date(start);
      const endDate = new Date(end);
      const days = [];

      while (startDate <= endDate) {
        days.push(
          new Date(startDate).toLocaleDateString("en-US", {
            weekday: "long",
            month: "long",
            day: "numeric",
          })
        );
        startDate.setDate(startDate.getDate() + 1);
      }

      return days;
    };

    const normalizeDay = (dayValue, startDate) => {
      if (/\d{1,2}\/\d{1,2}\/\d{4}/.test(dayValue)) {
        const date = new Date(dayValue);
        const dayDiff =
          Math.ceil((date - new Date(startDate)) / (1000 * 60 * 60 * 24)) + 1;
        return dayDiff > 0 ? `Day ${dayDiff}` : null;
      }
      return dayValue; // Already in "Day X" format
    };

    const fetchItinerary = async () => {
      try {
        console.log(`Fetching itinerary with ID: ${itinerary_id}`);
        const response = await axios.get(
          `${hostURL}/itinerary/${itinerary_id}`
        );

        if (isMounted) {
          console.log("Fetched Itinerary Data:", response.data);
          setItinerary(response.data);
          setEditTitle(response.data.trip_name);
          setDestination(response.data.destination_city);
          setStartDate(response.data.start_date);
          setEndDate(response.data.end_date);
          setTripdescription(response.data.tripdescription || "");
          setBudget(response.data.budget || 0);

          if (response.data.photo) {
            setPhoto(response.data.photo);
          }

          const placesResponse = await axios.get(
            `${hostURL}/api/places/${itinerary_id}`
          );
          const fetchedPlaces = placesResponse.data;
          console.log("Fetched Places Raw Data:", fetchedPlaces);

          const normalizedPlaces = {};
          fetchedPlaces.forEach((place) => {
            const normalizedDay = normalizeDay(
              place.day,
              response.data.start_date
            );
            if (normalizedDay) {
              if (!normalizedPlaces[normalizedDay])
                normalizedPlaces[normalizedDay] = [];
              normalizedPlaces[normalizedDay].push(place);
            }
          });

          console.log("Normalized Places:", normalizedPlaces);

          const days = calculateDays(
            response.data.start_date,
            response.data.end_date
          );

          const completePlaces = days.reduce((acc, day, index) => {
            const dayKey = `Day ${index + 1}`;
            acc[dayKey] = normalizedPlaces[dayKey] || [];
            return acc;
          }, {});

          console.log("Complete Places:", completePlaces);
          setPlaces(completePlaces);

          const updatedMarkers = fetchedPlaces
            .filter((place) => place.lat && place.lng)
            .map((place) => ({
              name: place.name,
              position: {
                lat: parseFloat(place.lat),
                lng: parseFloat(place.lng),
              },
            }));

          setMarkers(updatedMarkers);
          console.log("Markers set:", updatedMarkers);

          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching itinerary:", error);
        if (isMounted) setLoading(false);
      }
    };

    fetchItinerary();

    return () => {
      isMounted = false;
    };
  }, [itinerary_id]);

  if (loading) {
    return <div>Loading itinerary...</div>;
  }

  if (!itinerary) {
    return (
      <div>
        <LazyLoadImage src={wrong} alt="place" className="viewerrorimage" />
      </div>
    );
  }

  const handleSaveItinerary = async () => {
    try {
      if (!token) {
        console.error("No token found. User not authenticated.");
        alert("You are not logged in. Please log in to save your itinerary.");
        return;
      }

      const formData = new FormData();
      formData.append("trip_name", editTitle.trim() || "");
      formData.append("destination_city", destination.trim() || "");
      formData.append("start_date", startDate || "");
      formData.append("end_date", endDate || "");
      formData.append("tripdescription", tripdescription.trim() || "");
      formData.append("budget", budget); // Include budget in the payload

      if (photo) {
        formData.append("photo", photo);
      }

      // Add places as a JSON string
      formData.append(
        "places",
        JSON.stringify(
          Object.keys(places).flatMap((day) =>
            places[day].map((place, index) => ({
              ...place,
              day,
              description:
                placeDescriptions[`${day}-${index}`] || place.description || "",
            }))
          )
        )
      );

      const response = await axios.put(
        `${hostURL}/itineraries/${itinerary_id}`,
        formData,
        {
          headers: {
            Authorization: token,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Itinerary updated successfully:", response.data);
      navigate("/userprofile");
    } catch (error) {
      console.error(
        "Error updating itinerary:",
        error.response?.data || error.message || error
      );
      alert("Failed to update the itinerary. Please try again.");
    }
  };

  const handleClickPlaceName = (placeName) => {
    const marker = markers.find((marker) => marker.name === placeName);
    if (marker && mapRef.current) {
      mapRef.current.panTo(marker.position);
      mapRef.current.setZoom(14);
    }
  };

  const handleAddPlace = async (day) => {
    if (!newPlace[day]?.trim()) {
      alert("Please enter a valid place name.");
      return;
    }

    let newPlaceData = {
      name: newPlace[day],
      time: "",
      cost: "",
      description: "", // Initialize description as an empty string
      photo: null,
      lat: null,
      lng: null,
      id: null, // No ID yet for local-only places
    };

    try {
      const response = await axios.get(
        "https://maps.googleapis.com/maps/api/geocode/json",
        {
          params: {
            address: newPlace[day],
            key: googleMapsApiKey,
          },
        }
      );

      if (response.data.status === "OK" && response.data.results.length > 0) {
        const location = response.data.results[0].geometry.location;
        newPlaceData = {
          ...newPlaceData,
          lat: location.lat,
          lng: location.lng,
        };

        console.log(`Recognized place added and pinned: ${newPlace[day]}`);

        // Add marker for the recognized place
        setMarkers((prevMarkers) => [
          ...prevMarkers,
          {
            name: newPlace[day],
            position: { lat: location.lat, lng: location.lng },
          },
        ]);
      } else {
        console.warn(
          `Place not recognized by Google Maps API: ${newPlace[day]}`
        );
      }

      // Update the state with the new place
      setPlaces((prevPlaces) => ({
        ...prevPlaces,
        [day]: [...(prevPlaces[day] || []), newPlaceData],
      }));

      // Clear the input field
      setNewPlace((prevNewPlace) => ({ ...prevNewPlace, [day]: "" }));
    } catch (error) {
      console.error("Error fetching coordinates for the place:", error);
      alert("Failed to add the place. It will not have location data.");
      // Add the place without lat/lng if the API call fails
      setPlaces((prevPlaces) => ({
        ...prevPlaces,
        [day]: [...(prevPlaces[day] || []), newPlaceData],
      }));

      // Clear the input field
      setNewPlace((prevNewPlace) => ({ ...prevNewPlace, [day]: "" }));
    }
  };

  // Helper function to normalize day format
  const normalizeDay = (dayValue) => {
    // If day is in "MM/DD/YYYY" format
    if (/\d{1,2}\/\d{1,2}\/\d{4}/.test(dayValue)) {
      const date = new Date(dayValue);
      const start = new Date(startDate);
      const diffInDays = Math.ceil((date - start) / (1000 * 60 * 60 * 24)) + 1; // Calculate day difference
      return `Day ${diffInDays}`;
    }

    // If day is already in "Day X" format, return as is
    return dayValue;
  };

  const handleDeletePlace = async (day, index) => {
    const deletedPlace = places[day][index];

    if (!deletedPlace) {
      alert("Invalid place. Cannot delete.");
      return;
    }

    if (!deletedPlace.id) {
      // For local-only places without an ID, remove them from the state directly
      const updatedPlaces = { ...places };
      updatedPlaces[day].splice(index, 1);

      // Remove the day if no places are left
      if (updatedPlaces[day].length === 0) {
        delete updatedPlaces[day];
      }

      setPlaces(updatedPlaces);

      // Remove the marker if it exists
      setMarkers((prevMarkers) =>
        prevMarkers.filter((marker) => marker.name !== deletedPlace.name)
      );

      console.log(`Local place "${deletedPlace.name}" deleted successfully.`);
      return;
    }

    try {
      // Make a DELETE request for backend-synced places
      await axios.delete(`${hostURL}/api/places/${deletedPlace.id}`);

      // Update the state by removing the place
      const updatedPlaces = { ...places };
      updatedPlaces[day].splice(index, 1);

      // Remove the day if no places are left
      if (updatedPlaces[day].length === 0) {
        delete updatedPlaces[day];
      }

      setPlaces(updatedPlaces);

      // Remove the marker
      setMarkers((prevMarkers) =>
        prevMarkers.filter((marker) => marker.name !== deletedPlace.name)
      );

      console.log(`Place "${deletedPlace.name}" deleted successfully.`);
    } catch (error) {
      console.error("Error deleting place:", error);
      alert("Failed to delete the place. Please try again.");
    }
  };

  const handleEditPlace = () => {
    if (!selectedPlace) return;

    const { day, index } = selectedPlace;

    const updatedPlaces = { ...places };
    updatedPlaces[day][index] = {
      ...updatedPlaces[day][index],
      name: editPlaceName,
    };

    setPlaces(updatedPlaces);
    setShowEditPlaceModal(false);
  };

  const handleAttachPhoto = async (file) => {
    if (!selectedPlace) return;

    // File validation (already present in your code)
    const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];
    const maxFileSize = 25 * 1024 * 1024; // 25 MB in bytes

    if (!validImageTypes.includes(file.type)) {
      alert("Invalid file type. Please upload an image file (JPEG, PNG).");
      return;
    }

    if (file.size > maxFileSize) {
      alert("File size exceeds 25 MB. Please upload a smaller file.");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("photo", file);

      // Upload photo to the server
      const response = await axios.post(`${hostURL}/api/uploadPhoto`, formData);

      if (response.data.photoPath) {
        const { day, index } = selectedPlace;

        // Update state with the file path instead of blob
        setPlaces((prevPlaces) => {
          const updatedPlaces = { ...prevPlaces };
          updatedPlaces[day][index].photo = response.data.photoPath; // Save file path
          return updatedPlaces;
        });

        setShowAttachModal(false); // Close the modal
      } else {
        alert("Failed to upload photo. Please try again.");
      }
    } catch (error) {
      console.error("Error uploading photo:", error);
      alert("Failed to upload photo.");
    }
  };

  const handleTimeSave = () => {
    if (!selectedPlace) return;

    const { day, index } = selectedPlace;

    setPlaces((prevPlaces) => {
      const updatedPlaces = { ...prevPlaces };

      updatedPlaces[day][index] = {
        ...updatedPlaces[day][index],
        time: timeDetails.startTime
          ? `${timeDetails.startTime} - ${timeDetails.endTime || ""}`
          : "",
      };

      updatedPlaces[day] = sortPlacesByTime(updatedPlaces[day]);

      return updatedPlaces;
    });

    setShowTimeModal(false);
  };

  const handleExpenseSave = () => {
    if (!selectedPlace) return;

    const { day, index } = selectedPlace;

    const updatedPlaces = { ...places };
    updatedPlaces[day][index] = {
      ...updatedPlaces[day][index],
      cost: parseFloat(expense) || 0,
      description: description || "",
    };

    setPlaces(updatedPlaces);
    setShowExpenseModal(false);
  };

  const handleBudgetSave = () => {
    if (newBudget !== null && newBudget >= 0 && newBudget <= 10000000) {
      setBudget(newBudget); // Save the updated budget
      setShowBudgetModal(false); // Close the modal
    } else if (newBudget > 10000000) {
      alert("Budget cannot exceed 10 million. Please enter a valid amount."); // Validation error for exceeding maximum
    } else {
      alert("Budget cannot be negative. Please enter a valid amount."); // Validation error for negative values
    }
  };

  const calculateDate = (startDate, dayIndex) => {
    const date = new Date(startDate);
    date.setDate(date.getDate() + dayIndex);
    return date.toLocaleDateString("en-US", {
      weekday: "long",
      month: "long",
      day: "numeric",
    });
  };

  const handleEditTitle = () => {
    setEditTitle(editTitle);
    setShowEditTitleModal(false);
  };

  const mapContainerStyle = {
    width: "100%",
    height: "100vh",
    position: "fixed",
    right: "0",
    top: "0",
    zIndex: "1",
  };

  const center = markers.length
    ? markers[0].position
    : { lat: 37.7749, lng: -122.4194 };
  if (!itinerary) {
    return <div>Loading itinerary...</div>;
  }

  // Export to PDF
  const exportToPDF = () => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.width;
    const margin = 10;
    let y = 20;

    const logoWidth = 40;
    const logoHeight = 25;
    const logoX = (pageWidth - logoWidth) / 2;
    doc.addImage(image, "PNG", logoX, 10, logoWidth, logoHeight);
    y += logoHeight + 10;

    doc.setFontSize(18);
    doc.setFont("helvetica", "bold");
    doc.text("Itinerary Summary", pageWidth / 2, y, { align: "center" });
    y += 10;

    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text(`Title: ${editTitle}`, margin, (y += 10));
    doc.text(`Destination: ${destination}`, margin, (y += 10));
    doc.text(`Start Date: ${startDate}`, margin, (y += 10));
    doc.text(`End Date: ${endDate}`, margin, (y += 10));
    doc.text(`Description: ${tripdescription || "N/A"}`, margin, (y += 10));
    y += 10;

    Object.keys(places).forEach((day, index) => {
      const dayDate = new Date(startDate);
      dayDate.setDate(dayDate.getDate() + index);
      const formattedDate = dayDate.toLocaleDateString("en-US", {
        weekday: "long",
        month: "long",
        day: "numeric",
      });

      doc.setFont("helvetica", "bold");
      doc.setFontSize(14);
      doc.text(`Day ${index + 1} - ${formattedDate}:`, margin, y);
      y += 10;

      const tableData = places[day].map((place) => [
        place.name || "N/A",
        place.description || "N/A",
        place.time || "N/A",
        place.cost ? `${place.cost} ${selectedCurrency}` : "N/A",
      ]);

      doc.autoTable({
        head: [["Place Name", "Description", "Time", "Cost"]],
        body: tableData,
        startY: y,
        styles: { fontSize: 10, cellPadding: 5 },
        headStyles: { fillColor: [40, 116, 240], textColor: [255, 255, 255] },
        theme: "striped",
        margin: { left: margin, right: margin },
        didDrawPage: (data) => {
          y = data.cursor.y + 10;
        },
      });
    });

    doc.save("Itinerary.pdf");
  };

  const exportToExcel = () => {
    const data = [
      {
        Title: editTitle,
        Destination: destination,
        Start: startDate,
        End: endDate,
        Description: tripdescription || "N/A",
      },
      {},
      ...Object.keys(places).flatMap((day, index) => {
        const dayDate = new Date(startDate);
        dayDate.setDate(dayDate.getDate() + index);
        const formattedDate = dayDate.toLocaleDateString("en-US", {
          weekday: "long",
          month: "long",
          day: "numeric",
        });

        return [
          { Day: `Day ${index + 1}`, Date: formattedDate },
          ...places[day].map((place) => ({
            "Place Name": place.name || "N/A",
            Description: place.description || "No Description",
            Time: place.time || "N/A",
            Cost: place.cost ? `${place.cost} ${selectedCurrency}` : "N/A",
          })),
          {},
        ];
      }),
    ];

    const worksheet = XLSX.utils.json_to_sheet(data, { skipHeader: true });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Itinerary");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "Itinerary.xlsx");
  };
  // Export to Word
  const exportToWord = () => {
    let content = `
      <html>
      <head>
        <style>
          body {
            font-family: Arial, sans-serif;
            margin: 20px;
            color: #333;
            line-height: 1.6;
          }
          h1 {
            text-align: center;
            font-size: 28px;
            color: #444;
            margin-bottom: 10px;
          }
          h2 {
            text-align: center;
            font-size: 20px;
            color: #666;
            margin-bottom: 20px;
          }
          p {
            font-size: 16px;
            margin: 5px 0;
          }
          .summary {
            margin-bottom: 30px;
            padding: 10px;
            border: 1px solid #ddd;
            border-radius: 8px;
            background-color: #f9f9f9;
          }
          table {
            width: 100%;
            border-collapse: collapse;
            margin: 20px 0;
            font-size: 14px;
          }
          th, td {
            border: 1px solid #ddd;
            padding: 10px;
            text-align: left;
          }
          th {
            background-color: #2973F0;
            color: white;
            font-weight: bold;
            text-transform: uppercase;
          }
          tr:nth-child(even) {
            background-color: #f9f9f9;
          }
          tr:hover {
            background-color: #f1f1f1;
          }
          .section-header {
            font-size: 18px;
            font-weight: bold;
            color: #444;
            margin-top: 30px;
            margin-bottom: 10px;
          }
        </style>
      </head>
      <body>
        <h1>Itinerary Summary</h1>
        <h2>${editTitle}</h2>
        <div class="summary">
          <p><strong>Destination:</strong> ${destination}</p>
          <p><strong>Start Date:</strong> ${new Date(
      startDate
    ).toLocaleDateString()}</p>
          <p><strong>End Date:</strong> ${new Date(
      endDate
    ).toLocaleDateString()}</p>
          <p><strong>Description:</strong> ${tripdescription || "N/A"}</p>
        </div>
    `;

    // Add days and places in a visually pleasing format
    Object.keys(places).forEach((day, index) => {
      const dayDate = new Date(startDate);
      dayDate.setDate(dayDate.getDate() + index);
      const formattedDate = dayDate.toLocaleDateString("en-US", {
        weekday: "long",
        month: "long",
        day: "numeric",
      });

      // Add section header for each day
      content += `
        <div class="section-header">Day ${index + 1} - ${formattedDate}</div>
        <table>
          <thead>
            <tr>
              <th>Place Name</th>
              <th>Description</th>
              <th>Time</th>
              <th>Cost</th>
            </tr>
          </thead>
          <tbody>
      `;

      // Add each place under the day's section
      places[day].forEach((place) => {
        content += `
          <tr>
            <td>${place.name || "N/A"}</td>
            <td>${place.description || "No Description"}</td>
            <td>${place.time || "N/A"}</td>
            <td>${place.cost ? `${place.cost} ${selectedCurrency}` : "N/A"}</td>
          </tr>
        `;
      });

      content += `
          </tbody>
        </table>
      `;
    });

    // Close HTML structure
    content += `
      </body>
      </html>
    `;

    // Create the Word document and save it
    const blob = new Blob([content], { type: "application/msword" });
    saveAs(blob, "Itinerary.doc");
  };

  return (
    <div className="itinerary-summary-container">
      <div className="itinerary-details">
        <div
          className="trip-header"
          style={{ display: "flex", position: "relative" }}
        >
          {/* Left Section */}
          <div className="trip-header-left" style={{ flex: "1" }}>
            <h1>{editTitle}</h1>
            <p>
              {new Date(startDate).toLocaleDateString()} -{" "}
              {new Date(endDate).toLocaleDateString()}
            </p>
            <textarea
              placeholder="Enter itinerary description (optional)"
              value={tripdescription}
              onChange={(e) => setTripdescription(e.target.value)}
              className="description-textarea"
            />
          </div>

          {/* Right Section */}
          <div
            className="trip-header-right"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              gap: "10px",
            }}
          >
            {/* Photo Upload Feature */}
            <div className="photo-upload">
              <img
                src={
                  photo && typeof photo === "string"
                    ? `${hostURL}/${photo}` // Use the existing photo URL if available
                    : photo
                      ? URL.createObjectURL(photo) // Use the preview URL if a new photo is uploaded
                      : "https://via.placeholder.com/150x100" // Placeholder if no photo exists
                }
                alt="Upload Preview"
                className="photo-preview"
              />

              <label htmlFor="uploadPhotoInput" className="upload-photo-label">
                <input
                  type="file"
                  id="uploadPhotoInput"
                  accept="image/*"
                  onChange={handlePhotoChange}
                  style={{ display: "none" }}
                />
                Change Photo
              </label>
            </div>

            {/* Buttons */}
            <div
              className="header-buttons"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
                marginTop: "10px",
              }}
            >
              <button
                className="control-btn"
                onClick={() => setShowEditTitleModal(true)}
              >
                <FontAwesomeIcon icon={faPen} />
                <span>Edit Itinerary Title</span>
              </button>
              <button className="control-btn" onClick={handleSaveItinerary}>
                <FontAwesomeIcon icon={faSave} />
                <span>Save Itinerary</span>
              </button>
              <div className="export-dropdown">
                <button className="control-btn dropdown-toggle">
                  <FontAwesomeIcon icon={faFileExport} />
                  <span>Export Options</span>
                </button>
                <div className="dropdown-menu">
                  <button onClick={exportToPDF} className="dropdown-item">
                    Export to PDF
                  </button>
                  <button onClick={exportToExcel} className="dropdown-item">
                    Export to Excel
                  </button>
                  <button onClick={exportToWord} className="dropdown-item">
                    Export to Word
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="budget-header">
          <h2 className="budget-title">Budgeting</h2>
          <div className="budget-buttons">
            <button
              className="controll-btn"
              onClick={() => {
                setNewBudget(budget);
                setShowBudgetModal(true);
              }}
            >
              <FontAwesomeIcon icon={faPen} />
              <span>Edit</span>
            </button>
            <button
              className="controll-btn"
              onClick={() => setShowBreakdownModal(true)}
            >
              <FontAwesomeIcon icon={faEye} />
              <span>View Breakdown</span>
            </button>
          </div>
        </div>
        <div className="budget-box">
          <div className="budget-summary">
            <p>
              {getCurrencySymbol(selectedCurrency)}
              {totalExpenses.toLocaleString()}
            </p>
            <p className="budget-total">
              Budget: {getCurrencySymbol(selectedCurrency)}
              {(budget || 0).toLocaleString()}.00
            </p>
          </div>
          <div className="progress-bar">
            <div
              className="filled-bar"
              style={{
                width: `${budget && budget > 0
                  ? Math.min((totalExpenses / budget) * 100, 100)
                  : 0
                  }%`,
                backgroundColor:
                  budget && budget > 0 && totalExpenses > budget
                    ? "#ff4d4f"
                    : "#007bff",
              }}
            ></div>
          </div>
        </div>

        <div className="itinerary">
          {(() => {
            if (!startDate || !endDate) {
              console.error("Start or End Date is missing:", {
                startDate,
                endDate,
              });
              return <p>Unable to load itinerary. Please check the data.</p>;
            }

            const days = [];
            const start = new Date(startDate);
            const end = new Date(endDate);

            // Calculate total number of days
            const totalDays =
              Math.ceil((end - start) / (1000 * 60 * 60 * 24)) + 1;

            console.log("Total Days:", totalDays);

            // Helper function to normalize day format
            const normalizeDay = (dayValue) => {
              if (/\d{1,2}\/\d{1,2}\/\d{4}/.test(dayValue)) {
                const date = new Date(dayValue);
                const dayDiff =
                  Math.ceil((date - start) / (1000 * 60 * 60 * 24)) + 1;
                return `Day ${dayDiff}`;
              }
              return dayValue; // Already in "Day X" format
            };

            // Normalize places
            const normalizedPlaces = {};
            Object.keys(places).forEach((dayKey) => {
              const normalizedDay = normalizeDay(dayKey);
              if (!normalizedPlaces[normalizedDay])
                normalizedPlaces[normalizedDay] = [];
              normalizedPlaces[normalizedDay].push(...places[dayKey]);
            });

            for (let i = 0; i < totalDays; i++) {
              const currentDay = `Day ${i + 1}`;
              const currentDate = new Date(start);
              currentDate.setDate(start.getDate() + i);

              console.log("Processing Day:", currentDay, currentDate);

              if (!normalizedPlaces[currentDay]) {
                console.warn(
                  `No places for ${currentDay}. Initializing empty list.`
                );
                normalizedPlaces[currentDay] = []; // Ensure an empty array exists for the day
              }

              days.push(
                <div key={currentDay} className="day-container">
                  {/* Day Title with Date */}
                  <h3>
                    {currentDay} -{" "}
                    {currentDate.toLocaleDateString("en-US", {
                      weekday: "long",
                      month: "long",
                      day: "numeric",
                    })}
                  </h3>

                  {/* Places List */}
                  {normalizedPlaces[currentDay]?.length > 0 ? (
                    normalizedPlaces[currentDay].map((place, idx) => (
                      <div
                        key={idx}
                        className="place-card-maker"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "20px",
                        }}
                      >
                        <div
                          className="place-content"
                          style={{
                            flex: "1",
                            padding: "25px",
                            border: "1px solid #ccc",
                            borderRadius: "8px",
                          }}
                        >
                          <h4
                            style={{ cursor: "pointer", color: "black" }}
                            onClick={() => handleClickPlaceName(place.name)}
                          >
                            {place.name}
                          </h4>

                          <textarea
                            placeholder="Add notes, links, etc here."
                            value={place.description || ""}
                            onChange={(e) =>
                              handleDescriptionChange(
                                currentDay,
                                idx,
                                e.target.value
                              )
                            }
                            className="description-input"
                            style={{
                              width: "100%",
                              marginTop: "1px",
                              padding: "6px",
                              fontSize: "14px",
                              border: "0px solid #ddd",
                              borderRadius: "5px",
                            }}
                          />
                          <div
                            className="place-controls"
                            style={{
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                              marginTop: "5px",
                            }}
                          >
                            {/* Edit Name Button */}
                            <button
                              className="control-btn"
                              onClick={() => {
                                setSelectedPlace({
                                  day: currentDay,
                                  index: idx,
                                });
                                setShowEditPlaceModal(true);
                                setEditPlaceName(place.name);
                              }}
                            >
                              <FontAwesomeIcon icon={faPen} />
                              <span>Edit Name</span>
                            </button>

                            {/* Add/Edit Time */}
                            <button
                              className="control-btn"
                              onClick={() => {
                                setSelectedPlace({
                                  day: currentDay,
                                  index: idx,
                                });
                                setShowTimeModal(true);
                                setTimeDetails({
                                  startTime: place.time?.split(" - ")[0] || "",
                                  endTime: place.time?.split(" - ")[1] || "",
                                });
                              }}
                              style={{
                                color: place.time ? "#337ab7" : "inherit", // Blue for existing time
                                fontWeight: place.time ? "bold" : "normal",
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              <FontAwesomeIcon icon={faClock} />
                              <span>{place.time || "Add Time"}</span>
                            </button>

                            {/* Add/Edit Cost */}
                            <button
                              className="control-btn"
                              onClick={() => {
                                setSelectedPlace({
                                  day: currentDay,
                                  index: idx,
                                });
                                setShowExpenseModal(true);
                                setExpense(place.cost || "");
                                setDescription(place.description || "");
                              }}
                              style={{
                                color:
                                  place.cost && place.cost > 0
                                    ? "#337ab7"
                                    : "inherit", // Blue only for non-zero cost
                                fontWeight:
                                  place.cost && place.cost > 0
                                    ? "bold"
                                    : "normal", // Bold only for non-zero cost
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              <FontAwesomeIcon icon={faDollarSign} />
                              <span>
                                {place.cost && place.cost > 0 // Only display cost if it's greater than 0
                                  ? `${getCurrencySymbol(
                                    selectedCurrency
                                  )}${place.cost.toLocaleString()}`
                                  : "Add Cost"}{" "}
                                {/* Display "Add Cost" for empty or zero values */}
                              </span>
                            </button>

                            {/* Attach Button */}
                            <button
                              className="control-btn"
                              onClick={() => {
                                setSelectedPlace({
                                  day: currentDay,
                                  index: idx,
                                });
                                setShowAttachModal(true);
                              }}
                            >
                              <FontAwesomeIcon icon={faPaperclip} />
                              <span>Attach</span>
                            </button>

                            {/* Delete Button */}
                            <button
                              className="control-btn delete-btn"
                              onClick={() => handleDeletePlace(currentDay, idx)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                              <span>Delete</span>
                            </button>
                          </div>
                        </div>
                        {place.photo && (
                          <img
                            src={`${hostURL}/${place.photo}`}
                            alt="Place"
                            style={{
                              width: "250px",
                              height: "200px",
                              marginLeft: "20px",
                              borderRadius: "8px",
                              border: "1px solid #ccc",
                            }}
                          />
                        )}
                      </div>
                    ))
                  ) : (
                    <p
                      style={{
                        color: "#888",
                        fontStyle: "italic",
                        padding: "10px",
                      }}
                    >
                      No places added for this day.
                    </p>
                  )}

                  {/* Add Place Section */}
                  <div className="add-place">
                    <div className="add-place-input-container">
                      <FontAwesomeIcon
                        icon={faMapMarkerAlt}
                        className="add-place-icon"
                      />
                      <input
                        type="text"
                        value={newPlace[currentDay] || ""}
                        className="add-place-input"
                        placeholder="Add a place"
                        onChange={(e) =>
                          setNewPlace({
                            ...newPlace,
                            [currentDay]: e.target.value,
                          })
                        }
                      />
                    </div>
                    <button
                      className="add-place-btn"
                      onClick={() => handleAddPlace(currentDay)}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                  </div>
                </div>
              );
            }

            return days;
          })()}
        </div>
      </div>

      <div className="itinerary-map">
        <LoadScript googleMapsApiKey={googleMapsApiKey}>
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={center}
            zoom={10}
            onLoad={(map) => {
              mapRef.current = map;
              if (window.google && window.google.maps) {
                if (markers.length > 0) {
                  const bounds = new window.google.maps.LatLngBounds();
                  markers.forEach((marker) => {
                    if (marker.position) {
                      bounds.extend(marker.position);
                    }
                  });
                  map.fitBounds(bounds);
                }
              } else {
                console.error("Google Maps API is not loaded.");
              }
            }}
          >
            {/* Add markers to the map */}
            {markers.map((marker, idx) => (
              <Marker
                key={idx}
                position={marker.position}
                title={marker.name}
                icon={{
                  url: customMarkerIcon,
                  scaledSize: new window.google.maps.Size(40, 60), // Adjust size as needed
                }}
              />
            ))}
          </GoogleMap>
        </LoadScript>
      </div>

      {/* Modals */}
      {showBudgetModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Edit Budget</h2>
            <input
              type="text"
              value={
                newBudget !== null && newBudget !== undefined
                  ? new Intl.NumberFormat().format(newBudget)
                  : ""
              }
              onChange={(e) => {
                let value = e.target.value.replace(/,/g, ""); // Remove commas for processing

                // Ensure the value is a valid number within the allowed range
                if (!isNaN(value) && value !== "") {
                  const numericValue = parseInt(value, 10);
                  if (numericValue >= 0 && numericValue <= 10000000) {
                    setNewBudget(numericValue); // Update state without commas
                  } else if (numericValue > 10000000) {
                    alert("Budget cannot exceed 10 million."); // Alert for exceeding maximum value
                  } else {
                    alert("Budget cannot be negative."); // Alert for negative values
                  }
                } else if (value === "") {
                  setNewBudget(null); // Clear input
                }
              }}
              placeholder="Enter New Budget"
              style={{
                width: "100%",
                padding: "10px",
                fontSize: "16px",
                border: "1px solid #ccc",
                borderRadius: "6px",
                marginBottom: "10px",
              }}
            />
            <select
              value={selectedCurrency}
              onChange={(e) => setSelectedCurrency(e.target.value)}
              style={{
                width: "100%",
                padding: "10px",
                fontSize: "16px",
                border: "1px solid #ccc",
                borderRadius: "6px",
                marginBottom: "20px",
              }}
            >
              {currencyOptions.map((currency) => (
                <option key={currency.code} value={currency.code}>
                  {currency.name}
                </option>
              ))}
            </select>
            <div className="modal-buttons">
              <button
                onClick={() => setShowBudgetModal(false)}
                style={{
                  backgroundColor: "#e0e0e0",
                  color: "#000",
                  border: "none",
                  padding: "8px 20px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
              >
                Cancel
              </button>
              <button
                onClick={handleBudgetSave}
                style={{
                  backgroundColor: "#2979ff",
                  color: "#fff",
                  border: "none",
                  padding: "8px 20px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Add Time Modal */}
      {showTimeModal && (
        <div className="modal">
          <div className="modal-content">
            <h2 style={{ textAlign: "center", marginBottom: "15px" }}>
              Set Time
            </h2>
            <div
              className="time-inputs"
              style={{ display: "flex", alignItems: "center", gap: "20px" }}
            >
              {/* Start Time */}
              <div style={{ flex: 1 }}>
                <label
                  htmlFor="start-time"
                  style={{
                    display: "block",
                    fontWeight: "bold",
                    fontSize: "14px",
                    marginBottom: "8px",
                  }}
                >
                  Start Time
                </label>
                <select
                  id="start-time"
                  value={timeDetails.startTime}
                  onChange={(e) =>
                    setTimeDetails({
                      ...timeDetails,
                      startTime: e.target.value,
                    })
                  }
                  style={{
                    width: "100%",
                    padding: "20px",
                    borderRadius: "6px",
                    border: "1px solid #ccc",
                    fontSize: "14px",
                    maxHeight: "150px",
                    overflowY: "auto",
                  }}
                >
                  {Array.from({ length: 24 * 2 }, (_, i) => {
                    const hour = Math.floor(i / 2);
                    const minute = i % 2 === 0 ? "00" : "30";
                    const ampm = hour < 12 ? "AM" : "PM";
                    const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
                    const timeString = `${formattedHour}:${minute} ${ampm}`;
                    return (
                      <option key={timeString} value={timeString}>
                        {timeString}
                      </option>
                    );
                  })}
                </select>
              </div>

              {/* Separator */}
              <span style={{ fontSize: "18px", fontWeight: "bold" }}>—</span>

              {/* End Time */}
              <div style={{ flex: 1 }}>
                <label
                  htmlFor="end-time"
                  style={{
                    display: "block",
                    fontWeight: "bold",
                    fontSize: "14px",
                    marginBottom: "8px",
                  }}
                >
                  End Time
                </label>
                <select
                  id="end-time"
                  value={timeDetails.endTime}
                  onChange={(e) =>
                    setTimeDetails({ ...timeDetails, endTime: e.target.value })
                  }
                  style={{
                    width: "100%",
                    padding: "20px",
                    borderRadius: "6px",
                    border: "1px solid #ccc",
                    fontSize: "14px",
                    maxHeight: "150px",
                    overflowY: "auto",
                  }}
                >
                  {Array.from({ length: 24 * 2 }, (_, i) => {
                    const hour = Math.floor(i / 2);
                    const minute = i % 2 === 0 ? "00" : "30";
                    const ampm = hour < 12 ? "AM" : "PM";
                    const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
                    const timeString = `${formattedHour}:${minute} ${ampm}`;
                    return (
                      <option key={timeString} value={timeString}>
                        {timeString}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            {/* Buttons */}
            <div
              className="modal-buttons"
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <button
                onClick={() => setShowTimeModal(false)}
                style={{
                  backgroundColor: "#e0e0e0",
                  color: "#000",
                  border: "none",
                  padding: "8px 20px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  const { startTime, endTime } = timeDetails;

                  const convertTo24Hour = (time) => {
                    const [timeString, modifier] = time.split(" ");
                    let [hours, minutes] = timeString.split(":").map(Number);

                    if (modifier === "PM" && hours !== 12) {
                      hours += 12;
                    } else if (modifier === "AM" && hours === 12) {
                      hours = 0;
                    }

                    return hours * 60 + minutes;
                  };

                  if (startTime && endTime) {
                    const startTimeMinutes = convertTo24Hour(startTime);
                    const endTimeMinutes = convertTo24Hour(endTime);

                    if (endTimeMinutes < startTimeMinutes) {
                      alert("End time cannot be earlier than start time");
                    } else {
                      handleTimeSave();
                    }
                  }
                }}
                style={{
                  backgroundColor: "#2979ff",
                  color: "#fff",
                  border: "none",
                  padding: "8px 20px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Add Expense Modal */}
      {showExpenseModal && (
        <div className="modal">
          <div className="modal-content">
            <h2 style={{ textAlign: "center", marginBottom: "15px" }}>
              Add Expense
            </h2>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  padding: "8px",
                  width: "100%",
                  maxWidth: "300px",
                }}
              >
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "18px",
                    marginRight: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {getCurrencySymbol(selectedCurrency)}
                </span>
                <input
                  type="number"
                  value={expense}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value);
                    if (
                      (value >= 0 && value <= 10000000) ||
                      e.target.value === ""
                    ) {
                      setExpense(e.target.value); // Only allow values between 0 and 10,000,000 or empty input
                    } else if (value > 10000000) {
                      alert(
                        "Expense cannot exceed 10 million. Please enter a valid amount."
                      );
                    }
                  }}
                  placeholder="0.00"
                  style={{
                    flex: "1",
                    border: "none",
                    outline: "none",
                    fontSize: "18px",
                  }}
                />
              </div>
              <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
                <button
                  onClick={() => setShowExpenseModal(false)}
                  style={{
                    backgroundColor: "#e0e0e0",
                    color: "#000",
                    border: "none",
                    padding: "8px 20px",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    if (
                      expense === "" ||
                      (parseFloat(expense) >= 0 &&
                        parseFloat(expense) <= 10000000)
                    ) {
                      handleExpenseSave(); // Save the expense only if it's valid
                    } else if (parseFloat(expense) > 10000000) {
                      alert(
                        "Expense cannot exceed 10 million. Please enter a valid amount."
                      );
                    } else {
                      alert(
                        "Expense cannot be negative. Please enter a valid amount."
                      );
                    }
                  }}
                  style={{
                    backgroundColor: "#2979ff",
                    color: "#fff",
                    border: "none",
                    padding: "8px 20px",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Attach Photo Modal */}
      {showAttachModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Attach Photo</h2>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleAttachPhoto(e.target.files[0])}
            />
            <div className="modal-buttons">
              <button onClick={() => setShowAttachModal(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}

      {/* View Breakdown Modal */}
      {showBreakdownModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Budget Breakdown</h2>
            <p>
              <strong>Total Budget:</strong>{" "}
              {getCurrencySymbol(selectedCurrency)}
              {budget.toLocaleString()}
            </p>
            <p>
              <strong>Total Expenses:</strong>{" "}
              {getCurrencySymbol(selectedCurrency)}
              {totalExpenses.toLocaleString()}
            </p>
            <ul>
              {Object.keys(places).map((day) =>
                places[day].map((place, idx) =>
                  place.cost ? (
                    <li key={`${day}-${idx}`}>
                      {place.name}: {getCurrencySymbol(selectedCurrency)}
                      {place.cost.toLocaleString()}
                    </li>
                  ) : null
                )
              )}
            </ul>
            <div className="modal-buttons">
              <button onClick={() => setShowBreakdownModal(false)}>
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Edit Place Modal */}
      {showEditPlaceModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Edit Place Name</h2>
            <input
              type="text"
              value={editPlaceName}
              onChange={(e) => setEditPlaceName(e.target.value)}
              placeholder="Enter Place Name"
            />
            <div className="modal-buttons">
              <button onClick={() => setShowEditPlaceModal(false)}>
                Cancel
              </button>
              <button onClick={handleEditPlace}>Save</button>
            </div>
          </div>
        </div>
      )}

      {/* Edit Title Modal */}
      {showEditTitleModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Edit Itinerary Title</h2>
            <input
              type="text"
              value={editTitle}
              onChange={(e) => setEditTitle(e.target.value)}
              placeholder="Itinerary Title"
            />
            <div className="modal-buttons">
              <button onClick={() => setShowEditTitleModal(false)}>
                Cancel
              </button>
              <button onClick={handleEditTitle}>Save</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default EditItinerary;
