//texts
import bg1 from "./1.png";
import bg2 from "./2.png";
import bg3 from "./3.png";
import bg4 from "./4.png";
import bg5 from "./5.png";
import bg6 from "./6.png";
import bg7 from "./7.png";
import bg8 from "./8.png";
//bgs

export const bgs = [bg1, bg2, bg3, bg4, bg5, bg6, bg7, bg8];
