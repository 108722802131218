import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import "./generatedItinerary.css";
import jsPDF from "jspdf";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import image from "./assets/logo.png";
import customMarkerIcon from "./assets/custom-marker.png";
import "jspdf-autotable";
import hostURL from "./config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faEye,
  faClock,
  faSave,
  faFileExport,
  faDollarSign,
  faPaperclip,
  faTrash,
  faPlus,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";

function GeneratedItinerary() {
  const location = useLocation();
  const navigate = useNavigate();

  const { destination, startDate, endDate, itinerary } = location.state || {};

  const [places, setPlaces] = useState({});
  const [newPlace, setNewPlace] = useState({});
  const [budget, setBudget] = useState(20000);
  const [totalExpenses, setTotalExpenses] = useState(0);

  const [showBudgetModal, setShowBudgetModal] = useState(false);
  const [showBreakdownModal, setShowBreakdownModal] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [showTimeModal, setShowTimeModal] = useState(false);
  const [tripdescription, setTripdescription] = useState("");
  const [showExpenseModal, setShowExpenseModal] = useState(false);
  const [showAttachModal, setShowAttachModal] = useState(false);
  const [showEditPlaceModal, setShowEditPlaceModal] = useState(false);
  const [showEditTitleModal, setShowEditTitleModal] = useState(false);
  const [activeMarker, setActiveMarker] = useState(null);
  const [mapCenter, setMapCenter] = useState({ lat: 13.4125, lng: 103.8667 });

  const [placeDescriptions, setPlaceDescriptions] = useState({});
  const [photo, setPhoto] = useState(null);

  const [timeDetails, setTimeDetails] = useState({
    startTime: "",
    endTime: "",
  });
  const [expense, setExpense] = useState("");
  const [description, setDescription] = useState("");
  const [editPlaceName, setEditPlaceName] = useState("");
  const [editTitle, setEditTitle] = useState(destination);

  const [newBudget, setNewBudget] = useState(budget);
  const [selectedCurrency, setSelectedCurrency] = useState("PHP");
  const [markers, setMarkers] = useState([]);
  const mapRef = useRef(null); // Reference to the map
  const googleMapsApiKey = "AIzaSyCWQ3bmZoMMu6dkQ9TxUx8ln5lLQdNk_Do";

  const currencyOptions = [
    { code: "PHP", name: "Philippine Peso", symbol: "₱" },
    { code: "USD", name: "US Dollar", symbol: "$" },
    { code: "EUR", name: "Euro", symbol: "€" },
    { code: "JPY", name: "Japanese Yen", symbol: "¥" },
    { code: "GBP", name: "British Pound", symbol: "£" },
    { code: "AUD", name: "Australian Dollar", symbol: "A$" },
    { code: "CAD", name: "Canadian Dollar", symbol: "C$" },
    // Add more currencies as needed
  ];
  const handlePhotoChange = (event) => {
    const file = event.target.files[0];

    // Validation: Check if a file is selected
    if (!file) {
      alert("No file selected. Please choose an image.");
      return;
    }

    // Validation: Check file type
    const validImageTypes = [
      "image/jpeg",
      "image/png",
      "image/jpg",
      "image/gif",
    ];
    if (!validImageTypes.includes(file.type)) {
      alert("Invalid file type. Please upload a JPEG or PNG image.");
      return;
    }

    // Validation: Check file size (e.g., max 5MB)
    const maxSizeInMB = 5;
    if (file.size > maxSizeInMB * 1024 * 1024) {
      alert(
        `File size exceeds ${maxSizeInMB}MB. Please upload a smaller file.`
      );
      return;
    }

    // Set photo state if validation passes
    setPhoto(file); // Instead of just setting to preview, save the actual file object
  };

  const handleDescriptionChange = (day, index, description) => {
    setPlaces((prevPlaces) => {
      const updatedPlaces = { ...prevPlaces };
      updatedPlaces[day][index] = {
        ...updatedPlaces[day][index],
        description,
      };
      return updatedPlaces;
    });
  };

  const getCurrencySymbol = (currencyCode) => {
    const currency = currencyOptions.find((c) => c.code === currencyCode);
    return currency ? currency.symbol : "";
  };

  // Function to sort places by time
  const sortPlacesByTime = (dayPlaces = []) => {
    const convertTo24Hour = (time) => {
      const [timeString, modifier] = time.split(" ");
      let [hours, minutes] = timeString.split(":").map(Number);

      if (modifier === "PM" && hours !== 12) {
        hours += 12;
      } else if (modifier === "AM" && hours === 12) {
        hours = 0;
      }

      return hours * 60 + minutes; // Return total minutes from midnight
    };

    return [...dayPlaces].sort((a, b) => {
      // Assign "12:00 AM" as default time for places without a time
      const timeA = a.time ? a.time.split(" - ")[0] : "12:00 AM"; // Get start time
      const timeB = b.time ? b.time.split(" - ")[0] : "12:00 AM"; // Get start time

      const timeAMinutes = convertTo24Hour(timeA);
      const timeBMinutes = convertTo24Hour(timeB);

      return timeAMinutes - timeBMinutes;
    });
  };

  useEffect(() => {
    if (!itinerary || !destination || !startDate || !endDate) {
      navigate("/");
      return;
    }

    const initialPlaces = {};
    const initialNewPlace = {};
    const newMarkers = [];

    const fetchCoordinates = async (placeName) => {
      try {
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json`,
          {
            params: {
              address: placeName,
              key: googleMapsApiKey,
            },
          }
        );
        if (response.data.status === "OK") {
          const location = response.data.results[0].geometry.location;
          return { lat: location.lat, lng: location.lng };
        } else if (response.data.status === "ZERO_RESULTS") {
          console.warn(`Geocoding API error: ZERO_RESULTS for ${placeName}`);
          return null;
        } else {
          console.error(`Geocoding API error: ${response.data.status}`);
          return null;
        }
      } catch (error) {
        console.error(`Error fetching coordinates for ${placeName}:`, error);
        return null;
      }
    };

    const fetchAllCoordinates = async () => {
      for (const day of Object.keys(itinerary)) {
        const dayData = itinerary[day];
        const dayPlaces = [
          ...dayData.attractions.map((attraction) => ({
            name: attraction,
            time: "",
            cost: "",
            description: "Attraction", // Add description property
            photo: null,
          })),
          ...dayData.activities.map((activity) => ({
            name: activity,
            time: "",
            cost: "",
            description: "Activity", // Add description property
            photo: null,
          })),
          {
            name: `Breakfast: ${dayData.restaurants.breakfast.join(", ")}`,
            description: "Food Recommendation",
            time: "",
            cost: "",
            photo: null,
          },
          {
            name: `Lunch: ${dayData.restaurants.lunch.join(", ")}`,
            description: "Food Recommendation",
            time: "",
            cost: "",
            photo: null,
          },
          {
            name: `Dinner: ${dayData.restaurants.dinner.join(", ")}`,
            description: "Food Recommendation",
            time: "",
            cost: "",
            photo: null,
          },
          {
            name: dayData.transportation,
            description: "Transport Recommendation",
            time: "",
            cost: "",
            photo: null,
          },
        ];

        initialPlaces[day] = dayPlaces;
        initialNewPlace[day] = "";

        // Fetch coordinates for each place in the day
        for (const place of dayPlaces) {
          const coordinates = await fetchCoordinates(place.name);
          if (coordinates) {
            newMarkers.push({
              name: place.name,
              position: coordinates,
            });
          }
        }
      }

      setMarkers(newMarkers);
    };

    fetchAllCoordinates().then(() => {
      // Update state after fetching coordinates
      setPlaces(() => {
        const sortedPlaces = {};
        Object.keys(initialPlaces).forEach((day) => {
          sortedPlaces[day] = sortPlacesByTime(initialPlaces[day]);
        });
        return sortedPlaces;
      });

      setNewPlace(initialNewPlace);
    });
  }, [itinerary, destination, startDate, endDate, navigate, googleMapsApiKey]);

  useEffect(() => {
    const calculateTotalExpenses = () => {
      let total = 0;
      Object.keys(places).forEach((day) => {
        total += places[day].reduce(
          (acc, place) => acc + (parseFloat(place.cost) || 0), // Ensure `cost` is numeric
          0
        );
      });
      setTotalExpenses(total); // Update the total expenses
    };

    calculateTotalExpenses();
  }, [places]); // Recalculate whenever `places` changes

  const handleSaveItinerary = async () => {
    try {
      const token = localStorage.getItem("userToken");
      if (!token) {
        console.error("No token found. User not authenticated.");
        alert("You are not logged in. Please log in to save your itinerary.");
        return;
      }

      const userResponse = await axios.get(`${hostURL}/user`, {
        headers: {
          Authorization: token,
        },
      });

      const userId = userResponse.data.id;

      const formData = new FormData();
      formData.append("userId", userId);
      formData.append("name", editTitle);
      formData.append("destination", destination);
      formData.append("startDate", startDate);
      formData.append("endDate", endDate);
      formData.append("tripdescription", tripdescription || "");
      formData.append("budget", budget); // Include the budget
      formData.append("selectedCurrency", selectedCurrency); // Include selected currency

      if (photo) {
        formData.append("photo", photo);
      }

      // Add places as a JSON string including descriptions
      formData.append(
        "places",
        JSON.stringify(
          Object.keys(places).flatMap((day) =>
            places[day].map((place, index) => ({
              ...place,
              day,
              description:
                placeDescriptions[`${day}-${index}`] || place.description || "", // Include description from placeDescriptions or default
            }))
          )
        )
      );

      const response = await axios.post(`${hostURL}/saveItinerary`, formData, {
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("Itinerary saved successfully:", response.data);
      navigate("/userprofile");
    } catch (error) {
      console.error(
        "Error saving itinerary:",
        error.response || error.message || error
      );
      alert("Failed to save the itinerary. Please try again.");
    }
  };

  const handleClickPlaceName = (placeName) => {
    const marker = markers.find((marker) => marker.name === placeName);
    if (marker && mapRef.current) {
      mapRef.current.panTo(marker.position);
      mapRef.current.setZoom(14);
    }
  };

  const handleAddPlace = async (day) => {
    if (newPlace[day].trim() !== "") {
      try {
        const response = await axios.get(
          "https://maps.googleapis.com/maps/api/geocode/json",
          {
            params: {
              address: newPlace[day],
              key: googleMapsApiKey,
            },
          }
        );

        let newPlaceData = {
          name: newPlace[day],
          time: "",
          cost: "",
          description: "", // Initialize description as an empty string
          photo: null,
        };

        if (response.data.status === "OK" && response.data.results.length > 0) {
          const location = response.data.results[0].geometry.location;

          // Add latitude and longitude if recognizable
          newPlaceData = {
            ...newPlaceData,
            lat: location.lat,
            lng: location.lng,
          };

          // Add a new marker to the map
          setMarkers((prevMarkers) => [
            ...prevMarkers,
            {
              name: newPlace[day],
              position: {
                lat: location.lat,
                lng: location.lng,
              },
            },
          ]);

          console.log(`Recognized place added and pinned: ${newPlace[day]}`);
        } else {
          console.warn(
            `Place not recognized by Google Maps API: ${newPlace[day]}`
          );
        }

        // Add new place to the state regardless of recognition
        setPlaces((prevPlaces) => ({
          ...prevPlaces,
          [day]: [...prevPlaces[day], newPlaceData],
        }));

        // Clear the input field after adding
        setNewPlace((prevNewPlace) => ({ ...prevNewPlace, [day]: "" }));
      } catch (error) {
        console.error(
          `Error fetching coordinates for ${newPlace[day]}:`,
          error
        );

        // Add the place without lat/lng on API error
        setPlaces((prevPlaces) => ({
          ...prevPlaces,
          [day]: [
            ...prevPlaces[day],
            {
              name: newPlace[day],
              time: "",
              cost: "",
              description: "", // Initialize description as an empty string
              photo: null,
            },
          ],
        }));

        // Clear the input field after adding
        setNewPlace((prevNewPlace) => ({ ...prevNewPlace, [day]: "" }));
      }
    }
  };

  const handleDeletePlace = (day, index) => {
    const updatedPlaces = { ...places };
    const deletedPlace = updatedPlaces[day].splice(index, 1)[0]; // Get the deleted place

    // Update places state
    setPlaces(updatedPlaces);

    // Update markers by filtering out the marker for the deleted place
    setMarkers((prevMarkers) =>
      prevMarkers.filter((marker) => marker.name !== deletedPlace.name)
    );
  };

  const handleEditPlace = () => {
    if (!selectedPlace) return;

    const { day, index } = selectedPlace;

    const updatedPlaces = { ...places };
    updatedPlaces[day][index] = {
      ...updatedPlaces[day][index],
      name: editPlaceName,
    };

    setPlaces(updatedPlaces);
    setShowEditPlaceModal(false);
  };

  const handleAttachPhoto = async (file) => {
    if (!selectedPlace) return;

    // File validation (already present in your code)
    const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];
    const maxFileSize = 25 * 1024 * 1024; // 25 MB in bytes

    if (!validImageTypes.includes(file.type)) {
      alert("Invalid file type. Please upload an image file (JPEG, PNG).");
      return;
    }

    if (file.size > maxFileSize) {
      alert("File size exceeds 25 MB. Please upload a smaller file.");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("photo", file);

      // Upload photo to the server
      const response = await axios.post(`${hostURL}/api/uploadPhoto`, formData);

      if (response.data.photoPath) {
        const { day, index } = selectedPlace;

        // Update state with the file path instead of blob
        setPlaces((prevPlaces) => {
          const updatedPlaces = { ...prevPlaces };
          updatedPlaces[day][index].photo = response.data.photoPath; // Save file path
          return updatedPlaces;
        });

        setShowAttachModal(false); // Close the modal
      } else {
        alert("Failed to upload photo. Please try again.");
      }
    } catch (error) {
      console.error("Error uploading photo:", error);
      alert("Failed to upload photo.");
    }
  };

  const handleTimeSave = () => {
    if (!selectedPlace) return;

    const { day, index } = selectedPlace;

    setPlaces((prevPlaces) => {
      const updatedPlaces = { ...prevPlaces };

      // Update the time for the selected place
      updatedPlaces[day][index] = {
        ...updatedPlaces[day][index],
        time: timeDetails.startTime
          ? `${timeDetails.startTime} - ${timeDetails.endTime || ""}`
          : "",
      };

      // Sort places after updating time
      updatedPlaces[day] = sortPlacesByTime(updatedPlaces[day]);

      return updatedPlaces;
    });

    setShowTimeModal(false);
  };

  const handleExpenseSave = () => {
    if (!selectedPlace) return;

    const { day, index } = selectedPlace;

    setPlaces((prevPlaces) => {
      const updatedPlaces = { ...prevPlaces };
      updatedPlaces[day][index] = {
        ...updatedPlaces[day][index],
        cost: parseFloat(expense) || 0, // Ensure cost is saved as a number
        description: description || "",
      };

      return updatedPlaces; // Trigger re-render
    });

    setShowExpenseModal(false);
  };

  const handleBudgetSave = () => {
    if (newBudget !== null && newBudget >= 0 && newBudget <= 10000000) {
      setBudget(newBudget); // Save the updated budget
      setShowBudgetModal(false); // Close the modal
    } else if (newBudget > 10000000) {
      alert("Budget cannot exceed 10 million. Please enter a valid amount."); // Validation error for exceeding maximum
    } else {
      alert("Budget cannot be negative. Please enter a valid amount."); // Validation error for negative values
    }
  };

  const calculateDate = (startDate, dayIndex) => {
    const date = new Date(startDate);
    date.setDate(date.getDate() + dayIndex);
    return date.toLocaleDateString("en-US", {
      weekday: "long",
      month: "long",
      day: "numeric",
    });
  };
  const handleMarkerClick = (marker, idx) => {
    setActiveMarker(idx);
    setMapCenter({ lat: marker.lat, lng: marker.lng });
  };

  const handleEditTitle = () => {
    setEditTitle(editTitle);
    setShowEditTitleModal(false);
  };

  const mapContainerStyle = {
    width: "100%",
    height: "100vh",
    position: "fixed",
    right: "0",
    top: "0",
    zIndex: "1",
  };

  const center = markers.length
    ? markers[0].position
    : { lat: 37.7749, lng: -122.4194 };

  if (!itinerary) {
    return <div>Loading itinerary...</div>;
  }

  // Export to PDF
  const exportToPDF = () => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.width;
    const margin = 10;
    let y = 20;

    // Add Logo
    const logoWidth = 40;
    const logoHeight = 25;
    const logoX = (pageWidth - logoWidth) / 2;
    doc.addImage(image, "PNG", logoX, 10, logoWidth, logoHeight);
    y += logoHeight + 10;

    // Add Title
    doc.setFontSize(18);
    doc.setFont("helvetica", "bold");
    doc.text("Itinerary Summary", pageWidth / 2, y, { align: "center" });
    y += 10;

    // Add Itinerary Details
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text(`Title: ${editTitle}`, margin, (y += 10));
    doc.text(`Destination: ${destination}`, margin, (y += 10));
    doc.text(`Start Date: ${startDate}`, margin, (y += 10));
    doc.text(`End Date: ${endDate}`, margin, (y += 10));
    doc.text(`Description: ${tripdescription || "N/A"}`, margin, (y += 10));
    y += 10;

    // Iterate through Days
    Object.keys(places).forEach((day, index) => {
      // Calculate Day's Date
      const dayDate = new Date(startDate);
      dayDate.setDate(dayDate.getDate() + index);
      const formattedDate = dayDate.toLocaleDateString("en-US", {
        weekday: "long",
        month: "long",
        day: "numeric",
      });

      // Add Day Header
      doc.setFont("helvetica", "bold");
      doc.setFontSize(14);
      doc.text(`Day ${index + 1} - ${formattedDate}:`, margin, y);
      y += 10;

      // Prepare Table Data
      const tableData = places[day].map((place) => [
        place.name || "N/A",
        place.description || "N/A",
        place.time || "N/A",
        place.cost ? `${place.cost} ${selectedCurrency}` : "N/A",
      ]);

      // Add Table
      doc.autoTable({
        head: [["Place Name", "Description", "Time", "Cost"]],
        body: tableData,
        startY: y,
        styles: { fontSize: 10, cellPadding: 5 },
        headStyles: { fillColor: [40, 116, 240], textColor: [255, 255, 255] },
        theme: "striped",
        margin: { left: margin, right: margin },
        didDrawPage: (data) => {
          y = data.cursor.y + 10; // Update y position for the next content
        },
      });
    });

    // Save the PDF
    doc.save("Itinerary.pdf");
  };

  // Export to Excel
  const exportToExcel = () => {
    // Prepare data with headers
    const data = [
      {
        Title: editTitle,
        Destination: destination,
        Start: startDate,
        End: endDate,
        Description: tripdescription || "N/A",
      },
      {},
      ...Object.keys(places).flatMap((day, index) => {
        const dayDate = new Date(startDate);
        dayDate.setDate(dayDate.getDate() + index);
        const formattedDate = dayDate.toLocaleDateString("en-US", {
          weekday: "long",
          month: "long",
          day: "numeric",
        });

        return [
          { Day: `Day ${index + 1}`, Date: formattedDate }, // Add Day Header
          ...places[day].map((place) => ({
            "Place Name": place.name || "N/A",
            Description: place.description || "No Description",
            Time: place.time || "N/A",
            Cost: place.cost ? `${place.cost} ${selectedCurrency}` : "N/A",
          })),
          {}, // Add spacing between days
        ];
      }),
    ];

    // Create worksheet
    const worksheet = XLSX.utils.json_to_sheet(data, { skipHeader: true });

    // Create workbook and save
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Itinerary");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "Itinerary.xlsx");
  };

  // Export to Word
  const exportToWord = () => {
    let content = `
      <html>
      <head>
        <style>
          body {
            font-family: Arial, sans-serif;
            margin: 20px;
            color: #333;
            line-height: 1.6;
          }
          h1 {
            text-align: center;
            font-size: 28px;
            color: #444;
            margin-bottom: 10px;
          }
          h2 {
            text-align: center;
            font-size: 20px;
            color: #666;
            margin-bottom: 20px;
          }
          p {
            font-size: 16px;
            margin: 5px 0;
          }
          .summary {
            margin-bottom: 30px;
            padding: 10px;
            border: 1px solid #ddd;
            border-radius: 8px;
            background-color: #f9f9f9;
          }
          table {
            width: 100%;
            border-collapse: collapse;
            margin: 20px 0;
            font-size: 14px;
          }
          th, td {
            border: 1px solid #ddd;
            padding: 10px;
            text-align: left;
          }
          th {
            background-color: #2973F0; /* Match PDF header color */
            color: white;
            font-weight: bold;
            text-transform: uppercase;
          }
          tr:nth-child(even) {
            background-color: #f9f9f9;
          }
          tr:hover {
            background-color: #f1f1f1;
          }
          .section-header {
            font-size: 18px;
            font-weight: bold;
            color: #444; /* Match section header styling */
            margin-top: 30px;
            margin-bottom: 10px;
          }
        </style>
      </head>
      <body>
        <h1>Itinerary Summary</h1>
        <h2>${editTitle}</h2>
        <div class="summary">
          <p><strong>Destination:</strong> ${destination}</p>
          <p><strong>Start Date:</strong> ${new Date(
            startDate
          ).toLocaleDateString()}</p>
          <p><strong>End Date:</strong> ${new Date(
            endDate
          ).toLocaleDateString()}</p>
          <p><strong>Description:</strong> ${tripdescription || "N/A"}</p>
        </div>
    `;

    // Add days and places in a visually pleasing format
    Object.keys(places).forEach((day, index) => {
      const dayDate = new Date(startDate);
      dayDate.setDate(dayDate.getDate() + index);
      const formattedDate = dayDate.toLocaleDateString("en-US", {
        weekday: "long",
        month: "long",
        day: "numeric",
      });

      // Add section header for each day
      content += `
        <div class="section-header">Day ${index + 1} - ${formattedDate}</div>
        <table>
          <thead>
            <tr>
              <th>Place Name</th>
              <th>Description</th>
              <th>Time</th>
              <th>Cost</th>
            </tr>
          </thead>
          <tbody>
      `;

      // Add each place under the day's section
      places[day].forEach((place) => {
        content += `
          <tr>
            <td>${place.name || "N/A"}</td>
            <td>${place.description || "No Description"}</td>
            <td>${place.time || "N/A"}</td>
            <td>${place.cost ? `${place.cost} ${selectedCurrency}` : "N/A"}</td>
          </tr>
        `;
      });

      content += `
          </tbody>
        </table>
      `;
    });

    // Close HTML structure
    content += `
      </body>
      </html>
    `;

    // Create the Word document and save it
    const blob = new Blob([content], { type: "application/msword" });
    saveAs(blob, "Itinerary.doc");
  };

  return (
    <div className="itinerary-summary-container">
      <div className="itinerary-details">
        <div
          className="trip-header"
          style={{ display: "flex", position: "relative" }}
        >
          {/* Left Section */}
          <div className="trip-header-left" style={{ flex: "1" }}>
            <h1>{editTitle}</h1>
            <p>
              {new Date(startDate).toLocaleDateString()} -{" "}
              {new Date(endDate).toLocaleDateString()}
            </p>
            <textarea
              placeholder="Enter itinerary description (optional)"
              value={tripdescription}
              onChange={(e) => setTripdescription(e.target.value)}
              className="description-textarea"
            />
          </div>
          {/* Right Section */}
          <div
            className="trip-header-right"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              gap: "10px",
            }}
          >
            {/* Photo Upload Feature */}
            <div className="photo-upload">
              <img
                src={
                  photo
                    ? URL.createObjectURL(photo)
                    : "https://via.placeholder.com/150x100"
                }
                alt="Upload Preview"
                className="photo-preview"
              />
              <label htmlFor="uploadPhotoInput" className="upload-photo-label">
                <input
                  type="file"
                  id="uploadPhotoInput"
                  accept="image/*"
                  onChange={handlePhotoChange}
                  style={{ display: "none" }}
                />
                Change Photo
              </label>
            </div>

            {/* Buttons */}
            <div
              className="header-buttons"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
                marginTop: "10px",
              }}
            >
              <button
                className="control-btn"
                onClick={() => setShowEditTitleModal(true)}
              >
                <FontAwesomeIcon icon={faPen} />
                <span>Edit Itinerary Title</span>
              </button>
              <button className="control-btn" onClick={handleSaveItinerary}>
                <FontAwesomeIcon icon={faSave} />
                <span>Save as Draft</span>
              </button>
              <div className="export-dropdown">
                <button className="control-btn dropdown-toggle">
                  <FontAwesomeIcon icon={faFileExport} />
                  <span>Export Options</span>
                </button>
                <div className="dropdown-menu">
                  <button onClick={exportToPDF} className="dropdown-item">
                    Export to PDF
                  </button>
                  <button onClick={exportToExcel} className="dropdown-item">
                    Export to Excel
                  </button>
                  <button onClick={exportToWord} className="dropdown-item">
                    Export to Word
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="budget-header">
          <h2 className="budget-title">Budgeting</h2>
          <div className="budget-buttons">
            <button
              className="controll-btn"
              onClick={() => {
                setNewBudget(budget);
                setShowBudgetModal(true);
              }}
            >
              <FontAwesomeIcon icon={faPen} />
              <span>Edit</span>
            </button>
            <button
              className="controll-btn"
              onClick={() => setShowBreakdownModal(true)}
            >
              <FontAwesomeIcon icon={faEye} />
              <span>View Breakdown</span>
            </button>
          </div>
        </div>
        <div className="budget-box">
          <div className="budget-summary">
            <p>
              {getCurrencySymbol(selectedCurrency)}
              {totalExpenses.toLocaleString()}.00
            </p>
            <p className="budget-total">
              Budget: {getCurrencySymbol(selectedCurrency)}
              {(budget || 0).toLocaleString()}.00
            </p>
          </div>
          <div className="progress-bar">
            <div
              className="filled-bar"
              style={{
                width: `${
                  budget && budget > 0
                    ? Math.min((totalExpenses / budget) * 100, 100)
                    : 0
                }%`,
                backgroundColor:
                  budget && budget > 0 && totalExpenses > budget
                    ? "#ff4d4f"
                    : "#007bff", // Red if over budget, green otherwise
              }}
            ></div>
          </div>
        </div>

        <h2>Itinerary</h2>

        <div className="itinerary">
          {Object.keys(itinerary).map((day, index) => (
            <div key={day} className="day-container">
              <h3>{calculateDate(startDate, index)}</h3>
              {places[day]?.map((place, idx) => (
                <div
                  key={idx}
                  className="place-card-maker"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <div
                    className="place-content"
                    style={{
                      flex: "1",
                      padding: "25px",
                      border: "1px solid #ccc",
                      borderRadius: "8px",
                    }}
                  >
                    <h4
                      style={{ cursor: "pointer", color: "black" }}
                      onClick={() => handleClickPlaceName(place.name)}
                    >
                      <h4
                        style={{ cursor: "pointer", color: "black" }}
                        onClick={() => handleClickPlaceName(place.name)}
                      >
                        {place.name}
                      </h4>

                      {/* Add Description Button */}
                      <textarea
                        placeholder="Add notes, links, etc here."
                        value={
                          places[day]?.[idx]?.description &&
                          places[day][idx].description !== "Attraction" &&
                          places[day][idx].description !== "Activity" &&
                          places[day][idx].description !==
                            "Food Recommendation" &&
                          places[day][idx].description !==
                            "Transport Recommendation"
                            ? places[day][idx].description
                            : ""
                        } // Show the description only if it is not a default placeholder
                        onChange={(e) =>
                          handleDescriptionChange(day, idx, e.target.value)
                        }
                        className="description-input"
                        style={{
                          width: "100%",
                          marginTop: "1px",
                          padding: "6px",
                          fontSize: "14px",
                          border: "0px solid #ddd",
                          borderRadius: "5px",
                        }}
                      />
                    </h4>
                    {/* {place.description && <p> {place.description}</p>} */}
                    <div
                      className="place-controls"
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        marginTop: "5px",
                      }}
                    >
                      {/* Edit Name Button */}
                      <button
                        className="control-btn"
                        onClick={() => {
                          setSelectedPlace({ day, index: idx });
                          setShowEditPlaceModal(true);
                          setEditPlaceName(place.name);
                        }}
                      >
                        <FontAwesomeIcon icon={faPen} />
                        <span>Edit Name</span>
                      </button>

                      {/* Add/Edit Time */}
                      <button
                        className="control-btn"
                        onClick={() => {
                          setSelectedPlace({ day, index: idx }); // Use idx here
                          setShowTimeModal(true);
                          setTimeDetails({ startTime: "", endTime: "" });
                        }}
                        style={{
                          color: place.time ? "#337ab7" : "inherit", // Blue for existing time
                          fontWeight: place.time ? "bold" : "normal",
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <FontAwesomeIcon icon={faClock} />
                        <span>{place.time || "Add Time"}</span>
                      </button>
                      {/* Add/Edit Cost */}
                      <button
                        className="control-btn"
                        onClick={() => {
                          setSelectedPlace({ day, index: idx });
                          setShowExpenseModal(true);
                          setExpense(place.cost || "");
                          setDescription(place.description || "");
                        }}
                        style={{
                          color: place.cost ? "#337ab7" : "inherit", // Blue for existing cost
                          fontWeight: place.cost ? "bold" : "normal",
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <FontAwesomeIcon icon={faDollarSign} />
                        <span>
                          {place.cost
                            ? `${getCurrencySymbol(
                                selectedCurrency
                              )}${place.cost.toLocaleString()}`
                            : "Add Cost"}
                        </span>
                      </button>

                      {/* Attach Button */}
                      <button
                        className="control-btn"
                        onClick={() => {
                          setSelectedPlace({ day, index: idx });
                          setShowAttachModal(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faPaperclip} />
                        <span>Attach</span>
                      </button>

                      <button
                        className="control-btn delete-btn"
                        onClick={() => handleDeletePlace(day, idx)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                        <span>Delete</span>
                      </button>
                    </div>
                  </div>
                  {place.photo && (
                    <img
                      src={`${hostURL}/${place.photo}`} // Prefix with server URL
                      alt="Place"
                      style={{
                        width: "250px",
                        height: "220px",
                        marginLeft: "20px",
                        borderRadius: "8px",
                        border: "1px solid #ccc",
                      }}
                    />
                  )}
                </div>
              ))}
              <div className="add-place">
                <div className="add-place-input-container">
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    className="add-place-iconn"
                  />
                  <input
                    type="text"
                    value={newPlace[day] || ""}
                    className="add-place-input"
                    placeholder="Add a Place / Restaurant / Activity"
                    onChange={(e) =>
                      setNewPlace({ ...newPlace, [day]: e.target.value })
                    }
                  />
                </div>
                <button
                  className="add-place-btn"
                  onClick={() => handleAddPlace(day)}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="itinerary-map">
        <LoadScript googleMapsApiKey={googleMapsApiKey}>
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={center}
            zoom={10}
            onLoad={(map) => (mapRef.current = map)}
          >
            {markers.map((marker, idx) => (
              <Marker
                key={idx}
                position={marker.position}
                onClick={() => handleMarkerClick(marker, idx)}
                icon={{
                  url: customMarkerIcon, // Use the custom marker PNG
                  scaledSize: new window.google.maps.Size(40, 60), // Adjust size
                }}
              />
            ))}
          </GoogleMap>
        </LoadScript>
      </div>

      {/* Modals */}
      {showBudgetModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Edit Budget</h2>
            <input
              type="text"
              value={
                newBudget !== null && newBudget !== undefined
                  ? new Intl.NumberFormat().format(newBudget)
                  : ""
              }
              onChange={(e) => {
                let value = e.target.value.replace(/,/g, ""); // Remove commas for processing

                // Ensure the value is a valid number within the allowed range
                if (!isNaN(value) && value !== "") {
                  const numericValue = parseInt(value, 10);
                  if (numericValue >= 0 && numericValue <= 10000000) {
                    setNewBudget(numericValue); // Update state without commas
                  } else if (numericValue > 10000000) {
                    alert("Budget cannot exceed 10 million."); // Alert for exceeding maximum value
                  } else {
                    alert("Budget cannot be negative."); // Alert for negative values
                  }
                } else if (value === "") {
                  setNewBudget(null); // Clear input
                }
              }}
              placeholder="Enter New Budget"
              style={{
                width: "100%",
                padding: "10px",
                fontSize: "16px",
                border: "1px solid #ccc",
                borderRadius: "6px",
                marginBottom: "10px",
              }}
            />
            <select
              value={selectedCurrency}
              onChange={(e) => setSelectedCurrency(e.target.value)}
              style={{
                width: "100%",
                padding: "10px",
                fontSize: "16px",
                border: "1px solid #ccc",
                borderRadius: "6px",
                marginBottom: "20px",
              }}
            >
              {currencyOptions.map((currency) => (
                <option key={currency.code} value={currency.code}>
                  {currency.name}
                </option>
              ))}
            </select>
            <div className="modal-buttons">
              <button
                onClick={() => setShowBudgetModal(false)}
                style={{
                  backgroundColor: "#e0e0e0",
                  color: "#000",
                  border: "none",
                  padding: "8px 20px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
              >
                Cancel
              </button>
              <button
                onClick={handleBudgetSave}
                style={{
                  backgroundColor: "#2979ff",
                  color: "#fff",
                  border: "none",
                  padding: "8px 20px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Add Time Modal */}
      {showTimeModal && (
        <div
          className="modal"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            padding: "0",
            margin: "0",
          }}
        >
          <div
            className="modal-content"
            style={{
              backgroundColor: "#fff",
              borderRadius: "8px",
              padding: "20px", // Ensure uniform padding
              maxWidth: "400px",
              width: "90%",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              display: "flex",
              flexDirection: "column",
              gap: "15px",
              margin: "0",
            }}
          >
            <h2
              style={{
                textAlign: "center",
                margin: "0 0 15px 0",
              }}
            >
              Set Time
            </h2>
            <div
              className="time-inputs"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
              }}
            >
              {/* Start Time */}
              <div style={{ flex: 1 }}>
                <label
                  htmlFor="start-time"
                  style={{
                    display: "block",
                    fontWeight: "bold",
                    fontSize: "14px",
                    marginBottom: "8px",
                  }}
                >
                  Start Time
                </label>
                <select
                  id="start-time"
                  value={timeDetails.startTime}
                  onChange={(e) =>
                    setTimeDetails({
                      ...timeDetails,
                      startTime: e.target.value,
                    })
                  }
                  style={{
                    width: "100%",
                    padding: "20px",
                    borderRadius: "6px",
                    border: "1px solid #ccc",
                    fontSize: "14px",
                    maxHeight: "150px",
                    overflowY: "auto",
                  }}
                >
                  {Array.from({ length: 24 * 2 }, (_, i) => {
                    const hour = Math.floor(i / 2);
                    const minute = i % 2 === 0 ? "00" : "30";
                    const ampm = hour < 12 || hour === 24 ? "AM" : "PM";
                    const formattedHour =
                      hour === 0
                        ? 12
                        : hour === 12
                        ? 12
                        : hour > 12
                        ? hour - 12
                        : hour;
                    const timeString = `${formattedHour}:${minute} ${ampm}`;
                    return (
                      <option key={timeString} value={timeString}>
                        {timeString}
                      </option>
                    );
                  })}
                </select>
              </div>

              {/* Separator */}
              <span style={{ fontSize: "18px", fontWeight: "bold" }}>—</span>

              {/* End Time */}
              <div style={{ flex: 1 }}>
                <label
                  htmlFor="end-time"
                  style={{
                    display: "block",
                    fontWeight: "bold",
                    fontSize: "14px",
                    marginBottom: "8px",
                  }}
                >
                  End Time
                </label>
                <select
                  id="end-time"
                  value={timeDetails.endTime}
                  onChange={(e) =>
                    setTimeDetails({
                      ...timeDetails,
                      endTime: e.target.value,
                    })
                  }
                  style={{
                    width: "100%",
                    padding: "20px",
                    borderRadius: "6px",
                    border: "1px solid #ccc",
                    fontSize: "14px",
                    maxHeight: "150px",
                    overflowY: "auto",
                  }}
                >
                  {Array.from({ length: 24 * 2 }, (_, i) => {
                    const hour = Math.floor(i / 2);
                    const minute = i % 2 === 0 ? "00" : "30";
                    const ampm = hour < 12 || hour === 24 ? "AM" : "PM";
                    const formattedHour =
                      hour === 0
                        ? 12
                        : hour === 12
                        ? 12
                        : hour > 12
                        ? hour - 12
                        : hour;
                    const timeString = `${formattedHour}:${minute} ${ampm}`;
                    return (
                      <option key={timeString} value={timeString}>
                        {timeString}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            {/* Buttons */}
            <div
              className="modal-buttons"
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <button
                onClick={() => setShowTimeModal(false)}
                style={{
                  backgroundColor: "#e0e0e0",
                  color: "#000",
                  border: "none",
                  padding: "8px 20px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  const { startTime, endTime } = timeDetails;

                  const convertTo24Hour = (time) => {
                    const [timeString, modifier] = time.split(" ");
                    let [hours, minutes] = timeString.split(":").map(Number);

                    if (modifier === "PM" && hours !== 12) {
                      hours += 12;
                    } else if (modifier === "AM" && hours === 12) {
                      hours = 0;
                    }

                    return hours * 60 + minutes;
                  };

                  if (startTime && endTime) {
                    const startTimeMinutes = convertTo24Hour(startTime);
                    const endTimeMinutes = convertTo24Hour(endTime);

                    if (endTimeMinutes < startTimeMinutes) {
                      alert("End time cannot be earlier than start time");
                    } else {
                      handleTimeSave();
                    }
                  }
                }}
                style={{
                  backgroundColor: "#2979ff",
                  color: "#fff",
                  border: "none",
                  padding: "8px 20px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Add Expense Modal */}
      {showExpenseModal && (
        <div className="modal">
          <div className="modal-content">
            <h2 style={{ textAlign: "center", marginBottom: "15px" }}>
              Add Expense
            </h2>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  padding: "8px",
                  width: "100%",
                  maxWidth: "300px",
                }}
              >
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "18px",
                    marginRight: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {getCurrencySymbol(selectedCurrency)}
                </span>
                <input
                  type="number"
                  value={expense}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value);
                    if (
                      (value >= 0 && value <= 10000000) ||
                      e.target.value === ""
                    ) {
                      setExpense(e.target.value); // Only allow values between 0 and 10,000,000 or empty input
                    } else if (value > 10000000) {
                      alert(
                        "Expense cannot exceed 10 million. Please enter a valid amount."
                      );
                    }
                  }}
                  placeholder="0.00"
                  style={{
                    flex: "1",
                    border: "none",
                    outline: "none",
                    fontSize: "18px",
                  }}
                />
              </div>
              <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
                <button
                  onClick={() => setShowExpenseModal(false)}
                  style={{
                    backgroundColor: "#e0e0e0",
                    color: "#000",
                    border: "none",
                    padding: "8px 20px",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    if (
                      expense === "" ||
                      (parseFloat(expense) >= 0 &&
                        parseFloat(expense) <= 10000000)
                    ) {
                      handleExpenseSave(); // Save the expense only if it's valid
                    } else if (parseFloat(expense) > 10000000) {
                      alert(
                        "Expense cannot exceed 10 million. Please enter a valid amount."
                      );
                    } else {
                      alert(
                        "Expense cannot be negative. Please enter a valid amount."
                      );
                    }
                  }}
                  style={{
                    backgroundColor: "#2979ff",
                    color: "#fff",
                    border: "none",
                    padding: "8px 20px",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Attach Photo Modal */}
      {showAttachModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Attach Photo</h2>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleAttachPhoto(e.target.files[0])}
            />
            <div className="modal-buttons">
              <button onClick={() => setShowAttachModal(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}

      {/* View Breakdown Modal */}
      {showBreakdownModal && (
        <div className="modal">
          <div
            className="modal-content"
            style={{ maxWidth: "500px", textAlign: "center" }}
          >
            <h2
              style={{
                marginBottom: "20px",
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              Budget Breakdown
            </h2>
            <p style={{ marginBottom: "10px", fontSize: "16px" }}>
              <strong>Total Budget:</strong>{" "}
              {getCurrencySymbol(selectedCurrency)}
              {budget.toLocaleString()}.00
            </p>
            <p style={{ marginBottom: "20px", fontSize: "16px" }}>
              <strong>Total Expenses:</strong>{" "}
              {getCurrencySymbol(selectedCurrency)}
              {totalExpenses.toLocaleString()}.00
            </p>
            <ul
              style={{
                textAlign: "left",
                padding: "0",
                listStyle: "none",
                margin: "0 auto",
                maxWidth: "400px",
                fontSize: "14px",
              }}
            >
              {Object.keys(places).map((day) =>
                places[day].map((place, idx) =>
                  place.cost ? (
                    <li
                      key={`${day}-${idx}`}
                      style={{
                        borderBottom: "1px solid #ddd",
                        padding: "10px 0",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ fontWeight: "bold", color: "#333" }}>
                        {place.name}
                      </span>
                      <span style={{ color: "#555" }}>
                        {getCurrencySymbol(selectedCurrency)}
                        {place.cost.toLocaleString()}
                      </span>
                    </li>
                  ) : null
                )
              )}
            </ul>
            <div className="modal-buttons" style={{ marginTop: "20px" }}>
              <button
                style={{
                  backgroundColor: "#007bff",
                  color: "white",
                  padding: "10px 20px",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
                onClick={() => setShowBreakdownModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Edit Place Modal */}
      {showEditPlaceModal && (
        <div className="modal">
          <div className="modal-content">
            <h2 style={{ textAlign: "center", marginBottom: "15px" }}>
              Edit Place Name
            </h2>
            <input
              type="text"
              value={editPlaceName}
              onChange={(e) => setEditPlaceName(e.target.value)}
              placeholder="Place Name"
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "6px",
                border: "1px solid #ccc", // Add a light border
                fontSize: "16px",
                marginBottom: "15px",
                outline: "none", // Ensure no default outline on focus
              }}
            />
            <div
              className="modal-buttons"
              style={{
                display: "flex",
                justifyContent: "space-between", // Align buttons evenly
              }}
            >
              <button
                onClick={() => setShowEditPlaceModal(false)}
                style={{
                  backgroundColor: "#e0e0e0",
                  color: "#000",
                  border: "none",
                  padding: "8px 20px",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                Cancel
              </button>
              <button
                onClick={handleEditPlace}
                style={{
                  backgroundColor: "#2979ff",
                  color: "#fff",
                  border: "none",
                  padding: "8px 20px",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Edit Title Modal */}
      {showEditTitleModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Edit Itinerary Title</h2>
            <input
              type="text"
              value={editTitle}
              onChange={(e) => setEditTitle(e.target.value)}
              placeholder="Itinerary Title"
            />
            <div className="modal-buttons">
              <button onClick={() => setShowEditTitleModal(false)}>
                Cancel
              </button>
              <button onClick={handleEditTitle}>Save</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default GeneratedItinerary;
