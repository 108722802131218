function validatePasswordV2(values) {
  const newErrors = {};

  // Password validation
  const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  if (!values) {
      newErrors.oldPassword = "Password is required";
  } else if (values.length > 128) {
      newErrors.oldPassword = "Password is too long (maximum 128 characters)";
  } else if (!passwordPattern.test(values)) {
      newErrors.oldPassword = "Password must be at least 8 characters long and include uppercase, lowercase, number, and special character";
  }
  return newErrors;
}

export default validatePasswordV2; 